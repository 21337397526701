import React from "react";
import styled from "@emotion/styled";

import LoadingIcon from "../components/LoadingIcon";

const StyledVideo = styled.video`
  height: 100vh;
  width: calc(100vh * (9 / 16));
  outline: none;
  vertical-align: bottom;

  @media (min-width: 1281px) and (max-height: 700px) { 
    // fixed height size for small height(<700px) on desktop
    height: calc(100vh - (57px + 6vw));
    width: auto;
  }
`;
const StyledDiv = styled.div`
  .loading-icon-animation {
    width: auto !important;
    display: block;
    position: absolute;
  }
  @media (min-width: 1281px) and (max-height: 700px) { 
    // fixed height size for small height(<700px) on desktop
    height: calc(100vh - (57px + 6vw));
    width: auto;
  }
`;

function InterventionDetailAnimation(props) {
  // Capitalize the intevention title, and remove white spaces or "/" to use the string as part of the video path ("Speed Bumps" should be "SpeedBumps.mp4")
  const regex = /(\/|\s)/gm;
  const interventionTitleSplitted = props.interventionContents.Intervention.split(
    regex
  );
  const modifiedInterventionTitle = interventionTitleSplitted
    .map((word) => {
      if (!word.match(regex))
        return word.charAt(0).toUpperCase() + word.slice(1);
      return null;
    })
    .join("");

  const mp4VideoPath = `https://chase-staging-cdn.azureedge.net/videos-vertical/${modifiedInterventionTitle}.mp4`;
  const webmVideoPath = `https://chase-staging-cdn.azureedge.net/videos-vertical/${modifiedInterventionTitle}.webm`;

  // hide loading icon
  const onLoadAnimation = () => {
    const loadingIconSvg = document.querySelector(".loading-icon");
    loadingIconSvg.style.display = "none";
  };

  return (
    <StyledDiv>
      <LoadingIcon />

      <StyledVideo
        autoPlay
        muted
        loop
        onLoadedData={onLoadAnimation}
        key={webmVideoPath}
      >
        <source src={webmVideoPath} type="video/webm" />
        <source src={mp4VideoPath} type="video/mp4" />
        Your browser does not support the video tag.
      </StyledVideo>
    </StyledDiv>
  );
}

export default InterventionDetailAnimation;
