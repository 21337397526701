import React, { useContext } from "react";
import facepaint from "facepaint";
import styled from "@emotion/styled";
import { Link, NavLink } from "react-router-dom";

import { GlobalContext } from "../data/GlobalStore";

import SearchBarMobile from "./SearchBarMobile";

import GlobalColors from "../assets/GlobalColors";
import searchIcon from "../assets/icons/search.svg";
import vehicleIcon from "../assets/icons/vehicle.svg";
import bicycleIcon from "../assets/icons/bicycle.svg";
import pedestrianIcon from "../assets/icons/pedestrian.svg";
import navBarBgImage from "../assets/images/navbarBG.jpeg";
import aboutIcon from "../assets/icons/info.svg";

// media queries mapping
const breakpoints = [768, 1280]; // [mobile max width, small tablet max width, tablet max width]
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`));

const Header = styled.header`
  color: ${GlobalColors.white};
  background: linear-gradient(rgba(37, 162, 207, 0.7), rgba(37, 162, 207, 0.7)),
    url(${navBarBgImage});
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  transition: 0.5s;
  word-break: break-word;
  width: 100vw;
  ${mq({
    padding: ["3%"],
  })}

  h1 {
    ${mq({
      marginRight: ["15px"],
    })}
  }
  .search-icon {
    display: inline-block;
    img {
      opacity: 0.6;
      &.active {
        opacity: 1;
      }
    }
  }
  .about-icon {
    display: block;
    margin-left: 10px;
    img {
      opacity: 0.6;
      &.active {
        opacity: 1;
      }
    }
  }
  // set the same width/height to all <img>s in header
  img {
    width: 40px;
    height: 25px;
    ${mq({
      width: ["45px", "60px"],
      height: ["30px", "36px"],
    })}
  }
  // "About CHASE" text
  .global-nav-about {
    height: 0;
    margin-top: 0;
    padding-top: 0;
    font-size: 1.4rem;
    border-top: 1px solid rgba(255, 255, 255, 0.6);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s, height 0.3s, padding-top 0.3s,
      margin-top 0.3s;
    p {
      margin-bottom: 20px;
    }
    &.global-nav-about-shown {
      height: auto;
      padding-top: 20px;
      margin-top: 20px;
      visibility: visible;
      opacity: 1;
    }
  }
`;
const HomeLink = styled(Link)`
  color: ${GlobalColors.white};
  display: inline-block;
  text-decoration: none;
  word-break: keep-all;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  line-height: 1;
  margin-right: 5px;
  /* dashed line */
  background-image: linear-gradient(
    to right,
    #fff 60%,
    rgba(255, 255, 255, 0) 0%
  );
  background-repeat: repeat-x;
  ${mq({
    letterSpacing: ["2px"],
    fontSize: ["5rem"],

    /* dashed line */
    backgroundSize: ["18px 3px"], // ['theLengthOf Dash theHeightOfDash']
    backgroundPosition: ["-2px 5.3rem"], //['horizontalPositionOfDash distanceFromTextTop (setting to font size + 0.3rem)']
    paddingBottom: ["6px"], // space under text to show the dashed line
  })}
`;
const NavLinks = styled.nav`
  display: flex;
  a {
    text-transform: uppercase;
    font-size: 1.6rem;
    letter-spacing: 1px;
    opacity: 0.6;
  }
  .active {
    opacity: 1;
  }
`;
const Category = styled(NavLink)`
  text-decoration: none;
  color: #fff;
  display: block;
  font-size: 2.4rem;
  border-radius: 4px;
  font-weight: bold;
  transition: 0.2s;
  margin-bottom: 0;
  ${mq({
    marginLeft: ["14px", "20px"],
  })}
  &[href="/pedestrian"] img {
    // solely aesthetic purpose
    ${mq({
      width: ["auto"],
    })}
  }
`;
const BorderLinks = `
  width: 31%;
  text-align: center;
  color: #fff;
  text-decoration: none;
  padding: 10px;
  border: 1px solid;
  font-weight: bold;
`;
const StyledNavButtons = styled.nav`
  display: flex;
  justify-content: space-between;
  a {
    ${BorderLinks}
  }
`;
const SurveyLink = styled.a`
  ${BorderLinks}
`;

function GlobalNavTablet() {
  // load global state from GlobalStore.js
  const [globalState, globalDispatch] = useContext(GlobalContext);

  // clear "searchText", set "isSearchBarShown" and "isAboutTextShown" to false in GlobalStore.js
  const initializeStates = () => {
    globalDispatch({ type: "CHANGE_SEARCH_TEXT", payload: "" });
    globalDispatch({ type: "CHANGE_IS_SEARCH_BAR_SHOWN", payload: false });
    globalDispatch({ type: "CHANGE_IS_ABOUT_TEXT_SHOWN", payload: false });
  };

  // update "isSearchBarShown" in GlobalStore.js to show/hide search input in SearchBarMobile.js component
  const toggleIsSearchBarShown = () => {
    globalDispatch({
      type: "CHANGE_IS_SEARCH_BAR_SHOWN",
      payload: !globalState.isSearchBarShown,
    });
    // if "About CHASE" text is shown, hide it
    if (globalState.isAboutTextShown) {
      globalDispatch({
        type: "CHANGE_IS_ABOUT_TEXT_SHOWN",
        payload: !globalState.isAboutTextShown,
      });
    }
  };

  // update "isAboutTextShown" in GlobalStore.js to show/hide "About CHASE" text
  const toggleIsAboutTextShown = (e) => {
    globalDispatch({
      type: "CHANGE_IS_ABOUT_TEXT_SHOWN",
      payload: !globalState.isAboutTextShown,
    });
    // if search input is shown, hide it
    if (globalState.isSearchBarShown) {
      globalDispatch({
        type: "CHANGE_IS_SEARCH_BAR_SHOWN",
        payload: !globalState.isSearchBarShown,
      });
    }
  };

  return (
    <Header>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h1>
          <HomeLink to="/" onClick={initializeStates}>
            CHASE
          </HomeLink>
        </h1>

        <div className="about-icon">
          <img
            src={aboutIcon}
            alt="about chase icon"
            onClick={toggleIsAboutTextShown}
            className={`${globalState.isAboutTextShown ? "active" : ""}`}
          />
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <div className="search-icon">
          <img
            src={searchIcon}
            alt="search icon"
            onClick={toggleIsSearchBarShown}
            className={`${globalState.isSearchBarShown ? "active" : ""}`}
          />
        </div>

        <NavLinks>
          <Category to="/bicycle" onClick={initializeStates}>
            <img src={bicycleIcon} alt="bicycle icon" />
          </Category>
          <Category to="/vehicle" onClick={initializeStates}>
            <img src={vehicleIcon} alt="vehicle icon" />
          </Category>
          <Category to="/pedestrian" onClick={initializeStates}>
            <img src={pedestrianIcon} alt="pedestrian icon" />
          </Category>
        </NavLinks>
      </div>

      {globalState.isSearchBarShown && <SearchBarMobile />}

      <div
        className={`global-nav-about ${
          globalState.isAboutTextShown && "global-nav-about-shown"
        }`}
      >
        <p>
          This tool serves as a summary of results from published studies that
          examined the effectiveness of built environment interventions at
          increasing road safety.
        </p>
        <StyledNavButtons>
          <SurveyLink
            href="https://forms.gle/ynsuZUfsDxpBW5S67"
            target="_blank"
            rel="noreferrer noopener"
            onClick={initializeStates}
          >
            EVALUATION SURVEY
          </SurveyLink>
          <Link to="/about" onClick={initializeStates}>
            ABOUT CHASE
          </Link>
          <Link to="/help" onClick={initializeStates}>
            HOW TO USE
          </Link>
        </StyledNavButtons>
      </div>
    </Header>
  );
}

export default GlobalNavTablet;
