import React, { useContext } from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";

import { GlobalContext } from "../data/GlobalStore";

import InterventionDetailContents from "../components/InterventionDetailContents";
import InterventionDetailAnimation from "../components/InterventionDetailAnimation";

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

function InterventionDetailPage() {
  const [globalState] = useContext(GlobalContext);
  let { slug } = useParams();
  const interventionContents = globalState.interventions.filter(
    (intervention) => {
      // format the intervention name pulled from global states. replace whitespaces and "\" with "-". (i.e., "Speed Bumps" -> "speed-bumps", "Pedestrian Fence/Barrier" -> "pedestrian-fence-barrier")
      const formattedInterventionName = intervention.Intervention.toLowerCase().replaceAll(
        /\s|\//gm,
        "-"
      );
      return formattedInterventionName === slug;
    }
  );

  return (
    <StyledWrapper>
      <InterventionDetailContents
        interventionContents={interventionContents[0]}
      />
      {globalState.isMobileOrTablet ? (
        ""
      ) : (
        <InterventionDetailAnimation
          interventionContents={interventionContents[0]}
        />
      )}
    </StyledWrapper>
  );
}

export default InterventionDetailPage;
