import React, { useContext, useEffect } from "react";
import facepaint from "facepaint";
import styled from "@emotion/styled";

import GlobalNav from "./components/GlobalNav";
import GlobalNavMobile from "./components/GlobalNavMobile";
import GlobalNavTablet from "./components/GlobalNavTablet";

import HomePage from "./pages/HomePage";
import HelpPage from "./pages/HelpPage";
import AboutPage from "./pages/AboutPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";

import BicyclePage from "./pages/BicyclePage";
import VehiclePage from "./pages/VehiclePage";
import PedestrianPage from "./pages/PedestrianPage";
import InterventionDetailPage from "./pages/InterventionDetailPage";
import NotFoundPage from "./pages/NotFoundPage";

import { GlobalContext } from "./data/GlobalStore";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import GlobalLayout from "./assets/GlobalLayout";
import GlobalFonts from "./assets/GlobalFonts";

// media queries mapping
const breakpoints = [768, 1280]; // [mobile/tablet max width, large tablet max width]
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`));

const StyledWrapper = styled.div`
  font-family: "MuliRegular";
  ${mq({
    display: ["unset", "unset", "flex"],
  })}
  @media (min-width: 1281px) and (max-height: 700px) { 
    // for small height(<700px) on desktop
    display: unset;
  }
`;

function Routes() {

  // load global state from GlobalStore.js
  const [globalState, globalDispatch] = useContext(GlobalContext);

  let mediaQueryList = matchMedia("(max-width: 1279px)"); // mobile/tablet width
  let mediaQueryListMobile = matchMedia("(max-width: 600px)"); // mobile width
  let mediaQueryListTablet = matchMedia("(min-width: 601px) and (max-width: 1279px)"); // tablet width
  let mediaQueryTinyHeight = matchMedia("(max-height: 700px)"); // tiny height

  // for first rendering, check the window size and set "isMobileOrTablet" in GlobalState.js to render <GlobalNav /> or <GlobalNavMobile /> conditionally
  useEffect(() => {
    if (mediaQueryListMobile.matches) {
      globalDispatch({ type: "CHANGE_IS_MOBILE", payload: true });
    } else if (!mediaQueryListMobile.matches) {
      globalDispatch({ type: "CHANGE_IS_MOBILE", payload: false });
    }

    if (mediaQueryListTablet.matches) {
      globalDispatch({ type: "CHANGE_IS_TABLET", payload: true });
    } else if (!mediaQueryList.matches) {
      globalDispatch({ type: "CHANGE_IS_TABLET", payload: false });
    }

    if (mediaQueryList.matches) {
      globalDispatch({ type: "CHANGE_IS_MOBILE_OR_TABLET", payload: true });
    } else if (!mediaQueryList.matches) {
      globalDispatch({ type: "CHANGE_IS_MOBILE_OR_TABLET", payload: false });
    }

    if (mediaQueryTinyHeight.matches) {
      globalDispatch({ type: "CHANGE_IS_TINY_HEIGHT", payload: true });
    } else if (!mediaQueryTinyHeight.matches) {
      globalDispatch({ type: "CHANGE_IS_TINY_HEIGHT", payload: false });
    }
  }, [mediaQueryList.matches, mediaQueryListMobile.matches, mediaQueryListTablet.matches, mediaQueryTinyHeight.matches, globalDispatch]);

  // update "isMobile" in GlobalState.js according to the window size
  mediaQueryListMobile.addEventListener("change", (mediaQueryListMobile) => {
    if (mediaQueryListMobile.matches) {
      globalDispatch({ type: "CHANGE_IS_MOBILE", payload: true });
    } else if (!mediaQueryListMobile.matches) {
      globalDispatch({ type: "CHANGE_IS_MOBILE", payload: false });
    }
  });

  // update "isTablet" in GlobalState.js according to the window size
  mediaQueryListTablet.addEventListener("change", (mediaQueryListTablet) => {
    if (mediaQueryListTablet.matches) {
      globalDispatch({ type: "CHANGE_IS_TABLET", payload: true });
    } else if (!mediaQueryListTablet.matches) {
      globalDispatch({ type: "CHANGE_IS_TABLET", payload: false });
    }
  });

  // when the window size becomes larger/smaller than 1279px, update "isMobileOrTablet" in GlobalState.js
  mediaQueryList.addEventListener("change", (mediaQueryList) => {
    if (mediaQueryList.matches) {
      globalDispatch({ type: "CHANGE_IS_MOBILE_OR_TABLET", payload: true });
    } else if (!mediaQueryList.matches) {
      globalDispatch({ type: "CHANGE_IS_MOBILE_OR_TABLET", payload: false });
    }
  });

  // update "isTinyHeight" in GlobalState.js according to the window height size
  mediaQueryTinyHeight.addEventListener("change", (mediaQueryTinyHeight) => {
    if (mediaQueryTinyHeight.matches) {
      globalDispatch({ type: "CHANGE_IS_TINY_HEIGHT", payload: true });
    } else if (!mediaQueryTinyHeight.matches) {
      globalDispatch({ type: "CHANGE_IS_TINY_HEIGHT", payload: false });
    }
  });

  return (
    <Router>
      <GlobalLayout />
      <GlobalFonts />
      <StyledWrapper>
      {globalState.isTablet && <GlobalNavTablet />}
      {globalState.isMobile && <GlobalNavMobile />}
      {globalState.isTinyHeight && !globalState.isMobileOrTablet ? (
        <GlobalNavTablet />
      ) : (
        !globalState.isMobileOrTablet && <GlobalNav />
      )}

        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route exact path="/bicycle">
            {globalState.searchText && <Redirect to="/" />}
            <BicyclePage />
          </Route>
          <Route exact path="/vehicle">
            {globalState.searchText && <Redirect to="/" />}
            <VehiclePage />
          </Route>
          <Route exact path="/pedestrian">
            {globalState.searchText && <Redirect to="/" />}
            <PedestrianPage />
          </Route>
          <Route exact path="/about">
            {globalState.searchText && <Redirect to="/" />}
            <AboutPage />
          </Route>
          <Route exact path="/help">
            {globalState.searchText && <Redirect to="/" />}
            <HelpPage />
          </Route>
          <Route exact path="/privacy">
            {globalState.searchText && <Redirect to="/" />}
            <PrivacyPolicyPage />
          </Route>

          <Route path="/*/:slug">
            {globalState.searchText && <Redirect to="/" />}
            <InterventionDetailPage />
          </Route>
          <Route path="*">
            {globalState.searchText && <Redirect to="/" />}
            <NotFoundPage />
          </Route>
        </Switch>
      </StyledWrapper>
    </Router>
  );
}

export default Routes;
