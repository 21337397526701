import React from "react";
import facepaint from "facepaint";
import styled from "@emotion/styled";
import GlobalColors from "../../assets/GlobalColors";

//Media Queries//
const breakpoints = [480, 780, 1280]; // [mobile max width, small tablet max width, small screen max width]
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`));

const StyledWrapper = styled.div`
  width: 100%;
  
`;
const TwoColumns = styled.div`
  display:flex;
  ${mq({
    "flex-direction": ["column", "column", "row"],
  })}
  `;

const StyledH2 = styled.h2`
  font-size: 2.5rem;
  text-decoration: none;
  text-transform: uppercase;
  color: ${GlobalColors.blueMain};
  letter-spacing: 2px;
  display: inline-block;
  margin-bottom: 20px;
  font-weight: 900;
`;
const StyledDescription = styled.p`
  font-size: 1.8rem;
  letter-spacing: 1.2px;
  line-height: 30px;
  color: ${GlobalColors.blueMain};
  font-weight: normal;
  margin-bottom: 30px;
  width: 100%;
  ${mq({
    "padding-right": ["0", "0", "50px"],
  })}
`;
const StyledBody = styled.div`
  font-size: 1.6rem;
  margin-bottom: 50px;
  line-height: 25px;
  width: 100%;
  font-weight: 100;
  a {
    color: ${GlobalColors.blueMain};
    font-size: 1.4rem;
  }
  a.refs-link {
    font-size: 1.2rem;
    text-decoration: none;
    vertical-align: super;
  }
`;

function HelpPageUseThisTool() {
  return (
    <StyledWrapper>
      <StyledH2>HOW TO USE THIS TOOL</StyledH2>

      <TwoColumns>
        <StyledDescription>
          We have grouped the built environment interventions into three main
          categories: streets and sidewalks, intersections and crossings, and
          bicycle-related interventions. Streets and sidewalks and intersections
          and crossings both contain sub-groups including vertical and
          horizontal deflections, and an other category, and pedestrian focused
          and vehicle focused sub groups for intersections and crossings.
          Area-wide traffic calming interventions were placed in the ‘other’
          sub-group in streets and sidewalks.
        </StyledDescription>

        <StyledBody>
          Each intervention includes a description of what the intervention is,
          the types of study designs that were found from the environmental scan
          and literature review, the number of studies that were included in the
          summary and results of the critical appraisal. For each intervention
          type, there is a summary of the outcomes presented in each study.
          Examples of outcomes in this tool include motor vehicle collisions
          (MVCs), traffic volume, traffic yielding, and speed. Each outcome is
          reported using the data from each study. For example, many studies
          report the percent reduction in motor vehicle collisions, pre to post
          implementation of the intervention. Locations in which the included
          studies took place are also provided for each built environment
          intervention. Some studies have implemented BE change outside of OECD
          (Organisation for Economic Co-operation and Development) countries and
          readers should take into consideration implementation location when
          interpreting results.
        </StyledBody>
      </TwoColumns>
    </StyledWrapper>
  );
}

export default HelpPageUseThisTool;
