import React from "react";
import { Global, css } from "@emotion/core";
import MuliRegular from "./fonts/muli-webfont.woff2";
import Oswald from "./fonts/oswald-variablefont_wght-webfont.woff2";

export default () => {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: "MuliRegular";
          font-style: normal;
          font-weight: 500;
          src: url(${MuliRegular});
        }
        @font-face {
          font-family: "Oswald";
          font-style: normal;
          font-weight: 500;
          src: url(${Oswald});
        }
      `}
    />
  );
};
