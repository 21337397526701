import React, { useContext } from "react";
import styled from "@emotion/styled";
import GlobalColors from "../../assets/GlobalColors";

import { GlobalContext } from "../../data/GlobalStore";

const StyledWrapper = styled.div`
  width: 100%;
  padding-bottom: 100px;
`;

const StyledH3 = styled.h3`
  font-size: 1.8rem;
  color: ${GlobalColors.blueMain};
  font-weight: bolder;
  letter-spacing: 1.2px;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 10px;
  padding-top: 10px;
`;

const StyledBody = styled.div`
  font-size: 1.5rem;
  margin-bottom: 50px;
  width: 100%;
  font-weight: 100;
  a {
    color: ${GlobalColors.blueMain};
    font-size: 1.4rem;
  }
  a.refs-link {
    font-size: 1.2rem;
    text-decoration: none;
    vertical-align: super;
  }
`;

const StyledReferences = styled.ul`
  font-size: 1.2rem;
  a {
    text-decoration: none;
    color: ${GlobalColors.blueMain};
  }
`;

function HelpPageReferences(props) {
  const [globalState] = useContext(GlobalContext);
  const list = ["1", "2", "3", "117", "118"];

  return (
    <StyledWrapper>
      <StyledBody>
        <StyledH3 style={{ display: "block", marginBottom: "5px" }}>
          References:
        </StyledH3>
        <StyledReferences>
          {globalState.evidenceKPReferences
            .filter((reference) => {
              if (list.includes(Object.keys(reference)[0])) {
                return true;
              } else {
                return false;
              }
            })
            .map((reference,index) => {
              return (
                <li key={index} id={`footnote${Object.keys(reference)[0]}`}>
                  {Object.keys(reference)[0]} : {Object.values(reference)[0]}
                </li>
              );
            })}
        </StyledReferences>
      </StyledBody>
    </StyledWrapper>
  );
}

export default HelpPageReferences;
