import React from "react";
import styled from "@emotion/styled";
import facepaint from "facepaint";

//Components//

//First Section
import HelpPageUseThisTool from "./HelpPageUseThisTool";
//Second Section
import HelpPageMethod from "./HelpPageMethods";
//Third Section
import HelpPageEffectEstimates from "./HelpPageEffectEstimates";
//References
import HelpPageReferences from "./HelpPageReferences";
const breakpoints = [480, 768, 1280]; // [mobile max width, small tablet max width, small screen max width]
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`));

const StyledWrapper = styled.div`
  ${mq({
    padding: ["5%", "2%", "2%"],
  })}
  height: 100vh;
  width: 100%;
  padding-bottom: 100px;
`;

function HelpPage() {
  return (
    <StyledWrapper>
      <HelpPageUseThisTool />
      <HelpPageMethod />
      <HelpPageEffectEstimates />
      <HelpPageReferences />
    </StyledWrapper>
  );
}

export default HelpPage;
