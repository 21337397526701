export default {
    blueMain: "#27A2CF",
    blueSecondary: "#C9E7F2",
    blueNavBarOverlay: "rgba(37, 162, 207 , 0.45)",
    bodyText: "#313131",
    green: "#008800",
    white: "#fff",
    // Intervention detail table
    tableBgTopBar: "#C9E7F2",
    tableBgBottomSection: "#DFF0F7",
    tableTitle: "#27A2CF",
}