import React, { useContext, useRef } from "react";
import styled from "@emotion/styled";

import { navBarContext } from "./GlobalNav";
import { GlobalContext } from "../data/GlobalStore";

import GlobalColors from "../assets/GlobalColors";
import searchIcon from "../assets/icons/search.svg";
import searchIconGrey from "../assets/icons/search-grey.svg";

const FormArea = styled.form`
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  label,
  input,
  button,
  .filter-icon {
    border: none;
    padding: 10px;
    font-size: 1.6rem;
    outline: none;
    background-color: ${GlobalColors.blueSecondary};
  }
  input {
    border-radius: 0 5px 5px 0;
    margin: 0;
    width: 89%;
  }
  label {
    border-radius: 5px 0 0 5px;
    padding-right: 0;
    width: 11%;
    line-height: 1;
    img {
      vertical-align: middle;
    }
  }
  button,
  .filter-icon {
    border-radius: 0 5px 5px 0;
  }
  .filter-categories {
    display: none;
    position: absolute;
    z-index: 10;
    padding: 10px;
    border-radius: 5px;
    background-color: ${GlobalColors.blueSecondary};
    input,
    label {
      width: auto;
      border-radius: initial;
    }
    div {
      display: flex;
      align-items: center;
    }
  }
  /* When navbar is collapsed */
  &.collapsed {
    label {
      background: none;
      padding: 0;
      display: block;
      transition: 0.2s;
      width: inherit;
      text-align: center;
      flex-grow: 1;
      width: 11%;
      img {
        width: 35px;
      }
      svg {
        fill: white;
      }
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
    input,
    button,
    .filter-icon {
      display: none;
    }
    .filter-categories {
      display: none !important;
    }
  }
`;

function SearchBar(props) {
  const [globalState, globalDispatch] = useContext(GlobalContext);
  const [isNavBarCollapsed, setIsNavBarCollapsed] = useContext(navBarContext);
  const inputRef = useRef();

  const toggleIsNavBarCollapsed = () => {
    setIsNavBarCollapsed(!isNavBarCollapsed);

    // focus doesn't work without setTimeout
    setTimeout(() => inputRef.current.focus(), 100);
  };

  // prevent page refresh when user submits the search form
  const onFormSubmit = (e) => {
    e.preventDefault();
  }

  const filterInterventions = (e) => {
    // update global state "SearchText" in GlobalStore.js with the user input in input box
    globalDispatch({ type: "CHANGE_SEARCH_TEXT", payload: e.target.value });

    // everywhen the text in the input box is changed, the indexes of each carousel("bicycleInterventionIndexes", "vehicleInterventionIndexes", "pedestrianInterventionIndexes" array in GlobalState.js) is initialized to start from 0
    const carouselInitialIndex = [0, 1, 2];
    globalDispatch({
      type: "CHANGE_BICYCLE_INTERVENTION_INDEXES",
      payload: carouselInitialIndex,
    });
    globalDispatch({
      type: "CHANGE_VEHICLE_INTERVENTION_INDEXES",
      payload: carouselInitialIndex,
    });
    globalDispatch({
      type: "CHANGE_PEDESTRIAN_INTERVENTION_INDEXES",
      payload: carouselInitialIndex,
    });
  };

  return (
    <FormArea
      className={`${!props.isHomepage && isNavBarCollapsed ? "collapsed" : ""}`}
      onSubmit={onFormSubmit}
    >
      <label htmlFor="search" onClick={toggleIsNavBarCollapsed}>
        {isNavBarCollapsed && !props.isHomepage ? (
          <img 
            src={searchIcon} 
            alt="search icon"
          />
        ) : (
          <img 
            src={searchIconGrey} 
            alt="search icon" 
          />
        )}
      </label>
      <input
        ref={inputRef}
        type="text"
        name="search"
        placeholder="Search"
        onChange={(e) => filterInterventions(e)}
        value={globalState.searchText}
      />
    </FormArea>
  );
}

export default SearchBar;
