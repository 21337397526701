import React from "react";
import styled from "@emotion/styled";
import GlobalColors from "../../assets/GlobalColors";
import facepaint from "facepaint";
import CIHR from "../../assets/chase_logos/9_CIHR.png";
const breakpoints = [480, 790, 1280]; // [mobile max width, small tablet max width, small screen max width]
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`));

const StyledWrapper = styled.div`
  width: 100%;
  img {
    ${mq({
      height: ["auto", "auto", "150px"],
      width: ["200px", "200px", "auto"],
    })}
    padding-bottom: 30px;
  }
`;
const StyledH2 = styled.h2`
  font-size: 3rem;
  text-decoration: none;
  text-transform: uppercase;
  color: ${GlobalColors.blueMain};
  letter-spacing: 2px;
  display: inline-block;
  margin-bottom: 20px;
  font-weight: 900;
`;
const StyledDescription = styled.p`
  font-size: 2.1rem;
  letter-spacing: 1.2px;
  ${mq({
    "padding-right": ["0", "5%", "5%"],
  })}
  line-height: 30px;
  color: ${GlobalColors.blueMain};
  font-weight: normal;
  margin-bottom: 30px;
  ${mq({
    width: ["100%", "100%", "70%"],
  })}
`;
const StyledBody = styled.div`
  ${mq({
    "padding-right": ["0", "5%", "5%"],
  })}
  font-size: 1.6rem;
  ${mq({
    "margin-bottom": ["10px", "50px", "50px"],
  })}
  line-height: 25px;
  width: 100%;
  font-weight: 100;
  a {
    color: ${GlobalColors.blueMain};
    font-size: 1.4rem;
  }
  a.refs-link {
    font-size: 1.2rem;
    text-decoration: none;
    vertical-align: super;
  }
`;

const ThreeColumns = styled.div`
  display: flex;
  justify-content: center;
  ${mq({
    "flex-direction": ["column", "column", "row"],
  })}
`;

const Bold = styled.a`
  letter-spacing: 1.1px;
  color: ${GlobalColors.blueMain};
  font-size: 2.1rem;
  width: 100%;
  font-weight: 900;
`;

function AboutPageFirstSection() {
  return (
    <StyledWrapper>
      <StyledH2>ABOUT CHASE</StyledH2>
      <ThreeColumns>
        <StyledDescription>
          <Bold>CHASE </Bold>(Child Active Transportation Safety in the
          Environment) is a 5 year team grant funded by Canadian Institute for
          Health Research (CIHR)
        </StyledDescription>

        <StyledBody>
          The team includes 26 applicants from academic institutions, hospitals,
          municipal/ provincial/ national governments, and not-for-profit
          organizations representing many different disciplines across Canada.
          The study aimed to answer questions about how the built environment
          influences child and adolescent active transportation and the risk of
          active transportation injury. The municipalities participating in this
          work includes large, Canadian urban settings - Calgary, Toronto, Peel,
          Montreal, Laval, Calgary, Vancouver and Surrey.
        </StyledBody>
        <img src={CIHR} alt="" />
      </ThreeColumns>
    </StyledWrapper>
  );
}

export default AboutPageFirstSection;
