const GlobalReducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_NAV_COLLAPSE":
      return {
        ...state,
        isNavBarCollapsed: action.payload,
      };
   //  -----------------------------
    // For clicking Chase to homepage
    //  -----------------------------
      case "CHANGE_HOMEPAGE":
        return {
          ...state,
          isHomePageShown: action.payload,
        };

    //  -----------------------------
    // For searching/filtering
    //  -----------------------------
    case "CHANGE_SEARCH_TEXT":
      return {
        ...state,
        searchText: action.payload,
      };
    case "CHECK_BICYCLE_SELECTED":
      return {
        ...state,
        filterSelectedCategories: [
          ...state.filterSelectedCategories,
          "Bicycle",
        ],
        isBicycleSelected: action.payload,
      };
    case "UNCHECK_BICYCLE_SELECTED":
      return {
        ...state,
        filterSelectedCategories: state.filterSelectedCategories.filter(
          (category) => category !== "Bicycle"
        ),
        isBicycleSelected: action.payload,
      };
    case "CHECK_VEHICLE_SELECTED":
      return {
        ...state,
        filterSelectedCategories: [
          ...state.filterSelectedCategories,
          "Vehicle",
        ],
        isVehicleSelected: action.payload,
      };
    case "UNCHECK_VEHICLE_SELECTED":
      return {
        ...state,
        filterSelectedCategories: state.filterSelectedCategories.filter(
          (category) => category !== "Vehicle"
        ),
        isVehicleSelected: action.payload,
      };
    case "CHECK_PEDESTRIAN_SELECTED":
      return {
        ...state,
        filterSelectedCategories: [
          ...state.filterSelectedCategories,
          "Pedestrian",
        ],
        isPedestrianSelected: action.payload,
      };
    case "UNCHECK_PEDESTRIAN_SELECTED":
      return {
        ...state,
        filterSelectedCategories: state.filterSelectedCategories.filter(
          (category) => category !== "Pedestrian"
        ),
        isPedestrianSelected: action.payload,
      };

    //  -----------------------------
    // For card container carousel
    // ------------------------------
    case "CHANGE_LENGTH_OF_FILTERED_BICYCLE_INTERVENTIONS":
      return {
        ...state,
        lengthOfFilteredBicycleInterventions: action.payload,
      };
    case "CHANGE_LENGTH_OF_FILTERED_VEHICLE_INTERVENTIONS":
      return {
        ...state,
        lengthOfFilteredVehicleInterventions: action.payload,
      };
    case "CHANGE_LENGTH_OF_FILTERED_PEDESTRIAN_INTERVENTIONS":
      return {
        ...state,
        lengthOfFilteredPedestrianInterventions: action.payload,
      };

    // update intervention card indexes currently shown in carousel
    case "CHANGE_BICYCLE_INTERVENTION_INDEXES":
      return {
        ...state,
        bicycleInterventionIndexes: action.payload,
      };
    case "CHANGE_VEHICLE_INTERVENTION_INDEXES":
      return {
        ...state,
        vehicleInterventionIndexes: action.payload,
      };
    case "CHANGE_PEDESTRIAN_INTERVENTION_INDEXES":
      return {
        ...state,
        pedestrianInterventionIndexes: action.payload,
      };

    // update the interventions array with a randomized order
    case "UPDATE_BICYCLE_RANDOMIZED_INTERVENTIONS_ON_HOMEPAGE":
        return {
            ...state,
            bicycleRandomizedInterventionsOnHomepage : action.payload,
        };
    case "UPDATE_VEHICLE_RANDOMIZED_INTERVENTIONS_ON_HOMEPAGE":
        return {
            ...state,
            vehicleRandomizedInterventionsOnHomepage : action.payload,
        };
    case "UPDATE_PEDESTRIAN_RANDOMIZED_INTERVENTIONS_ON_HOMEPAGE":
        return {
            ...state,
            pedestrianRandomizedInterventionsOnHomepage : action.payload,
        };

    // -----------------------------
    // For PC nav bar for "Help" and "About" bars
    // -----------------------------
    case "CHANGE_IS_HELP_ABOUT_SHOWN":
      return {
        ...state,
        isSearchBarShown: action.payload,
      };

    // -----------------------------
    // For responsive design
    // -----------------------------

    // to show/hide search input
    case "CHANGE_IS_SEARCH_BAR_SHOWN":
      return {
        ...state,
        isSearchBarShown: action.payload,
      };
    // to show/hide "About CHASE" text
    case "CHANGE_IS_ABOUT_TEXT_SHOWN":
      return {
        ...state,
        isAboutTextShown: action.payload,
      };
    // Update user's media query info
    case "CHANGE_IS_MOBILE_OR_TABLET":
      return {
        ...state,
        isMobileOrTablet: action.payload,
      };
    //Update user's media query info to Mobile
    case "CHANGE_IS_MOBILE":
      return {
        ...state,
        isMobile: action.payload,
      };
    //Update user's media query info to Tablet
    case "CHANGE_IS_TABLET":
      return {
        ...state,
        isTablet: action.payload,
      };

    //Update user's media query info to tiny height
    case "CHANGE_IS_TINY_HEIGHT":
      return {
        ...state,
        isTinyHeight: action.payload,
      };
    // Update user's media query and device orientation info
    // case "CHANGE_IS_MOBILE_PORTRAIT":
    //     return {
    //         ...state,
    //         isMobilePortrait : action.payload,
    //     };
    // case "CHANGE_IS_MOBILE_LANDSCAPE":
    //     return {
    //         ...state,
    //         isMobileLandscape : action.payload,
    //     };
    // case "CHANGE_IS_Tablet_PORTRAIT":
    //     return {
    //         ...state,
    //         isTabletPortrait : action.payload,
    //     };
    // case "CHANGE_IS_TABLET_LANDSCAPE":
    //     return {
    //         ...state,
    //         isTabletLandscape : action.payload,
    //     };
    // case "CHANGE_IS_PC":
    //     return {
    //         ...state,
    //         isPC : action.payload,
    //     };
    // case "CHANGE_IS_LARGE_PC":
    //     return {
    //         ...state,
    //         isLargePC : action.payload,
    //     };

    default:
      return state;
  }
};

export default GlobalReducer;
