import React, { createContext, useReducer } from "react"
import GlobalReducer from "./GlobalReducer"

const initialGlobalState = {
  isNavBarCollapsed: false,
  majorCategories: ["Bicycle", "Vehicle", "Pedestrian"],

  // For responsive design -----------------------
  // Check user device
  isMobileOrTablet: true,
  isMobile: false,
  isTablet: false,
  isTinyHeight: false,
  // isMobilePortrait: false,
  // isMobileLandscape: false,
  // isTabletPortrait: false,
  // isTabletLandscape: false,
  // isPC: false,
  // isLargePC: false,

  //Check if clicking CHASE will lead to homepage
  isHomePage: false,

  //Check if Help and About bar is collapsed on PC
  isAboutHelpAndAboutShown: true,

  // Check if search bar is collapsed on mobile/tablet
  isSearchBarShown: false,
  isAboutTextShown: false,

  // For searching/filtering -----------------------
  searchText: "",
  isBicycleSelected: true,
  isVehicleSelected: true,
  isPedestrianSelected: true,
  filterSelectedCategories: ["Bicycle", "Vehicle", "Pedestrian"],

  // For card container carousel -----------------------
  lengthOfFilteredBicycleInterventions: 0,
  lengthOfFilteredVehicleInterventions: 0,
  lengthOfFilteredPedestrianInterventions: 0,
  bicycleInterventionIndexes: [0, 1, 2],
  vehicleInterventionIndexes: [0, 1, 2],
  pedestrianInterventionIndexes: [0, 1, 2],

  // used only when first-load of the homepage
  bicycleRandomizedInterventionsOnHomepage: [],
  vehicleRandomizedInterventionsOnHomepage: [],
  pedestrianRandomizedInterventionsOnHomepage: [],

  // Interventions data -----------------------
  interventions: [
    {
      MainCategory: "Vehicle",
      SubCategory: "Vertical Deflections",
      Intervention: "Speed Humps",
      Description:
        "Raised sections of the roadway designed to discourage motor vehicle drivers from travelling at excessive speeds.",
      StudyDesigns:
        "From the environmental scan, 9 studies were identified: 3 pre/post studies(*2-*-2*)(*3-*-3*)(*4-*-4*), 2 reviews(*5-*-5*)(*6-*-6*), 1 cross-sectional(*7-*-7*), 1 case-control(*8-*-8*) and 2 case studies(*9-*-9*)(*10-*-10*).",
      QualityOfStudies:
        "Quality of studies: Of the 3 pre-post studies included, 1 scored moderate(*3-*-3*) and 1 strong(*2-*-2*) on the critical appraisal. One study(*4-*-4*) did not complete rigorous statistical analysis and was excluded.",
      TacCgtcManual: "No",
      Collisions:
        "PMVCs: -22%(*2-*-2*)<br />Child PMVCs: -43%(*2-*-2*)<br />Adult PMVCs: -20%(*2-*-2*)<br />Older adult PMVCs: -17% (This result is not statistically significant)(*2-*-2*)<br />Fatal/Serious MVCs: -34%(*2-*-2*) (This result is not statistically significant)<br />Minimal Injury MVCs: -20%(*2-*-2*)<br />No injury MVCs: -22%(*2-*-2*)<br />One study reported results without any measures of statistical significance, limiting our ability to confidently interpret the results.(*3-*-3*)",
      CollisionsExtraTitle: "[% change]",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [2, 3, 4, 5, 6, 7, 8, 9, 10],
      Notes:
        "One set of wheels on the speed hump at a time (front lowers as the back raises). The vehicle should slow while approaching the speed hump, and can speed up after passing over.",
      Link:
        "https://nacto.org/publication/urban-street-design-guide/street-design-elements/vertical-speed-control-elements/speed-hump/",
      AdditionalNotes: "None",
      Interpretations:
        "Literature demonstrates that installing speed humps is effective in reducing the number of serious and fatal pedestrian motor vehicle collisions anywhere from 22 to 68%; and all collisions involving child pedestrians by 43%.",
      Locations: [
        "Toronto, ON, Canada(*2-*-2*)",
        "Durban, South Africa(*3-*-3*)",
      ],
    },
    {
      MainCategory: "Vehicle",
      SubCategory: "Vertical Deflections",
      Intervention: "Speed Tables",
      Description: `Similar to a speed hump, speed tables raise the entire wheelbase of a vehicle from the road to reduce its speed. Speed tables are longer than speed humps and flat-topped, with a height of 3–3.5” and a length of 22".`,
      StudyDesigns:
        "From the environmental scan, 6 studies were identified: 3 pre/post(*11-*-11*)(*12-*-12*)(*13-*-13*), 1 review(*6-*-6*), 1 cross-sectional(*13-*-13*) and 1 case study(*10-*-10*).",
      QualityOfStudies:
        "One study was included and scored strong(*11-*-11*) on the critical appraisal. Two studies were excluded, 1 was an area wide intervention(*13-*-13*) and 1 did not complete a rigorous statistical analysis(*12-*-12*).",
      TacCgtcManual: "No",
      Collisions:
        "One study did not find any change in collisions compared to controls.(*11-*-11*)",
      CollisionsExtraTitle: "[% change]",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [6, 10, 11, 12, 13],
      Notes:
        "Similar concept to speed hump, but with this intervention the entire base of support of the vehicle mounts the speed table before descending. Again, the vehicle should slow while approaching the speed table, and can speed up after dismounting fully.",
      Link:
        "https://nacto.org/publication/urban-street-design-guide/street-design-elements/vertical-speed-control-elements/speed-table/",
      AdditionalNotes: "None",
      Interpretations:
        "Literature demonstrates that installing speed tables is effective in reducing the total number of motor vehicle collisions by 20%; and collisions involving pedestrians by 42%.",
      Locations: ["New York, NY, USA(*11-*-11*)"],
    },
    {
      MainCategory: "Vehicle",
      SubCategory: "Vertical Deflections",
      Intervention: "Speed Bumps",
      Description:
        "Raised sections of the roadway designed to discourage drivers from travelling at excessive speeds. Speed bumps are considerably shorter than speed humps.",
      StudyDesigns:
        "From the environmental scan, 1 pre/post study(*29-*-29*) was identified.",
      QualityOfStudies:
        "The 1 study found investigated the effects of multiple interventions together; therefore, there was no ability to determine which intervention(s) was/were responsible for any changes. This study can be found in area-wide traffic calming.",
      TacCgtcManual: "No",
      Collisions: "N/A",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [29],
      Notes:
        'Similar to the speed hump, however the speed bumps much more "aggressive". It is shorter in length but typically the same height.',
      Link: "None",
      AdditionalNotes: "None",
      Interpretations: "Not possible here.",
      Locations: ["Switzerland(*29-*-29*)"],
    },
    {
      MainCategory: "Vehicle",
      SubCategory: "Horizontal Deflections",
      Intervention: "Road Diet",
      Description:
        "A reconfiguration of a roadway typically consisting of removing vehicle lanes and repurposing the space.",
      StudyDesigns:
        "From the environmental scan, 11 studies were identified: 7 pre/post(*11-*-11*)(*12-*-12*)(*14-*-14*)(*15-*-15*)(*16-*-16*)(*17-*-17*)(*18-*-18*)(*19-*-19*), 1 review(*5-*-5*), 1 ecological study(*7-*-7*), 1 cross-sectional(*20-*-20*) and 1 case study(*10-*-10*).",
      QualityOfStudies:
        "Of the 7 pre-post studies, 1 scored strong(*11-*-11*), and 1 scored moderate(*15-*-15*) on the critical appraisal. Five studies were excluded, 3 did not complete the rigorous statistical analysis(*12-*-12*)(*16-*-16*)(*18-*-18*), 1 was an area wide intervention(*13-*-13*), and 1 was a simulation study(*14-*-14*). The area-wide intervention can be found in the 'Other' section.",
      TacCgtcManual: "No",
      Collisions:
        "Total: [-67%](*11-*-11*)<br />MVCs: [-67%](*11-*-11*)<br />Injury/Fatal: [-70%](*11-*-11*)<br />Two studies did not find any change in PMVCs compared to controls.(*11-*-11*)(*15-*-15*)",
      CollisionsExtraTitle: "[% change]",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [5, 7, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      Notes:
        "Can be done in a number of ways. Main point: reduce the number of lanes in a roadway. Either add a turn lane, bike lanes/cycle track.",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations:
        "Literature evaluating the effectiveness of road diets is mixed. One study shows that road diets are effective in reducing the total number of motor vehicle collisions by 67%; and specifically fatal collisions by 70%. Another study found road diets not effective in reducing the total number of motor vehicle collisions.",
      Locations: ["New York, NY, USA(*11-*-11*)(*15-*-15*)"],
    },
    {
      MainCategory: "Vehicle",
      SubCategory: "Horizontal Deflections",
      Intervention: "Curb Extension",
      Description:
        "A horizontal intrusion of the curb resulting in narrowing of the roadway. Curb extensions can be installed mid-block or near an intersection.",
      StudyDesigns:
        "From the environmental scan, 3 studies were identified: 2 pre/post studies(*15-*-15*)(*21-*-21*) and 1 simulation study(*22-*-22*).",
      QualityOfStudies:
        "Of the 2 pre-post studies, 1 scored weak(*21-*-21*) and  1 moderate(*15-*-15*) on the critical appraisal.",
      TacCgtcManual: "Yes",
      Collisions: "PMVCs: [-33.8%](*15-*-15*)",
      CollisionsExtraTitle: "[% change]",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [15, 21, 22],
      Notes:
        "These additions to the curb shorten the crossing distance for pedestrians.",
      Link:
        "https://nacto.org/publication/urban-street-design-guide/street-design-elements/curb-extensions/",
      AdditionalNotes: "None",
      Interpretations:
        "Literature demonstrates that installing curb extensions is effective in reducing the total number of pedestrian motor vehicle collisions by 33.8%.",
      Locations: ["New York, NY, USA(*15-*-15*)"],
    },
    {
      MainCategory: "Vehicle",
      SubCategory: "Horizontal Deflections",
      Intervention: "Chicanes",
      Description:
        "A series of curb extensions on alternate sides of a roadway which narrow the roadway and require drivers to steer from on side to the other to travel through the chicane.",
      StudyDesigns:
        "From the environmental scan, 2 studies were identified: 2 reviews(*5-*-5*)(*6-*-6*).",
      QualityOfStudies:
        "One review reported data from multiple case studies and did not complete sufficient statistical analysis and therefore was considered weak on the critical appraisal. That study is summarized here. One review investigated the effects of multiple traffic calming interventions together; therefore, there was no ability to determine which intervention was responsible for any changes. This study is summarized in area-wide traffic calming.",
      TacCgtcManual: "No",
      Collisions:
        "Total MVCs: [-82%](*6-*-6*)<br />This study reported results without measures of statistical significance limiting our ability to interpret the results.(*6-*-6*)",
      CollisionsExtraTitle: "[% change]",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [5, 6],
      Notes:
        "Only one car can pass through the chicanes at a time, so one car must yield to the other. They are typically the width of one large lane.",
      Link:
        "https://nacto.org/publication/urban-street-design-guide/street-design-elements/curb-extensions/chicane/",
      AdditionalNotes: "None",
      Interpretations:
        "This study was considered 'weak' by the critical appraisal process. Chicanes are reported to be effective in reducing the total number of motor vehicle collisions by 82%.",
      Locations: ["USA(*6-*-6*)"],
    },
    {
      MainCategory: "Vehicle",
      SubCategory: "Horizontal Deflections",
      Intervention: "Chokers",
      Description:
        "Curb extensions that narrow a roadway by widening the side-walks and/or curb, effectively creating a pinch point along the street.",
      StudyDesigns:
        "From the environmental scan, 1 pre/post study(*14-*-14*) was identified.",
      QualityOfStudies:
        "The pre/post study did not complete a rigorous statistical analysis and was therefore considered weak on the critical appraisal. That study is summarized here.",
      TacCgtcManual: "No",
      Collisions: "N/A",
      Speed:
        "Mean change: [-3 to -12%](*14-*-14*)<br />85th percentile change: [-5 to -11%](*14-*-14*)",
      SpeedExtraTitle: "[% change, range]",
      Volume: "N/A",
      Yielding: "N/A",
      References: [14],
      Notes:
        "Similar to chicanes, however the wo curb extensions are aligned to reduce the width of the road significantly. Only one car can pass through at a time - the other must yield.",
      Link:
        "https://nacto.org/publication/urban-street-design-guide/street-design-elements/curb-extensions/pinchpoint/",
      AdditionalNotes: "Also known as: pinch points",
      Interpretations:
        "This study was considered 'weak' by the critical appraisal process. Chokers are reported to be effective in reducing the reported 85th percentile speed anywhere from 5 to 11km/hr.",
      Locations: ["Eagan, MN, USA(*14-*-14*)"],
    },
    {
      MainCategory: "Vehicle",
      SubCategory: "Intersections & Crossings",
      Intervention: "Roundabout",
      Description:
        "A circular intersection or junction in which vehicular traffic is permitted to flow in one direction around a central island.",
      StudyDesigns:
        "From the environmental scan, 9 studies were identified: 4 pre/post(*69-*-69*)(*70-*-70*)(*71-*-71*)(*72-*-72*), 1 area-wide interventions(*5-*-5*), 3 case studies(*9-*-9*)(*10-*-10*)(*73-*-73*), and 1 review(*37-*-37*).",
      QualityOfStudies:
        "Of the 4 pre/post studies, 1 scored strong(*69-*-69*), and 1 scored moderate(*70-*-70*) on the critical appraisal. Two did not complete rigorous statistical analysis and were therefore excluded(*71-*-71*)(*72-*-72*).",
      TacCgtcManual: "Yes",
      Collisions:
        "Total MVCs: [-21% to -38%](*69-*-69*)(*70-*-70*)<br />Injury MVCs: [- 65.8% to -76%](*69-*-69*)(*70-*-70*)",
      CollisionsExtraTitle: "[% change, range]",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [5, 9, 10, 37, 69, 70, 71, 72, 73],
      Notes: "",
      Link:
        "https://nacto.org/publication/urban-street-design-guide/intersections/minor-intersections/mini-roundabout/",
      AdditionalNotes: "None",
      Interpretations:
        "Literature demonstrates that installing a roundabout is effective in reducing the total number of motor vehicle collisions anywhere from 21 to 39%.",
      Locations: [
        "CA, CO, FL, IN, KS, ME, MI, NY, NC, SC, VT, and WA, USA(*70-*-70*)",
      ],
    },
    {
      MainCategory: "Vehicle",
      SubCategory: "Intersections & Crossings",
      Intervention: "Traffic Signals",
      Description:
        "Signaling devices positioned at intersections, pedestrian crossings, and other locations to control the flow of traffic.",
      StudyDesigns:
        "From the environmental scan, 6 studies were identified: 4 pre/post studies(*11-*-11*)(*15-*-15*)(*62-*-62*)(*74-*-74*), 1 case-control(*14-*-14*), and 1 review(*37-*-37*).",
      QualityOfStudies:
        "Of the 4 pre/post studies, 1 scored moderate(*15-*-15*), and 1 scored strong(*11-*-11*) on the critical appraisal. One study did not complete rigorous statistical analysis and was therefore excluded(*74-*-74*), another was excluded because it was a summary report of a previously reported analysis(*62-*-62*).",
      TacCgtcManual: "No",
      Collisions:
        "Total: [-25%](*11-*-11*)<br />MVCs: [-22%](*11-*-11*)<br />Injury/Fatal: [-20%](*11-*-11*)<br />Two studies did not find any change in PMVCs compared to controls.(*11-*-11*)(*15-*-15*)",
      CollisionsExtraTitle: "[% change, range]",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [11, 14, 15, 37, 62, 74],
      Notes:
        "I drew the conversion of an all-way stop sign intersection to a traffic signal controlled intersection. (Black bars are the stop lines on the pavement)",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations:
        "Literature evaluating the effectiveness of traffic signals is mixed. Studies show the effectiveness of traffic signals in reducing the total number of motor vehicle collisions range from 0 to 25%. Another study found traffic signals not effective in reducing the total number of motor vehicle collisions.",
      Locations: ["New York, NY, USA(*11-*-11*)(*15-*-15*)"],
    },
    {
      MainCategory: "Vehicle",
      SubCategory: "Intersections & Crossings",
      Intervention: "Channelized Right Turn",
      Description:
        "A lane designated for right turns, often at an intersection or junction.",
      StudyDesigns:
        "From the environmental scan, 5 studies were identified: 3 pre/post(*75-*-75*)(*76-*-76*)(*77-*-77*), and 2 reviews(*5-*-5*)(*53-*-53*).",
      QualityOfStudies:
        "Of the 3 pre/post studies, 2 scored moderate(*75-*-75*)(*76-*-76*) on the critical appraisal. One study was excluded due to an analysis using the same data(*77-*-77*). The 2 reviews were excluded due to study design.",
      TacCgtcManual: "No",
      Collisions:
        "Urban: -4% (signalized); -40% (unsignalized)(*76-*-76*)<br />Rural: -14% (unsignalized)(*76-*-76*)<br /><em>Fatal/Serious MVCs:</em><br />Urban: -9% (signalized)<br />Rural: -23% (unsignalized)(*76-*-76*)<br /><em>Conflicts [% change]:</em><br />Total: -51%(*75-*-75*)<br /><em>Severity:</em> -41%",
      CollisionsExtraTitle: "[% change]",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [5, 53, 75, 76, 77],
      Notes:
        "Right-turn slip lane. This sketch should have stoplights. Actions: cars travelling E/W driving normally. Cars driving N/S stopped at intersection. Cars turning right at the intersection use the slip lane without slowing other traffic.",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations:
        "Literature demonstrates that installing a channelized right turn is effective in reducing the total number of conflicts anywhere from 4 to 78%.",
      Locations: [
        "Penticton, BC, Canada(*75-*-75*)",
        "IL, IA, LA, MN, NE, NC, OR, VA, USA(*76-*-76*)",
      ],
    },
    {
      MainCategory: "Vehicle",
      SubCategory: "Intersections & Crossings",
      Intervention: "Left-Turn Bay",
      Description:
        "An exclusive left turn lane formed on the approach where the turn is to be made. Often located at intersections.",
      StudyDesigns:
        "From the environmental scan, 6 pre/post studies were identified.(*11-*-11*)(*15-*-15*)(*76-*-76*)(*77-*-77*)(*78-*-78*)(*79-*-79*)(*80-*-80*)",
      QualityOfStudies:
        "Of the 6 pre/post studies, 4 scored strong(*11-*-11*)(*78-*-78*)(*79-*-79*)(*80-*-80*) and 2 scored moderate(*15-*-15*)(*76-*-76*) on the critical appraisal.",
      TacCgtcManual: "Yes",
      Collisions:
        "Total MVCs: [-35 to -65%](*77-*-77*)(*78-*-78*)(*79-*-79*)(*80-*-80*)<br />Severe MVCs: [-71 to -85%](*78-*-78*)(*79-*-79*)<br />One study did not find any change in collisions compared to controls(*11-*-11*).",
      CollisionsExtraTitle: "[% change, range]",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [11, 15, 76, 77, 78, 79, 80],
      Notes:
        "A lane dedicated to left hand turning vehicles. Allows vehicles traveling straight through the intersection to continue without waiting for turning vehicles. I drew the conversion sign again, to show the disfference (red dots on cars are turning signals)",
      Link: "None",
      AdditionalNotes:
        "The LT lane could be created by converting a through traffic lane to LT lane for either direction. Otherwise the additional lane is added by widening the roadway.",
      Interpretations:
        "Literature demonstrates that installing a left turn bay is effective in reducing the total number of motor vehicle collisions anywhere from 10 to 64%; and specifically severe collisions by 73.2%. Two studies reported that installing a left turn bay is not effective in reducing motor vehicle collisions compared to controls.",
      Locations: [
        "Surrey, BC, Canada(*78-*-78*)(*79-*-79*)(*80-*-80*)",
        "New York, NY, USA(*11-*-11*)(*15-*-15*); IL, IA, LA, MN, NE, NC, OR, VA, USA(*76-*-76*)",
      ],
    },
    {
      MainCategory: "Vehicle",
      SubCategory: "Intersections & Crossings",
      Intervention: "Left-Turn phase",
      Description:
        "A traffic signal that provides drivers with the right-of-way to complete a left turn without the potential conflict with other traffic or pedestrian crossings.",
      StudyDesigns:
        "From the environmental scan, 3 pre/post studies(*11-*-11*)(*81-*-81*)(*82-*-82*) were identified.",
      QualityOfStudies:
        "All 3 pre/post studies scored strong(*11-*-11*)(*81-*-81*)(*82-*-82*) on the critical appraisal.",
      TacCgtcManual: "No",
      Collisions: "Total MVCs: [-17%](*11-*-11*)<br />PMVCs: [-43%](*11-*-11*)",
      CollisionsExtraTitle: "[% change, range]",
      PermissiveToProtected:
        "One study found a significant reduction in PMVCs and<br />left-turn collisions, however, no change was found in total MVCs, MVCs, or CMVCs(*81-*-81*).",
      PermissiveToProtectedPermissive:
        "Total MVCs: [+8](*82-*-82*)<br />One study did not find any change in collisions for any collision type(*81-*-81*).",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [11, 81, 82],
      Notes: "",
      Link: "None",
      AdditionalNotes:
        "Dedicated left-turn light, could be in conjunction with a left-turn bay, or could be left-turn + go-straight light. Definitely need a solid green arrow. Both permissive and protected must be illustrated, maybe have one above the other.",
      Interpretations:
        "Literature demonstrates that installing a left turn phase is effective in reducing the total number of motor vehicle collisions by 17%; and collisions with pedestrians by 43%. Literature demonstrates that changing a left turn phase from a permissive phase to a protected only phase is effective in reducing the total number of motor vehicle collisions anywhere from 14 to 55%; and collisions with pedestrians by 67%. Literature demonstrates that changing a left turn phase from a permissive phase to a protected/permissive phase is effective in reducing the total number of motor vehicle collisions by 33%; and collisions with pedestrians by 38%.",
      Locations: [
        "Toronto, ON, Canada(*82-*-82*)",
        "New York, NY, NC, USA(*11-*-11*)(*81-*-81*)",
      ],
    },
    {
      MainCategory: "Vehicle",
      SubCategory: "Intersections & Crossings",
      Intervention: "Advance Stop/Yield Line",
      Description:
        "A stop bar placed in advance of a marked crosswalk to indicate where vehicles are required to stop or yield to discourage drivers from stopping too close to crosswalks.",
      StudyDesigns:
        "From the environmental scan, 8 studies were identified: 4 pre/post(*47-*-47*)(*83-*-83*)(*84-*-84*)(*85-*-85*), 2 simulation studies(*22-*-22*)(*86-*-86*), 1 case study(*10-*-10*), and 1 review(*37-*-37*).",
      QualityOfStudies:
        "Of the 4 pre/post studies, 2 scored moderate(*47-*-47*)(*85-*-85*) on the critical appraisal. Two studies did not complete a rigorous statistical analysis and were therefore excluded(*83-*-83*)(*84-*-84*).",
      TacCgtcManual: "No",
      Collisions:
        "PMVCs: [-36%](*47-*-47*)<br />One study reported no change in total collisions.(*47-*-47*)",
      CollisionsExtraTitle: "[% change]",
      Speed: "N/A",
      Volume: "N/A",
      Yielding:
        "Compliance: [57%](*85-*-85*)<br />Drivers stopping >3ft back: [+18%](*85-*-85*)<br />Drivers stopping in crosswalk: [-18%](*85-*-85*)",
      YieldingExtraTitle: "[% change]",
      References: [10, 22, 37, 47, 83, 84, 85, 86],
      Notes:
        "A line drawn on the pavement demonstrating to cars where they should stop ahead of the crosswalk. Actions: car approaches and stops behind the line. Pedestrian crosses at crosswalk.",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations:
        "Literature demonstrates that installing an advanced stop/yield line is effective in reducing the number of pedestrian motor vehicle collisions by 36%, and the number of drivers entering the crosswalk by 18%. Advance stop/yield lines are also effective in increasing the number of drivers stopping >3ft back from the crosswalk anywhere from 18 to 40.5%. Compliance with the advance stop light was 57%.",
      Locations: ["USA(*47-*-47*)(*85-*-85*)"],
    },
    {
      MainCategory: "Vehicle",
      SubCategory: "Intersections & Crossings",
      Intervention: "Raised Intersections",
      Description:
        "An intersection constructed at a higher elevation than the adjacent roadway.",
      StudyDesigns:
        "From the environmental scan, 2 studies were identified: 1 simulation study(*14-*-14*) and 1 pre/post case study(*10-*-10*).",
      QualityOfStudies:
        "The 1 pre/post study(*10-*-10*) scored weak on the critical appraisal. That study is summarized here.",
      TacCgtcManual: "No",
      Collisions: "N/A",
      Speed: "85th percentile: [-1%](*10-*-10*)",
      SpeedExtraTitle: "[% change]",
      Volume: "N/A",
      Yielding: "N/A",
      References: [10, 14],
      Notes:
        "Similar concept to speed tables, however it spans the entire intersection. Cars and pedestrians treat the intersection the same.",
      Link:
        "https://nacto.org/publication/urban-street-design-guide/intersections/minor-intersections/raised-intersections/",
      AdditionalNotes: "None",
      Interpretations:
        "This study was considered 'weak' by the critical appraisal process. Raised intersections are reported to be effective in reducing the reported 85th percentile speed by 1%.",
      Locations: ["USA(*10-*-10*)"],
    },
    {
      MainCategory: "Vehicle",
      SubCategory: "Intersections & Crossings",
      Intervention: "Traffic Circles",
      Description:
        "A circular junction in which traffic flows one way around a circular island, typically controlled with stop signs or traffic signals. Similar to a traditional roundabout, but considerably larger in diameter.",
      StudyDesigns:
        "From the environmental scan, 1 case-crossover study(*111-*-111*) was identified.",
      QualityOfStudies:
        "The included study scored strong(*111-*-111*) on the critical appraisal.",
      TacCgtcManual: "No",
      Collisions: "Odds of CMVCs: [798%] (*111-*-111*)",
      CollisionsExtraTitle: "[% change]",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [111],
      Notes:
        "Smaller version of a roundabout. Usually found in residential neighbourhoods.",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations:
        "Evidence suggests that installing a traffic circle increases the odds of cyclist motor vehicle collisions by nearly 8 times.",
      Locations: [
        "Toronto, ON, Canada(*111-*-111*)",
        "Vancouver, BC, Canada(*111-*-111*)",
      ],
    },
    {
      MainCategory: "Vehicle",
      SubCategory: "Intersections & Crossings",
      Intervention: "Diagonal Diverters",
      Description:
        "A raised barrier placed diagonally across an intersection forcing traffic to turn rather than proceeding through the intersection.",
      StudyDesigns:
        "From the environmental scan, 2 studies were identified: 1 case-crossover(*111-*-111*), and 2 case studies(*9-*-9*)(*10-*-10*).",
      QualityOfStudies:
        "The included study scored strong(*111-*-111*) on the critical appraisal.",
      TacCgtcManual: "No",
      Collisions: "Odds of Cyclist injury: [-96%](*111-*-111*)",
      CollisionsExtraTitle: "[% change]",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [9, 10, 111],
      Notes: "",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations:
        "Evidence suggests that installing a diagonal diverter reduces the odds of cyclist injury by 96%.",
      Locations: [
        "Toronto, ON, Canada(*111-*-111*)",
        "Vancouver, BC, Canada(*111-*-111*)",
      ],
    },
    {
      MainCategory: "Vehicle",
      SubCategory: "Other",
      Intervention: "Speed Cameras",
      Description:
        "A camera mounted beside or over a roadway, or installed in an enforcement vehicle to detect vehicle speeding.",
      StudyDesigns:
        "From the environmental scan, 6 pre/post(*13-*-13*)(*23-*-23*)(*24-*-24*)(*25-*-25*)(*26-*-26*)(*27-*-27*) studies were identified.",
      QualityOfStudies:
        "Of the 6 pre-post studies, 2 scored moderate(*23-*-23*)(*24-*-24*), and 4 scored strong(*13-*-13*)(*25-*-25*)(*26-*-26*)(*27-*-27*) on the critical appraisal.",
      TacCgtcManual: "No",
      Collisions:
        "Total MVCs, [% change, range]: [-16 to -54%](*24-*-24*)(*25-*-25*)<br />PMVCs: [-22%* to -26%* reduction](*13-*-13*)(*26-*-26*)(*27-*-27*)<br />Fatal/Serious MVCs: [-11% to -13%](*13-*-13*)(*26-*-26*) (These results are not statistically significant)<br />Property damage MVCs: -56%(*25-*-25*)",
      CollisionsExtraTitle: "[% change, range]",
      Speed:
        "Mean speed: [-2.8km/hr to -16km/hr](*23-*-23*)(*24-*-24*)(*25-*-25*)(*26-*-26*)<br />85th percentile speed: [ -8.05km/hr to -10.9 km/hr](*13-*-13*)(*26-*-26*)<br />Odds of exceeding speed limit: -80%(*23-*-23*)<br />Odds of exceeding the speed limit by more than 10%: -86%(*23-*-23*)",
      SpeedExtraTitle: "[change, range]",
      Volume: "N/A",
      Yielding: "N/A",
      References: [13, 23, 24, 25, 26, 27],
      Notes: "",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations:
        "Literature demonstrates that installing speed cameras is effective in reducing the total number of motor vehicle collisions anywhere from 16 to 54%; and specifically personal injury collisions by 22 to 31%. Speed cameras are also effective in reducing reported 85th percentile speed by 5.3km/hr; and mean speed by 2.8 to 14.5km/hr.",
      Locations: [
        "UK(*13-*-13*)",
        "Flanders, Belgium(*23-*-23*)",
        "BC, Canada(*24-*-24*)",
        "Scottsdale, AZ, USA(*25-*-25*)",
        "Great Britain(*26-*-26*)(*27-*-27*)",
      ],
    },
    {
      MainCategory: "Vehicle",
      SubCategory: "Other",
      Intervention: "Dedicated Right of Way",
      Description: "Implementation of a dedicated right of way for streetcars.",
      StudyDesigns:
        "From the environmental scan, 1 pre/post study(*28-*-28*) was identified.",
      QualityOfStudies:
        "The included study scored strong(*28-*-28*) on the critical appraisal.",
      TacCgtcManual: "Yes",
      Collisions:
        "PMVCs: -48%*<br />Child PMVCs: -87%*<br />Adult PMVCs: -39% reduction*<br />Older Adult (60+) PMVCs: -25% reduction (not statistically significant)<br />Minor Injury PMVCs: -44%*(*28-*-28*)",
      CollisionsExtraTitle: "[% change]",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [28],
      Notes: "",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations:
        "Literature demonstrates that installing a dedicated right of way for streetcars is effective in reducing the total number of pedestrian motor vehicle collisions by 48%. The intervention was also effective in reducing collisions involving children and adults and for minor injury collisions; however, the intervention was not effective in reducing collisions involving older adults (60 years of age and older).",
      Locations: ["Toronto, ON, Canada(*28-*-28*)"],
    },
    {
      MainCategory: "Vehicle",
      SubCategory: "Other",
      Intervention: "Lowering Speed Limits",
      Description: "Decreasing the maximum speed permitted on a roadway.",
      StudyDesigns:
        "From the environmental scan, 7 studies were identified: 6 pre/post(*11-*-11*)(*13-*-13*)(*29-*-29*)(*30-*-30*)(*31-*-31*)(*32-*-32*), 1 report(*33-*-33*).",
      QualityOfStudies:
        "Of the 6 studies, 4 scored strong(*11-*-11*)(*30-*-30*)(*31-*-31*)(*32-*-32*) on the critical appraisal. Two studies examined area-wide traffic calming interventions and were excluded(*13-*-13*)(*29-*-29*). We excluded the report as it presented the same data as one of the pre-post studies.(*30-*-30*)",
      TacCgtcManual: "Yes",
      Collisions:
        "Total: [-5 to -38%](*30-*-30*)(*31-*-31*)<br />Fatal/Serious MVCs: [-44%](*30-*-30*)<br />CMVCs: [-17%](*30-*-30*)<br />One study did not find any change in collisions compared to controls(*11-*-11*).",
      CollisionsExtraTitle: "[% change, range]",
      Speed:
        "Mean change: [-1.6 to -4.9km/h](*31-*-31*)(*32-*-32*)<br />85th percentile: [-3 to -6km/hr](*31-*-31*)(*32-*-32*)",
      SpeedExtraTitle: "[% change, range]",
      Volume: "N/A",
      Yielding: "N/A",
      References: [11, 13, 29, 30, 31, 32, 33],
      Notes:
        "Side by side roadways with one car travelling at 50km/h and the other at 40km/h. Could show he cars stopping (one easier than the other).",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations:
        "Literature demonstrates that lowering speed limits is effective in reducing both the total number of motor vehicle collisions anywhere from 5 to 42%; and reported 85th percentile speed by 3 to 6km/hr.",
      Locations: [
        "Edmonton, AB(*32-*-32*), Canada",
        "New York, NY, USA(*11-*-11*)",
        "Sweden(*31-*-31*)",
        "London, UK(*30-*-30*)",
      ],
    },
    {
      MainCategory: "Vehicle",
      SubCategory: "Other",
      Intervention: "Non-Pedestrian Medians",
      Description:
        "An elevated median constructed on the centerline of a two-way road to create a barrier between opposing travel lanes.",
      StudyDesigns:
        "From the environmental scan, 2 pre/post studies(*15-*-15*)(*34-*-34*) were identified.",
      QualityOfStudies:
        "One study was included and scored moderate(*15-*-15*) on the critical appraisal. One study was excluded due to being an area-wide intervention(*34-*-34*).",
      TacCgtcManual: "Yes",
      Collisions:
        "PMVCs: No change in collisions compared to control.(*15-*-15*)",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [15, 34],
      Notes:
        "A median that runs the length of the road dividing the opposing traffic. Not designed for pedestrian use (as opposed to refuge islands). Sometimes they have trees/plants for decoration.",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations:
        "Literature demonstrates that installing a non-pedestrian median is not effective in reducing pedestrian motor vehicle collisions compared to controls.",
      Locations: ["New York, NY, USA(*15-*-15*)"],
    },
    {
      MainCategory: "Vehicle",
      SubCategory: "Other",
      Intervention: "On-Street Parking",
      Description:
        "The reduction of the width of the roadway by allowing motor vehicles to park parallel to the curb.",
      StudyDesigns:
        "From the environmental scan, 5 studies were identified: 1 pre/post(*15-*-15*), 2 simulation studies(*22-*-22*)(*35-*-35*), 1 mathematical modeling study(*36-*-36*) and 1 review(*37-*-37*).",
      QualityOfStudies:
        "One study was included and scored strong(*15-*-15*) on the critical appraisal. The review was excluded due to study design.",
      TacCgtcManual: "Yes",
      Collisions:
        "PMVCs: No change in collisions compared to control(*15-*-15*)",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [15, 22, 35, 36, 37],
      Notes:
        "Parking on the street that reduces the number of lanes in a given section. I have sketched the cars merging out of the parking lane (optional).",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations:
        "Literature demonstrates that permitting on-street parking is not effective in reducing pedestrian motor vehicle collisions compared to controls.",
      Locations: ["New York, NY, USA(*15-*-15*)"],
    },
    {
      MainCategory: "Vehicle",
      SubCategory: "Other",
      Intervention: "Area-Wide Traffic Calming",
      Description:
        "A suite of built environment interventions in a geographic area intended to slow the speed of motor vehicles to improve road user safety.",
      StudyDesigns:
        "From the environmental scan, 3 pre/post studies(*13-*-13*)(*29-*-29*)(*34-*-34*) and 2 reviews(*5-*-5*)(*33-*-33*) were identified. Each of the 5 studies examined a unique set of traffic calming interventions. Below is a summary of the outcomes reported in each study.",
      QualityOfStudies:
        "The five included studies scored moderate(*5-*-5*)(*29-*-29*)(*34-*-34*) and strong(*13-*-13*)(*33-*-33*) on the critical appraisal.",
      TacCgtcManual: "No",
      Collisions:
        "Total MVCs: [-15%](*29-*-29*) One study did not report a statistically significant change(*5-*-5*).<br />PMVCs: No statistically significant change was reported.<br />Fatal MVCs: No statistically significant change was reported.<br />Total Injury MVCs: [-11%](*5-*-5*)<br />Personal Injury MVCs: [-15 to -45%](*13-*-13*)(*29-*-29*)<br />Fatal/Serious MVCs: [-14 to -42%](*13-*-13*)(*29-*-29*)(*33-*-33*)",
      CollisionsExtraTitle: "[% change, range]",
      Speed:
        "Mean speed: [-13.7 km/hr]<br />85th percentile speed: [-32 km/hr to -14.2 km/hr]",
      SpeedExtraTitle: "[change, range]",
      Volume: "Mean: [-6%](*13-*-13*). One study found no change.(*34-*-34*)",
      VolumeExtraTitle: "[% change]",
      PedestrianCrossingTime: "[-28%](*34-*-34*)",
      PedestrianCrossingTimeExtraTitle: "[% change]",
      Yielding: "N/A",
      References: [4, 5, 13, 29, 33, 34],
      Notes:
        "Multiple traffic calming interventions implemented in a neighbourhood.",
      Link: "None",
      AdditionalNotes:
        "Speed humps, chicanes, chokers, the ones illustrated in the image are pretty common",
      Interpretations:
        "Due to the complexity of the interventions included here, interpretation of the estimates is not possible.",
      Locations: [
        "New Jersey, USA(*34-*-34*)",
        "Switzerland(*29-*-29*)",
        "UK(*5-*-5*)(*13-*-13*)(*33-*-33*)",
        "Australia(*5-*-5*)",
      ],
    },
    {
      MainCategory: "Vehicle",
      SubCategory: "Other",
      Intervention: "Street Closures",
      Description:
        "A barrier extending the entire width of a roadway which obstructs all motor vehicle traffic from continuing along the roadway.",
      StudyDesigns:
        "From the environmental scan, 5 studies were identified: 2 reviews(*5-*-5*)(*6-*-6*), 1 cross sectional(*7-*-7*), and 2 pre-post case studies(*9-*-9*)(*10-*-10*).",
      QualityOfStudies:
        "One study summarized the effects of multiple traffic calming interventions together, therefore it is impossible to determine which intervention was responsible for any changes(*5-*-5*). This study is summarized in area-wide traffic calming. One review was excluded due to study design, the other studies did not complete rigorous statistical analysis and were considered weak on the critical appraisal. The results from the case studies are summarized here.",
      TacCgtcManual: "No",
      Collisions: "N/A",
      Speed: "85th percentile: [-8.6%](*9-*-9*)",
      SpeedExtraTitle: "[% change]",
      Volume:
        "Traffic: [-33 to -44%](*9-*-9*)(*10-*-10*)<br />These studies reported results without measures of statistical significance limiting our ability to interpret the results.(*9-*-9*)(*10-*-10*)",
      VolumeExtraTitle: "[% change, range]",
      Yielding: "N/A",
      References: [5, 6, 7, 9, 10],
      Notes:
        "Streets are closed specifically to cars. Bikes and pedestrians may continue to use.",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations:
        "This study was considered 'weak' by the critical appraisal process. Street closures are reported to be effective in reducing 85th percentile speed by 8.6%; and traffic volume by 33 to 44%.",
      Locations: ["USA(*9-*-9*)(*10-*-10*)"],
    },
    {
      MainCategory: "Vehicle",
      SubCategory: "Other",
      Intervention: "Road Surface",
      Description:
        "The surface material of the roadway intended to sustain vehicular or foot traffic.",
      StudyDesigns:
        "From the environmental scan, 1 review(*5-*-5*) was identified.",
      QualityOfStudies:
        "The review summarized the effects of multiple traffic calming interventions together; therefore, it is impossible to determine which intervention was responsible for any changes(*5-*-5*). This study is summarized in area-wide traffic calming.",
      TacCgtcManual: "No",
      Collisions: "N/A",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [5],
      Notes: "",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations: "Not possible here.",
      Locations: ["N/A"],
    },
    {
      MainCategory: "Vehicle",
      SubCategory: "Other",
      Intervention: "Roadway Lighting",
      Description: "Lighting used to illuminate dark sections of the roadway.",
      StudyDesigns: "",
      QualityOfStudies:
        "The studies were not pre/post in design and were excluded.",
      TacCgtcManual: "No",
      Collisions: "N/A",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [37, 38, 39, 40],
      Notes: "",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations: "Not possible here.",
      Locations: ["N/A"],
    },
    {
      MainCategory: "Vehicle",
      SubCategory: "",
      Intervention: "All-Way Stop Signs",
      Description:
        "A four-way intersection with a stop sign on each corner forcing vehicles from all approaches of the road to stop before proceeding.",
      StudyDesigns: "",
      QualityOfStudies: "",
      TacCgtcManual: "N/A",
      Collisions: "N/A",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [],
      Notes: "",
      Link: "",
      AdditionalNotes: "None",
      Interpretations: "",
      Locations: ["N/A"],
    },
    {
      MainCategory: "Pedestrian",
      SubCategory: "Intersections & Crossings",
      Intervention: "Crosswalk Markings",
      Description:
        "Painted roadway markings located at signalized intersections and/or other areas intended for pedestrian crossing.",
      StudyDesigns:
        "From the environmental scan, 9 studies were identified: 4 pre/post studies(*11-*-11*)(*15-*-15*)(*41-*-41*)(*42-*-42*), 2 cross sectional(*43-*-43*)(*44-*-44*), 2 case-control(*45-*-45*)(*46-*-46*) and 1 review(*37-*-37*).",
      QualityOfStudies:
        "Of the 4 pre/post studies, 2 scored moderate(*15-*-15*)(*41-*-41*), and 2 strong(*11-*-11*)(*42-*-42*) on the critical appraisal. The review was excluded due to study design.",
      TacCgtcManual: "No",
      Collisions:
        "Total: [-29%](*41-*-41*)<br />PMVCs: [-37 to -69%](*11-*-11*)(*15-*-15*)(*41-*-41*)(*42-*-42*)<br />Two studies found no significant change in total, severe, and injury/fatal MVCs compared to controls.(*11-*-11*)(*41-*-41*)",
      CollisionsExtraTitle: "[% change, range]",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [11, 15, 37, 41, 42, 43, 44, 45, 46],
      Notes:
        "The addition of zebra markings to a pedestrian crossing. This is probably not the best way to depict this, but I did a side by side to show the difference.",
      Link:
        "https://nacto.org/publication/urban-street-design-guide/intersection-design-elements/crosswalks-and-crossings/conventional-crosswalks/",
      AdditionalNotes: "None",
      Interpretations:
        "Literature evaluating the effectiveness of crosswalk markings is mixed. One study shows that crosswalk markings are effective in reducing the total number of motor vehicle collisions by 29%; and collisions involving pedestrians anywhere from 37 to 69%.",
      Locations: [
        "New York, NY, USA(*11-*-11*)(*15-*-15*)",
        "San Francisco, CA, USA(*42-*-42*)",
        "USA(*41-*-41*)",
      ],
    },
    {
      MainCategory: "Pedestrian",
      SubCategory: "Intersections & Crossings",
      Intervention: "Refuge Islands",
      Description:
        "Protected, raised median islands in the centre of a roadway that provide a location for pedestrians to safely wait for a gap in traffic so they can finish crossing.",
      StudyDesigns:
        "From the environmental scan, 5 studies were identified: 3 pre/post studies(*15-*-15*)(*21-*-21*)(*47-*-47*) and 2 case-control studies(*48-*-48*)(*43-*-43*).",
      QualityOfStudies:
        "Of the 3 pre/post studies, 2 scored moderate(*15-*-15*)(*47-*-47*) and 1 weak(*21-*-21*) on the critical appraisal. The 2 studies included are summarized here.",
      TacCgtcManual: "Yes",
      Collisions: "PMVCs: [-32% to -39%](*15-*-15*)(*47-*-47*)",
      CollisionsExtraTitle: "[% change, range]",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [15, 21, 43, 47, 48],
      Notes:
        "An island or median in the middle of a crosswalk so that pedestrians can break between traffic segments(?). Ignore the scribbled out arrow. Action: pedestrian crosses first portion of the roadway. Pauses on the refuge island, and then continues through the remainder of the roadway.",
      Link:
        "https://nacto.org/publication/urban-street-design-guide/intersection-design-elements/crosswalks-and-crossings/pedestrian-safety-islands/",
      AdditionalNotes: "None",
      Interpretations:
        "Literature demonstrates that installing refuge islands is effective in reducing the total number of pedestrian motor vehicle collisions anywhere from 32 to 39%.",
      Locations: ["New York, NY, USA(*15-*-15*)", "USA(*47-*-47*)"],
    },
    {
      MainCategory: "Pedestrian",
      SubCategory: "Intersections & Crossings",
      Intervention: "In-Pavement Flashing Lights",
      Description:
        "LED lights embedded in the pavement at pedestrian crosswalks to warn drivers of oncoming pedestrian traffic.",
      StudyDesigns:
        "From the environmental scan, 4 studies were identified: 3 pre/post studies(*49-*-49*)(*50-*-50*)(*51-*-51*) and 1 review(*37-*-37*).",
      QualityOfStudies:
        "One study was included and scored moderate(*51-*-51*) on the critical appraisal. Two studies(*49-*-49*)(*50-*-50*) did not complete rigorous statistical analysis and were excluded. The review was excluded due to study design.",
      TacCgtcManual: "No",
      Conflicts:
        "Rate of conflicts reduced to less than 1% from baseline in one group one, but this change was not statistically significant.",
      ConflictsExtraTitle: "[% change]",
      Collisions: "N/A",
      Speed:
        "[-2km/hr to -5km/hr] (only on roads where initial speeds exceeded 30km/hr)",
      SpeedExtraTitle: "[change, range]",
      Volume: "N/A",
      Yielding: "N/A",
      References: [37, 49, 50, 51],
      Notes:
        "Flashing lights that are activated when pedestrians push the button on the side of the road. Lights line either side of the crosswalk. Actions: car approaches crosswalk and stops. Pedestrian crosses. (Lights flashing).",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations:
        "Literature demonstrates that installing in-pavement flashing lights is effective in reducing potential conflicts almost entirely; mean speed by 2 to 5km/hr; and increasing the number of vehicles yielding to pedestrians anywhere from 35 to 70%.",
      Locations: ["Halifax City & Bat Yam City, Israel(*51-*-51*)"],
    },
    {
      MainCategory: "Pedestrian",
      SubCategory: "Intersections & Crossings",
      Intervention: "Pedestrian Countdown Signals",
      Description:
        "A numeric display indicating the number of seconds remaining for a pedestrian to complete crossing.",
      StudyDesigns:
        "From the environmental scan, 8 studies were identified: 6 pre/post(*52-*-52*)(*53-*-53*)(*54-*-54*)(*55-*-55*)(*56-*-56*)(*57-*-57*), 1 observational(*58-*-58*) and 1 case study(*59-*-59*).",
      QualityOfStudies:
        "Of the 6 pre/post studies, 1 scored moderate(*54-*-54*), 2 strong(*52-*-52*)(*57-*-57*) on the critical appraisal. Two of the pre/post studies did not complete rigorous statistical analysis(*53-*-53*)(*56-*-56*) and were excluded, another was excluded due to an updated analysis using the same data(*55-*-55*).",
      TacCgtcManual: "Yes",
      Collisions:
        "Total MVCs: [+7.5 to +26%](*52-*-52*)(*57-*-57*)<br />Crash reduction factor: 10.6% (10.6% expected reduction in collisions)(*54-*-54*)<br /><br /><em>Age:</em><br />MVCs, 0-15: - 4% [-31%, +33%](*52-*-52*) (Not statistically significant).<br />MVCs, 16-59: +34%*(*52-*-52*)<br />MVCs, >59: +14%*(*52-*-52*)<br /><br /><em>Injury Severity:</em><br />No injury: No statistically significant effect was observed for non-injurious collisions.<br />Minor/minimal injury: +25%*(*52-*-52*)<br />Fatal/Serious injury: +51%* [+6%, +116%](*52-*-52*). One study did not find a statistically significant effect(*54-*-54*).<br />Fatal injuries only: No statistically significant effect was observed(*57-*-57*).<br />Non-fatal injuries: No statistically significant effect was observed(*57-*-57*).<br />Property damages only: +8%*(*57-*-57*)<br />Crash Reduction Factor: 9.2%*(*54-*-54*) There was a 9.2% reduction in expected collisions.",
      CollisionsExtraTitle: "[% change, range]",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [52, 53, 54, 55, 56, 57, 58, 59],
      Notes:
        "Timer on pedestrian crosswalk stoplight counts down with remainder of crossing time. Actions: N/S cars travel through intersection, E/W cars are stopped at crosswalks. Pedestrians cross N/S. (numbers countdown as pedestrians cross).",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations:
        "Literature evaluating the effectiveness of pedestrian countdown signals is mixed. One study shows a 10% decrease in total number of motor vehicle collisions, another study shows a 7.5% increase. One study showed a 26% increase in the total number of pedestrian motor vehicle collisions.",
      Locations: [
        "Toronto, ON, Canada(*52-*-52*)(*57-*-57*)",
        "Jacksonville and Gainesville, FL, USA(*54-*-54*)",
      ],
    },
    {
      MainCategory: "Pedestrian",
      SubCategory: "Intersections & Crossings",
      Intervention: "Exclusive Pedestrian Phase",
      Description:
        "A dedicated pedestrian crossing signal at intersections that hold drivers in the stopped position in all directions.",
      StudyDesigns:
        "From the environmental scan, 7 studies were identified: 4 pre/post(*11-*-11*)(*60-*-60*)(*61-*-61*)(*62-*-62*), 1 simulation study(*63-*-63*), 1 case control(*64-*-64*) and 1 review(*37-*-37*).",
      QualityOfStudies:
        "Of the 4 pre/post studies, 1 scored moderate(*60-*-60*) and 1 strong(*11-*-11*) on the critical appraisal. One of the pre/post studies did not complete rigorous statistical analysis(*61-*-61*) and was therefore excluded, another was excluded because it was a summary report of a previously reported analysis(*62-*-62*).",
      TacCgtcManual: "No",
      Collisions:
        "Total: [-12% to -22%](*60-*-60*)<br />PMVCs: [-35%](*11-*-11*)<br />Injury/fatal: [-25%](*60-*-60*)<br />One study found no significant change in total, MVCs, or injury/fatal MVCs compared to controls.(*11-*-11*)",
      CollisionsExtraTitle: "[% change, range]",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [11, 37, 60, 61, 62, 63, 64],
      Notes:
        "A signal phase where only pedestrians have the right of way. All cars are stopped to allow pedestrians to cross. Actions: N/S & E/W cars are all stopped at crosswalks. Pedestrians cross in all directions.",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations:
        "Literature demonstrates that installing an exclusive pedestrian phase is effective in reducing the total number of motor vehicle collisions anywhere from 12 to 22%; and collisions with pedestrians by 39 to 57%.",
      Locations: [
        "New York City, NY, USA(*11-*-11*)",
        "Oakland, CA, USA(*64-*-64*)",
      ],
    },
    {
      MainCategory: "Pedestrian",
      SubCategory: "Intersections & Crossings",
      Intervention: "Leading Pedestrian Phase",
      Description:
        "An advanced walk signal giving pedestrians lead time for crossing before vehicles get a green signal.",
      StudyDesigns:
        "From the environmental scan, 5 studies were identified: 4 pre/post(*65-*-65*)(*66-*-66*)(*67-*-67*)(*68-*-68*) and 1 review(*53-*-53*).",
      QualityOfStudies:
        "Of the 4 pre/post studies, 2 scored moderate(*65-*-65*)(*66-*-66*) on the critical appraisal. Two of the pre/post studies did not complete rigorous statistical analysis(*67-*-67*)(*68-*-68*) and were therefore excluded.",
      TacCgtcManual: "Yes",
      Collisions:
        "Total PMVCs: -59%(*65-*-65*)<br />Odds of MVCs (Total): -95%(*66-*-66*)<br /><br /><em>Age:</em><br />Odds of MVCs (Senior): -89%(*66-*-66*)<br />Odds of MVCs (non- senior): -97%(*66-*-66*)<br />Odds of Yielding [% change]: -60%(*66-*-66*)",
      CollisionsExtraTitle: "[% change]",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [53, 65, 66, 67, 68],
      Notes:
        "A signal phase where pedestrians have an additional phase to begin crossing (in one direction). All cars are stopped for the duration of the phase. Actions: N/S & E/W cars are all stopped at crosswalks. Pedestrians cross E/W. After the leading phase, E/W cars begin traveling through the intersection.",
      Link:
        "https://nacto.org/publication/urban-street-design-guide/intersection-design-elements/traffic-signals/leading-pedestrian-interval/",
      AdditionalNotes:
        "Leading Pedestrian Phase can also be referred to as Leading Pedestrian Interval.",
      Interpretations:
        "Literature demonstrates that installing a leading pedestrian phase is effective in reducing the total number of pedestrian motor vehicle collisions anywhere from 21 to 58%, as well as a significant decrease in the chance of a pedestrian coming into close conflict with a vehicle, and the number of pedestrians yielding to turning vehicles.",
      Locations: [
        "State College, PA, USA(*65-*-65*)",
        "St. Petersburg, FL, USA(*66-*-66*)",
      ],
    },
    {
      MainCategory: "Pedestrian",
      SubCategory: "Intersections & Crossings",
      Intervention: "Pedestrian Overpass",
      Description:
        "A bridge that allows pedestrians safe crossing over busy roads without affecting traffic.",
      StudyDesigns:
        "From the environmental scan, 1 pre/post study(*98-*-98*) was identified.",
      QualityOfStudies:
        "The pre/post study did not complete a rigorous statistical analysis and was therefore considered weak on the critical appraisal. That study is summarized here.",
      TacCgtcManual: "Yes",
      Collisions: "Total PMVCs: +35% (absolute increase)(*98-*-98*)",
      CollisionsExtraTitle: "[% change]",
      Speed: "N/A",
      Volume: "+35% (no baseline measure was provided)(*98-*-98*)",
      Yielding: "N/A",
      References: [98],
      Notes:
        "A raised walkway that allows pedestrian to cross a major roadway safely without interacting with traffic. Actions: cars can continue driving normally on the roadway. Pedestrians walk up ramp and across the overpass.",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations:
        "This study was considered 'weak' by the critical appraisal process. Pedestrian overpasses were reported to increase the total number of pedestrian motor vehicle collisions by 33%. In this study, use of the pedestrian overpass post implementation was low (i.e., only 35% of pedestrians reported using the overpass); therefore, the authors speculate that the increase in collisions may be due to pedestrian crossing in other areas of the roadway.",
      Locations: ["Kampala, Uganda(*98-*-98*)"],
    },
    {
      MainCategory: "Pedestrian",
      SubCategory: "Intersections & Crossings",
      Intervention: "In-Street Yield to Pedestrian Sign",
      Description:
        "A sign placed within the roadway, to remind road users of pedestrian right-of-way at an un-signalized crossing.",
      StudyDesigns:
        "From the environmental scan, 6 studies were identified: 3 pre/post(*99-*-99*)(*100-*-100*)(*101-*-101*), 2 time series(*83-*-83*)(*102-*-102*), and 1 review(*53-*-53*).",
      QualityOfStudies:
        "All 5 pre/post studies scored weak on the critical appraisal(*83-*-83*)(*99-*-99*)(*100-*-100*)(*101-*-101*)(*102-*-102*). Those studies are summarized here.",
      TacCgtcManual: "No",
      Collisions:
        "Left turn MVCs: [-20 to -65%](*100-*-100*)<br />Right turn MVCs: [-15 to -30%](*100-*-100*)<br />Two studies reported results without measures of statistical significance, limiting our ability to confidently interpret the results(*83-*-83*)(*102-*-102*). One study reported mixed results(*101-*-101*).<br /><br /><em>Conflicts [% change]:</em><br />Total right turn: -13%(*100-*-100*)<br />Total left turn: -21%(*100-*-100*)",
      CollisionsExtraTitle: " [% change, range]",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [53, 83, 99, 100, 101, 102],
      Notes: "",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations:
        "This study was considered 'weak' by the critical appraisal process. In-street yield pedestrian signs are reported to be effective in reducing the total number of motor vehicle collisions by 6 to 12%; the number of turning collisions anywhere from 15 to 65%. Further, in-street yield to pedestrian signs are reported to increase the number of drivers yielding to pedestrians by 13 to 46%.",
      Locations: [
        "Halifax, NS, Canada(*83-*-83*)",
        "Grand Rapids, MI, USA(*102-*-102*)",
        "Miami, FL, USA(*99-*-99*)",
        "Omaha & Lincoln, NE, USA(*100-*-100*)",
        "USA(*53-*-53*)(*101-*-101*)",
      ],
    },
    {
      MainCategory: "Pedestrian",
      SubCategory: "Intersections & Crossings",
      Intervention: "Rectangular Rapid Flashing Beacons",
      Description:
        "A pedestrian-actuated beacon consisting of two rectangular-shaped yellow lights that flashes when a pedestrian wishes to cross. The RRFBs are placed on both sides of a crosswalk below the pedestrian crossing sign and above the arrow indication pointing at the crossing.",
      StudyDesigns:
        "From the environmental scan, 11 studies were identified: 8 pre/post(*47-*-47*)(*53-*-53*)(*89-*-89*)(*90-*-90*)(*91-*-91*)(*92-*-92*)(*93-*-93*)(*94-*-94*) and 3 descriptive studies(*95-*-95*)(*96-*-96*)(*97-*-97*).",
      QualityOfStudies:
        "Of the 8 pre/post studies, 1 scored moderate(*47-*-47*) on the critical appraisal. Seven studies did not complete a rigorous statistical analysis and were, therefore, excluded(*53-*-53*)(*89-*-89*)(*90-*-90*)(*91-*-91*)(*92-*-92*)(*93-*-93*)(*94-*-94*).",
      TacCgtcManual: "No",
      Collisions: "PMVCs: [-47%](*47-*-47*)",
      CollisionsExtraTitle: "[% change]",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [47, 53, 89, 90, 91, 92, 93, 94, 95, 96, 97],
      Notes:
        "Similar to the PHB but this beacon is located in the middle of the road and at eye level. Again, it is activated by a push-button for pedestrians. Actions: pedestrian pushes the button. Lights start flashing. Car approaches and stops. Pedestrian crosses.",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations:
        "Literature demonstrates that installing a rectangular rapid flashing beacon is effective in reducing the total number of motor vehicle collisions by 47%.",
      Locations: ["USA(*47-*-47*)"],
    },
    {
      MainCategory: "Pedestrian",
      SubCategory: "Intersections & Crossings",
      Intervention: "Pedestrian Hybrid Beacons",
      Description:
        "A pedestrian-activated beacon consisting of two red lights above a single yellow light that flashes when a pedestrian wishes to cross. Traffic must stop at the indicated line.",
      StudyDesigns:
        "From the environmental scan, 5 studies were identified: 3 pre/post(*41-*-41*)(*47-*-47*)(*53-*-53*), 1 descriptive study(*87-*-87*), and 1 case-control(*88-*-88*).",
      QualityOfStudies:
        "Of the 3 pre/post studies, 1 study scored moderate(*47-*-47*) on the critical appraisal. Two studies did not complete rigorous statistical analysis and were, therefore, excluded(*41-*-41*)(*53-*-53*).",
      TacCgtcManual: "No",
      Collisions: "PMVCs: [-55%](*47-*-47*)",
      CollisionsExtraTitle: "[% change]",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [41, 47, 53, 87, 88],
      Notes:
        "A flashing overhead beacon indicating to vehicles that there is a crosswalk and pedestrians have the right of way. Activated by a push-button for pedestrians. Actions: pedestrian pushes the button. Lights start flashing. Car approaches and stops. Pedestrian crosses.",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations:
        "Literature demonstrates that installing a pedestrian hybrid beacon is effective in reducing the total number of pedestrian motor vehicle collisions by 55%.",
      Locations: ["USA(*47-*-47*)"],
    },
    {
      MainCategory: "Pedestrian",
      SubCategory: "Other",
      Intervention: "Pedestrian Barriers/Fences",
      Description:
        "A barrier or fence used to separate pedestrians from motor vehicles.",
      StudyDesigns:
        "From the environmental scan, 2 pre/post studies(*11-*-11*)(*15-*-15*) and 1 review(*37-*-37*) were identified.",
      QualityOfStudies:
        "Of the included studies, 1 study scored moderate(*15-*-15*) and the other strong(*11-*-11*) on the critical appraisal.",
      TacCgtcManual: "Yes",
      Collisions:
        "Both studies found no significant change in collisions compared to control locations.(*11-*-11*)(*15-*-15*)",
      CollisionsExtraTitle: "",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [11, 15, 37],
      Notes:
        "A physical barrier that separates the roadway from the sidewalk. This one is tricky to show from above. I *tried* to draw it from a bit of an angle.",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations:
        "Literature demonstrates that installing pedestrian barriers and fences is not effective in reducing pedestrian motor vehicle collisions compared to controls.",
      Locations: ["New York, NY, USA(*11-*-11*)(*15-*-15*)"],
    },
    {
      MainCategory: "Pedestrian",
      SubCategory: "",
      Intervention: "Sidewalks",
      Description:
        "A physically separated path along the side of a roadway designated for pedestrians.",
      StudyDesigns: "",
      QualityOfStudies: "",
      TacCgtcManual: "N/A",
      Collisions: "",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [],
      Notes: "",
      Link: "",
      AdditionalNotes: "None",
      Interpretations: "",
      Locations: ["N/A"],
    },
    {
      MainCategory: "Bicycle",
      SubCategory: "Bicycle with Traffic Integration",
      Intervention: "Painted Cycle Lanes",
      Description:
        "Bicycle-only lane marked with solid or dotted lines on the street surface.",
      StudyDesigns:
        "From the environmental scan, 8 studies were identified: 3 pre/post(*15-*-15*)(*103-*-103*)(*104-*-104*), 1 cross-sectional(*105-*-105*), 1 case-crossover(*106-*-106*), 1 time series(*107-*-107*), 1 observational(*108-*-108*), and 1 review(*109-*-109*).",
      QualityOfStudies:
        "Of the comparison studies, 6 scored strong(*103-*-103*)(*104-*-104*)(*105-*-105*)(*106-*-106*)(*107-*-107*)(*108-*-108*), and 2 scored moderate(*15-*-15*)(*109-*-109*) on the critical appraisal. ",
      TacCgtcManual: "Yes",
      Collisions:
        "Odds of Cyclist injury collisions: [-40 to +44%](*108-*-108*)<br />Five studies found no significant change in collisions compared to controls.(*15-*-15*)(*103-*-103*)(*104-*-104*)(*106-*-106*)<br />One study reported mixed results.(*105-*-105*)<br />One study reported results without measures of statistical significance limiting our ability to interpret the results.(*109-*-109*)",
      CollisionsExtraTitle: "[% change, range]",
      Speed: "N/A",
      Volume: "Cyclists: [+140%](*107-*-107*)",
      VolumeExtraTitle: "[% change]",
      Yielding: "N/A",
      References: [14, 15, 103, 104, 105, 106, 107, 108, 109],
      Notes: "",
      Link:
        "https://nacto.org/publication/urban-bikeway-design-guide/bike-lanes/conventional-bike-lanes/",
      AdditionalNotes: "None",
      Interpretations:
        "Evidence from pre/post studies demonstrates that installing a painted cycle lane had no effect on the number of cyclist motor vehicle collisions. Comparison: Literature in this area is mixed. Evidence from the comparison studies is mixed; for example, there are studies that demonstrate that painted cycle lanes are effective in reducing the risk of injury among cyclists anywhere from 14 to 47.6%; however, three studies reported painted cycle lanes having no significant change on collision frequency compared to controls. One study demonstrated a 140% increase in the number of cyclists using the cycle lane, post implementation.",
      Locations: [
        "Toronto, ON, Canada(*104-*-104*)(*106-*-106*)",
        "Vancouver, BC, Canada(*106-*-106*)",
        "New York, NY, USA(*14-*-14*)(*15-*-15*)(*103-*-103*)",
        "Philadelphia, PA, USA(*108-*-108*)",
        "Melbourne, Australia(*105-*-105*)",
      ],
    },
    {
      MainCategory: "Bicycle",
      SubCategory: "Bicycle with Traffic Integration",
      Intervention: "Cycle Tracks",
      Description:
        "A segregated bicycle lane alongside major streets, often separated with a physical barrier (e.g. bollards).",
      StudyDesigns:
        "From the environmental scan, 6 studies were identified: 1 pre/post(*110-*-110*), 2 case-crossover(*106-*-106*)(*111-*-111*), 1 case-control(*112-*-112*), and 2 reviews(*109-*-109*)(*113-*-113*).",
      QualityOfStudies:
        "The included studies scored moderate(*110-*-110*) and strong(*106-*-106*)(*111-*-111*)(*112-*-112*) on the critical appraisal. The two reviews were excluded due to lack of specific reporting (effect estimates) on this intervention.",
      TacCgtcManual: "Yes",
      Collisions:
        "Odds of CMVCs: [-89 to -95%](*106-*-106*)(*111-*-111*)<br />CMVCs: [-28%](*112-*-112*)",
      CollisionsExtraTitle: "[% change, range]",
      Speed: "N/A",
      Volume: "Cyclists: [+21% to +171%](*110-*-110*)",
      VolumeExtraTitle: "[% change, range]",
      Yielding: "N/A",
      References: [106, 109, 110, 111, 112, 113],
      Notes:
        "Similar to a bike lane but there is a physical barrier or delineation between the road and the cycle track.",
      Link:
        "https://nacto.org/publication/urban-bikeway-design-guide/cycle-tracks/one-way-protected-cycle-tracks/",
      AdditionalNotes: "None",
      Interpretations:
        "Evidence suggests that installing a cycle track is effective in increasing the total number of cyclists using the route anywhere from 21 to 171%. Installing a cycle track reduces the odds of a cyclist motor vehicle collision by 89 to 95% and the risk of a cyclist motor vehicle collision by 28%.",
      Locations: [
        "Austin, TX",
        "Chicago, IL",
        "Portland, OR",
        "San Francisco, CA",
        "Washington, D.C., USA(*110-*-110*)",
      ],
    },
    {
      MainCategory: "Bicycle",
      SubCategory: "Bicycle with Traffic Integration",
      Intervention: "Coloured Cycle Crossing",
      Description:
        "A distinct intersection crossing for bicyclists that is coloured and may have bicyclist-operated traffic signals.",
      StudyDesigns:
        "From the environmental scan, 2 studies were identified: 1 pre/post study(*116-*-116*) and 1 review(*113-*-113*).",
      QualityOfStudies:
        "One study scored strong(*116-*-116*) and the other study(*113-*-113*) was excluded due to not having an injury outcome.",
      TacCgtcManual: "Yes",
      Collisions:
        "Mixed results were found for the impact of coloured crossings on injuries: [-10% and +60%, 1 and 4 coloured segments, respectively](*116-*-116*)",
      CollisionsExtraTitle: "[% change, range]",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [113, 116],
      Notes: "",
      Link: "None",
      AdditionalNotes:
        "Intersection would be used in the same way, the colour just calls more attention to there being a bike lane in the intersection as well.",
      Interpretations:
        "Evidence from pre/post studies evaluating the effectiveness of coloured cycle crossings is mixed. An intersection with one coloured segment (i.e. one cycle crossing at the intersection is coloured) reduces the number of motor vehicle collisions by 10%; however, intersections with four coloured segments (i.e. four cycle crossings at the intersection are coloured) increase the number of motor vehicle collisions by 60%.",
      Locations: ["Denmark(*116-*-116*)"],
    },
    {
      MainCategory: "Bicycle",
      SubCategory: "Bicycle with Traffic Integration",
      Intervention: "Bike Box",
      Description:
        "A right-angle extension to a bicycle lane to the head of an intersection, which allows bicyclists to wait at the traffic signal, ahead of motor vehicle traffic.",
      StudyDesigns:
        "From the environmental scan, 2 pre/post studies(*114-*-114*)(*115-*-115*) were identified.",
      QualityOfStudies:
        "One study scored strong(*114-*-114*) on the critical appraisal. One study did not complete rigorous statistical analysis and was therefore excluded(*115-*-115*).",
      TacCgtcManual: "Yes",
      CyclistsEnteringCrosswalk:
        "[-10 to -16%]<br /><br /><em>All motor vehicle encroachment [% change, range]:</em> [-12.5% to -18.7%]<br /><br /><em>Conflicts [n change]:</em><br />Pre: 29, Post: 20",
      CyclistsEnteringCrosswalkExtraTitle: "[% change, range]",
      Collisions: "N/A",
      CollisionsExtraTitle: "[% change, range]",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [114, 115],
      Notes: "",
      Link:
        "https://nacto.org/publication/urban-bikeway-design-guide/intersection-treatments/bike-boxes/",
      AdditionalNotes: "None",
      Interpretations:
        "Evidence from pre/post studies demonstrates that installing a bike box is effective in reducing the number of conflicts anywhere from 38 to 251%, as well as the number of cyclists entering the crosswalk by 16%. Further, bike boxes decrease the number of drivers entering the crosswalk by 73%.",
      Locations: ["Portland, OR, USA(*114-*-114*)"],
    },
    {
      MainCategory: "Bicycle",
      SubCategory: "Bicycle with Traffic Integration",
      Intervention: "Bicycle Integrated Roundabout",
      Description:
        "A circular intersection of junction in which vehicular traffic is permitted to flow in one direction around a central island. Priority is typically given to traffic already in the junction. Roundabouts are smaller traffic circles.",
      StudyDesigns:
        "From the environmental scan, 2 pre/post(*117-*-117*)(*118-*-118*) studies were identified.",
      QualityOfStudies:
        "Of the included studies, 1 scored strong(*118-*-118*), and 1 scored moderate(*117-*-117*).",
      TacCgtcManual: "Yes",
      Collisions:
        "CMVCs: +27%(*117-*-117*)<br />Odds of Bicycle collisions [% change, range]<br />Total MVCs: [+27%](*117-*-117*)(*118-*-118*)<br />Fatal and serious injuries: [+44%](*117-*-117*)(*118-*-118*)<br />All cycle lanes: [+93%](*117-*-117*)(*118-*-118*)<br />Grade separated: [-56%] Not statistically significant.<br />Mixed traffic: [-91%] Not statistically significant.<br /><br /><em>Odds of Fatal and Serious collisions [% change]:</em><br />Mixed traffic: [+77%] Not statistically significant.<br />All cycle lanes: [+37%] Not statistically significant.",
      CollisionsExtraTitle: "[%change, range]",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [117, 118],
      Notes:
        "Similar to classic roundabout, however, this one has cycling facilities integrated into the design.",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations:
        "Evidence from pre/post studies demonstrates that installing a roundabout increases the odds of cyclist motor vehicle collisions by 27%. Roundabouts with bike lanes increase the odds of a cyclist motor vehicle collision by 93% compared to roundabouts in general. Grade separated paths are associated with a 17% to 44% reduction in the odds of a cyclist motor vehicle collision compared to roundabouts.",
      Locations: ["Flanders, Belgium(*117-*-117*)(*118-*-118*)"],
    },
    {
      MainCategory: "Bicycle",
      SubCategory: "Bicycle with Traffic Integration",
      Intervention: "Raised Bicycle Crossing",
      Description:
        "A distinct road crossing for bicyclists constructed at a higher elevation than the adjacent roadway, and may have bicyclist-operated traffic signals.",
      StudyDesigns: "",
      QualityOfStudies: "",
      TacCgtcManual: "",
      Collisions: "",
      CollisionsExtraTitle: "",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [],
      Notes: "",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations: "",
      Locations: [],
    },
    {
      MainCategory: "Bicycle",
      SubCategory: "Bicycle with Traffic Integration",
      Intervention: "Bicycle Signal Heads",
      Description:
        "An additional indicator, typically a bicycle stencil, with green, yellow, and red phases to signify bicycle-only movements within signalized intersections.",
      StudyDesigns: "",
      QualityOfStudies: "",
      TacCgtcManual: "",
      Collisions: "",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [],
      Notes: "",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations: "",
      Locations: ["N/A"],
    },
    {
      MainCategory: "Bicycle",
      SubCategory: "Other",
      Intervention: "Bicycle Signs",
      Description:
        "Physical signs found along the roadway indicating a bicycle route, or signaling to drivers to be aware of cyclists.",
      StudyDesigns: "",
      QualityOfStudies: "",
      TacCgtcManual: "",
      Collisions: "",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [],
      Notes: "",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations: "",
      Locations: ["N/A"],
    },
    {
      MainCategory: "Bicycle",
      SubCategory: "Other",
      Intervention: "Multi-Use Trail",
      Description:
        "Off-road paved or unpaved path or trail, shared with other non-motorized users (e.g. pedestrians, runners, or in-line skater).",
      StudyDesigns:
        "From the environmental scan, 2 case-crossover studies(*106-*-106*)(*111-*-111*) were identified.",
      QualityOfStudies:
        "The 2 included studies scored strong(*106-*-106*)(*111-*-111*) on the critical appraisal.",
      TacCgtcManual: "Yes",
      Collisions:
        "There were no significant changes, compared to controls, on both paved and non-paved trails(*106-*-106*)(*111-*-111*).",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [106, 111],
      Notes: "",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations:
        "Evidence suggests that installing multi-use trails is not effective in reducing collisions compared to controls, on both paved and non-paved trails.",
      Locations: [
        "Toronto, ON, Canada(*106-*-106*)(*111-*-111*)",
        "Vancouver, BC, Canada(*106-*-106*)(*111-*-111*)",
      ],
    },
    {
      MainCategory: "Bicycle",
      SubCategory: "Other",
      Intervention: "Cycle-Only Path",
      Description: "Off-road path or trail, designated for bicycle use only.",
      StudyDesigns:
        "From the environmental scan, 2 case-crossover studies(*106-*-106*)(*111-*-111*) were identified.",
      QualityOfStudies:
        "Of the comparisons studies, both scored strong(*106-*-106*)(*111-*-111*) on the critical appraisal.",
      TacCgtcManual: "Yes",
      Collisions:
        "There were no significant changes, compared to controls(*106-*-106*)(*111-*-111*).",
      Speed: "N/A",
      Volume: "N/A",
      Yielding: "N/A",
      References: [106, 111],
      Notes: "",
      Link: "None",
      AdditionalNotes: "None",
      Interpretations:
        "Evidence suggests that installing cycle-only paths is not effective in reducing collisions compared to controls.",
      Locations: [
        "Toronto, ON, Canada(*106-*-106*)(*111-*-111*)",
        "Vancouver, BC, Canada(*106-*-106*)(*111-*-111*)",
      ],
    },
  ],
  evidenceKPReferences: [
    {
      1: "Hagel, B.E., Macpherson, A., Howard, A. et al. The built environment and active transportation safety in children and youth: a study protocol. BMC Public Health 19, 728 (2019) doi:10.1186/s12889-019-7024-6",
    },
    {
      2: "Rothman L, Macpherson A, Buliung R, Macarthur C, To T, Larsen K, et al. Installation of speed humps and pedestrian-motor vehicle collisions in Toronto, Canada: a quasi-experimental study. BMC Public Health. 2015; 15:774",
    },
    {
      3: "Nadesan-Reddy N, Knight S. The effect of traffic calming on pedestrian injuries and motor vehicle collisions in two areas of the eThekwini Municipality: A before-and-after study. S Afr Med J. 2013;103(9):621-625",
    },
    {
      4: "Arbogast H, Patao M, Demeter N, Bachman S, Devietti E, Upperman JS, et al. The effectiveness of installing a speed hump in reducing motor vehicle accidents involving pedestrians under the age of 21. Journal of Transport & Health. 2018 Mar;8: 30–34",
    },
    {
      5: "Bunn F, Collier T, Frost C, Ker K, Roberts I, Wentz R. Traffic calming for the prevention of road traffic injuries: systematic review and meta-analysis. Injury Prevention. 2003;9:200-204",
    },
    {
      6: "Ewing R. Impacts of Traffic Calming. Transportation Research Board: Urban Street Symposium. 2000. TRB Circular E-C019",
    },
    {
      7: "Jones SJ, Lyons RA, John A, Palmer SR. Traffic calming policy can reduce inequalities in child pedestrian injuries: database study. Injury Prevention. 2005;11:152-156",
    },
    {
      8: "Tester JM, Rutherford GW, Wald Z, Rutherford MW. A matched case-control study evaluating the effectiveness of speed humps in reducing child pedestrian injuries. American Journal of Public Health. 2004;94(4):646-650.",
    },
    {
      9: "Transportation Research Information Services Database. Washington, DC: Transportation Research Board. 2001",
    },
    {
      10: "Ewing R (Publication: Institute of Transportation Engineers). Traffic calming: State of the practice. Washington, DC: Federal Highway Administration. 1999.",
    },
    {
      11: "Chen L, Chen C, Ewing R, McKnight CE, Srinivasan R, Roe M. Safety countermeasures and crash reduction in New York City – Experience and lessons learned. Accid Anal Prev. 2013; 50:312-322.",
    },
    {
      12: "Cottrell WD, Kim N, Martin PT, Perrin HJ. Effectiveness of traffic management in Salt Lake City, Utah. Journal of Safety Research. 2006;37:27-41.",
    },
    {
      13: "Mountain LJ, Hirst WM, Maher MJ. Are speed enforcement cameras more effective than other speed management measures? The impact of speed management schemes on 30 mph roads. Accid Anal Prev. 2005;37:742-754.",
    },
    {
      14: "Corkle J, Giese JL, Marti MM. Investigating the effectiveness of traffic calming strategies on driver behavior, traffic flow and speed. Minnesota Local Research Board. 2001; MN/RC – 2002-02.",
    },
    {
      15: "Kang B. Identifying street design elements associated with vehicle-to-pedestrian collision reduction at intersections in New York City. Accid Anal Prev. 2019.",
    },
    {
      16: "Huang HF, Stewart JR, Zeeger CV. Evaluation of lane reduction “road diet” measures on crashes and injuries. Transportation Research Record: Journal of the Transportation Research Board. 2002;1784(1):80-90.",
    },
    {
      17: "Gudz EM, Fang K, Handy SL. When a diet prompts a gain. Impact of a road diet on bicycling in Davis, California. Transportation Research Record: Journal of the Transportation Research Board. 2016;2587:61-67.",
    },
    {
      18: "Persaud B, Lyon C. Evaluation of lane reduction “road diet” measures on crashes. ITE Journal. 2010 Sept; 80(9):31.",
    },
    {
      19: "Stout TB, Pawlovich M, Souleyrette RR, Carriquiry A. Safety impacts of “road diets” in Iowa. Institute of Transportation Engineers. ITE Journal. 2006;76:24-27.",
    },
    {
      20: "Chai C. Koorey G, Nicholson A. The effectiveness of two-way street calming pinch-points. IPENZ Transportation Group Conference; 2011 March; Auckland, New Zealand.",
    },
    {
      21: "Huang HF, Cynecki MJ. Effects of traffic calming measures on pedestrian and motorist behavior. Transportation Research Record: Journal of the Transportation Research Board. 2000;1705:26-31.",
    },
    {
      22: "Bella F, Silvestri M. Effects of safety measures on driver’s speed behavior at pedestrian crossings. Accid Anal Prev. 2015;83:111-124.",
    },
    {
      23: "De Pauw E, Saniels S, Brijs T, Hermans E, Wets G. Behavioural effects of fixed speed cameras on motorways: Overall improved speed compliance or kangaroo jumps? Accid Anal Prev. 2014;73:132-140.",
    },
    {
      24: "Chen G, Meckle W, Wilson J. Speed and safety effect of photo radar enforcement on a highway corridor in British Columbia. Accid Anal Prev. 2002;34:129-138.",
    },
    {
      25: "Shin K, Washington SP, van Schalkwyk I. Evaluation of the Scottsdale Loop 101 automated speed enforcement demonstration program. Accid Anal Prev. 2009;41:393-403.",
    },
    {
      26: "Mountain LJ, Hirst WM, Maher MJ. Costing lives or saving lives: a detailed evaluation of the impact of speed cameras. Traffic, Engineering and Control. 2004;45(8):280-287.",
    },
    {
      27: "Li H, Graham DJ, Majumdar A. The impacts of speed cameras on road accidents: An application of propensity score matching methods. Accid Anal Prev. 2013;60:148-157.",
    },
    {
      28: "Richmond SA, Rothman L, Buliung R, Schwartz N, Larsen K, Howard A. Exploring the impact of a dedicated streetcar right-of-way on pedestrian motor vehicle collisions: A quasi experimental design. Accid Anal Prev. 2014;71:222-227.",
    },
    {
      29: "Lindenmann HP. The effects on road safety of 30 kilometer-per-hour zone signposting in residential districts. ITE Journal. 2005",
    },
    {
      30: "Grundy C, Steinbach R, Edwards P, Green J, Armstrong B, Wilkinson P. Effect of 20 mph traffic speed zones on road injuries in London, 1986-2006: controlled interrupted time series analysis. BMJ. 2009;339:b4469.",
    },
    {
      31: "Silvano AP, Bang KL. Impact of speed limits and road characteristics on free-flow speed in urban areas. Journal of Transportation Engineering. 2016 Feb;142(2):04015039.",
    },
    {
      32: "Islam MT, El-Basyouny K, Ibrahim SE. The impact of lowered residential speed limits on vehicle speed behavior. Safety Science. 2014;62:483-494.",
    },
    {
      33: "Grundy C, Steinbach R, Edwards P, Wilkinson P, Green J. 20 mph zones and road safety in London: A report to the London Road Safety Unit. London: London School of Hygiene and Tropical Medicine. 2008.",
    },
    {
      34: "King M. Pedestrian safety through a raised median and redesigned intersection. Transportation Research Board Annual Meeting. 2003",
    },
    {
      35: "Edquist J, Rudin-Brown CM, Lenné MG. The effects of on-street parking and road environment visual complexity on travel speed and reaction time. Accid Anal Prev. 2012;45:759-765.",
    },
    {
      36: "Cao Y, Yang ZZ, Zuo ZY. The effect of curb parking on road capacity and traffic safety. Eur Trans Res Rev. 2017;9:4.",
    },
    {
      37: "Retting RA, Ferguson SA, McCartt AT. A review of evidence-based traffic engineering measures designed to reduce pedestrian–motor vehicle crashes. American Journal of Public Health. 2003 Sept;93(9):1456-1463.",
    },
    {
      38: "Nabavi Niaki MS, Fu T, Saunier N, Miranda-Moreno LF, Amador L, Bruneau JF. Road lighting effects on bicycle and pedestrian accident frequency. Case study in Montreal, Quebec, Canada. Transportation Research Record: Journal of the Transportation Research Board. 2016;2555:86-94.",
    },
    {
      39: "Jagerbrand AK, Sjöbergh J. Effects of weather conditions, light conditions, and road lighting on vehicle speed. SpringerPlus. 2016;5:505.",
    },
    {
      40: "Wanvik PO. Effects of road lighting: An analysis based on Dutch accident statistics 1987-2006. Accid Anal Prev. 2009",
    },
    {
      41: "Fitzpatrick K, Park EG. Federal Highway Administration. Safety effectiveness of the HAWK pedestrian crossing treatment. Federal Highway Administration report. 2010; FHWA-HRT-10-045.",
    },
    {
      42: "Feldman M, Manzi JG, Mitman MF. Empirical Bayesian evaluation of safety effects of high-visibility school (yellow) crosswalks in San Francisco, California. J Transp Eng. 2010;2198:8-14.",
    },
    {
      43: "Zeeger CV, Huang H, Stewart J, Lagerwey P. Safety effects of marked versus unmarked crosswalks at uncontrolled locations: analysis of pedestrian crashes in 30 cities. Transportation Research Record: Journal of Transportation Research Board. 2001;1773(1):56-68.",
    },
    {
      44: "Iasmin H, Kojima A, Kubota H. Safety effectiveness of pavement design treatment at intersections: Left turning vehicles and pedestrians on crosswalks. IATSS Research. 2016 July;40(1):47-55.",
    },
    {
      45: "Gitelman V, Hakkert AS, Doveh E, Cohen A. A study of safety effects of road infrastructure improvements under Israeli conditions. International Conference: Traffic Safety on Three Continents. Moscow, Russia. 2001 Sept. Volume: VTI Konferens 18A.",
    },
    {
      46: "Koepsell T, McCloskey L, Wolf M, Moudon AV, Buchner D, Kraus J, et al. Crosswalk markings and the risk of pedestrian-motor vehicle collisions in older pedestrians. JAMA. 2002 Nov;288(17):2136-43.",
    },
    {
      47: "Zeeger C, Lyon C, Srinivasan R, Persaud B, Lan B, Smith S, et al. Development of crash modification factors for uncontrolled pedestrian crossing treatments. Transportation Research Record: Journal of the Transportation Research Board. 2017; 2636:1-8.",
    },
    {
      48: "Li L, Yang X, Yin L. Exploration of pedestrian refuge effect on safety crossing at signalized intersection. Transportation Research Record: Journal of the Transportation Research Board. 2010;2193(1):44-50.",
    },
    {
      49: "Prevedouros PD. Evaluation of in-pavement Flashing Lights on a Six-lane Arterial Pedestrian Crossing. In: ITE 2001 Annual Meeting. ITE. 2001.",
    },
    {
      50: "Karkee GJ, Nambisan SS, Pulugurtha SS. Motorist Actions at a Crosswalk With an In-Pavement Flashing Light System. Traffic Injury Prevention. 2010; 11:642-649.",
    },
    {
      51: "Hakkert AS, Gitelman V, Ben-Shabat E. An evaluation of crosswalk warning systems: effects on pedestrian and vehicle behaviour. Transportation Research Part F: Traffic Psychology and Behaviour. 2002; 5.4:275-292.",
    },
    {
      52: "Richmond SA, Willan AR, Rothman L, Camden A, Buliung R, Macarthur C, Howard A. The impact of pedestrian countdown signals on pedestrian-motor vehicle collisions: a reanalysis of data from a quasi-experimental study. Injury Prevention. 2014;20:155-158.",
    },
    {
      53: "Van Houten R, LaPlante J, Gustafson T. Evaluating pedestrian safety improvements. Michigan Department of Transportation Final Report. 2012;RC-1585.",
    },
    {
      54: "Kitali AE, Sando PET. A full Bayesian approach to appraise the safety effects of pedestrian countdown signals to drivers. Accid Anal Prev. 2017;106:327-335.",
    },
    {
      55: "Camden A, Buliung R, Rothman L, Macarthur C, Howard A. The impact of pedestrian countdown signals on pedestrian-motor vehicle collisions: a quasi-experimental study. Inj Prev. 2012;18:210-215.",
    },
    {
      56: "Pulugurtha SS, Desai A, Pulugurtha NM. Are pedestrian countdown signals effective in reducing crashes? Traffic Injury Prevention. 2010;11:632-641.",
    },
    {
      57: "Escott BG, Richmond SA, Willan AR, Ravi B, Howard AW. The impact of pedestrian countdown signals on single and two vehicle motor vehicle collisions: a quasi-experimental study. International Journal of Injury Control and Safety Promotion. 2016;24(4):429-434.",
    },
    {
      58: "Eccles K, Tao R, Magnum B. Evaluation of pedestrian countdown signals in Montgomery County, Maryland. Transport Research Board: Journal of the Transport Research Board. 2004;1878:36-41.",
    },
    {
      59: "Rousseau G, Davis G. A comparison of Countdown Pedestrian Signal Display Strategies; Report. Mclean Virginia: USDepartment of Transportation. Federal Highway Administration. 2003.",
    },
    {
      60: "Sacchi E, Sayed T, Osama A. Developing crash modification functions for pedestrian signal improvement. Accident Analysis and Prevention. 2015;83:47-56.",
    },
    {
      61: "Bechtel AK, MacLeod KE, Ragland DR. Pedestrian scramble signal in Chinatown neighborhood of Oakland, California: An evaluation. Transportation Research Board: Journal of the Transportation Research Board. 2004;1878:19-26.",
    },
    {
      62: "Chen L, Chen C, Ewing R. The relative effectiveness of pedestrian safety countermeasures at urban intersections – lessons from a New York City experience. Transportation Research Board Annual Meeting; 2014.",
    },
    {
      63: "Ma W, Han B, An K, Zhang N. Investigating the applicability of exclusive pedestrian phase at two-phase actuated controlled intersections. Journal of Advanced Transportation. 2015;49(6):752-767.",
    },
    {
      64: "Zhang Y, Mamum SA, Ivann JN, Ravishanker N, Haque K. Safety effects of exclusive and concurrent signal phasing for pedestrian crossing. Accident Analysis and Prevention. 2015;83:26-36.",
    },
    {
      65: "Fayish AC, Gross F. Safety effectiveness of leading pedestrian intervals evaluated by a before-after study with comparison groups. Transportation Research Record: Journal of the Transportation Research Board. 2010;2198:15-22.",
    },
    {
      66: "Van Houten R, Retting RA, Farmer CM, Van Houten J. Field evaluation of a leading pedestrian interval signal phase at three urban intersections. Transportation Research Record: Journal of the Transportation Research Board. 2000;1734: 86-92.",
    },
    {
      67: "Osama A, Sayed T, Zaki MH. Before-after automated safety analysis of leading pedestrian interval (LPI). Proceedings of the 5th International Road Safety and Simulation; 2015 Oct; Orlando, Florida, USA.",
    },
    {
      68: "Hua J, Gutierrez N, Markowitz F, Banerjee I, Ragland DR. San Francisco PedSafe 2 Project Outcomes and Lessons Learned. Transportation Research Board Annual Meeting; 2009.",
    },
    {
      69: "Gross F, Lyon C, Persaud B, Srinivasan R. Safety effectiveness of converting signalized intersections to roundabouts. Accid Anal Prev. 2013;50:234-241.",
    },
    {
      70: "Retting RA, Persaud BN, Garder PE, Lord D. Crash and Injury Reduction Following Installation of Roundabouts in the United States. American Journal of Public Health. 2001;91:628-631.",
    },
    {
      71: "Mamlouk M, Souliman B. Effect of traffic roundabouts on accident rate and severity in Arizona. Journal of Transportation Safety & Security. 2019;11(4):430-442.",
    },
    {
      72: "State Highway Administration: Maryland Department of Transportation. Maryland’s Roundabouts: Accident Experience and Economic Evaluation. April 2004.",
    },
    {
      73: "Hyden C, Varhelyi A. The effects on safety, time consumption and environment of large scale use of roundabouts in an urban area: a case study. Accident Analysis and Prevention. 2000;32:11-23.",
    },
    {
      74: "Retting RA, Chapline JF, William AF. Changes in crash risk following re-timing of traffic signal change intervals. Accident Analysis and Prevention. 2002;34:215-220.",
    },
    {
      75: "Autey J, Sayed T, Zaki MH. Safety evaluation of right-turn smart channels using automated traffic conflict analysis. Accid Anal Prev. 2012;45:120-130.",
    },
    {
      76: "Harwood DW, Bauer KM, Potts IB, Torbic DJ, Richard KR, Kohlman Rabbani ER, et al. Safety Effectiveness of Intersection Left- and Right-Turn Lanes. Transportation Research Record: Journal of the Transportation Research Board. 2003;1840:131–139.",
    },
    {
      77: "Sacchi E, Sayed T, deLeur P. A comparison of collision-based and conflict-based safety evaluations: The case of right-turn smart channels. Accident Analysis and Prevention. 2013;59:260-266.",
    },
    {
      78: "Zheng L, Sayed T, Tageldin A. Before-after safety analysis using extreme value theory: A case of left-turn bay extension. Accident Analysis and Prevention. 2018;121:258-267.",
    },
    {
      79: "Zheng L, Sayed T. A full Bayes approach for traffic conflict-based before-after safety evaluation using extreme value theory. Accident Analysis and Prevention. 2019;131:308-315.",
    },
    {
      80: "Tageldin A, Sayed T, Ismail K. Evaluating the safety and operational impacts of left-turn bay extension at signalized intersections using automated video analysis. Accident Analysis and Prevention. 2018;120:13-27.",
    },
    {
      81: "Chen L, Chen C, Ewing R. Left-turn phase: Permissive, protected, or both? A quasi-experimental design in New York City. Accident Analysis and Prevention. 2015;76:102-109.",
    },
    {
      82: "Srinivasan R, Lyon C, Persaud B, Baek J, Gross F, Smith S, Sundstrom C. Crash modification factors for changes to left-turn phasing. Transportation Research Record: Journal of the Transportation Research Board. 2012;2279:108-117.",
    },
    {
      83: "Huybers S, Van Houten R, Malenfant JEL. Reducing conflicts between motor vehicles and pedestrians: the separate and combined effects of pavement markings and a sign prompt. Journal of Applied Behavior Analysis. 2004;37(4):445-456.",
    },
    {
      84: "Fisher D, Garay-Vega L. Advance yield markings and drivers’ performance in response to multiple-threat scenarios at mid-block crosswalks. Accident Analysis and Prevention. 2012;44(1):35-41.",
    },
    {
      85: "Retting RA, Van Houten R. Safety benefits of advance stop lines at signalized intersections: results of a field evaluation. ITE Journal. 2000 Sept;70(9):47-49,54.",
    },
    {
      86: "Gómez RA, Samuel S, Gerardino LR, Romoser MRE, Collura J, Knodler M, et al. Do advance yield markings increase safe driver behaviors at unsignalized, marked midblock crossings? Driving simulator study. Transportation Research Record: Journal of the Transportation Research Board. 2011 Jan; 2264:27-33.",
    },
    {
      87: "Fitzpatrick K, Pratt M. Federal Highway Administration. Road User Behaviors at Pedestrian Hybrid Beacons. Federal Highway Administration report. 2016; FHWA-HRT-16-039.",
    },
    {
      88: "Godavarthy RP. Effectiveness of a pedestrian hybrid beacon at mid-block pedestrian crossings in decreasing unnecessary delay to drivers and a comparison to other systems [masters thesis]. Manhattan, Kansas. Kansas State University; 2010.",
    },
    {
      89: "Van Wagner M, Van Houten R, Betts B. The effects of a rectangular rapid-flashing beacon on vehicle speed. Journal of Applied Behaviour Analysis. 2011;44(3):629-633.",
    },
    {
      90: "Brewer MA, Fitzpatrick K. Before-and-after study of the effectiveness of rectangular rapid-flashing beacons used with school sign in Garland, Texas. TexITE Technical Paper Award. 2012.",
    },
    {
      91: "Shurbutt J, Van Houten R, Turner S, Huitema B. Analysis of effects of LED rectangular rapid-flashing beacons on yielding to pedestrians in multilane crosswalks. Transportation Research Record: Journal of the Transportation Research Board. 2009;2140:85-95.",
    },
    {
      92: "Hunter WW, Srinivasan R, Martell CA. Evaluation of rectangular rapid flash beacon at Pinellas Trail Crossing in Saint Petersburg, Florida. Transportation Research Record: Journal of the Transportation Research Board. 2012;2314:7-13.",
    },
    {
      93: "Ross J, Serpico D, Lewis R. Assessment of driver yield rates pre- and post-RRFB installation, Bend, Oregon. Oregon Department of Transportation. Federal Highway Administration. 2011. FHWA-OR-RD 12-05.",
    },
    {
      94: "Hoye A, Laureshyn A. SeeMe at the crosswalk: Before-after study of a pedestrian crosswalk warning system. Transportation Research Part F. 2019;60:723-733.",
    },
    {
      95: "Foster N, Monsere CM, Carlos K. Evaluating Driver and Pedestrian Behaviors at Enhanced, Multilane, Midblock Pedestrian Crossings: Case Study in Portland, Oregon. Transportation Research Record: Journal of the Transportation Research Board. 2014;2464(1):59-66.",
    },
    {
      96: "Hunter-Zaworski K, Mueller J. Evaluation of Alternative Pedestrian Traffic Control Devices. Oregon Department of Transportation. Federal Highway Administration. 2012. Report No. FHWA-OR-RD-12-09.",
    },
    {
      97: "Al-Kaisy A, Miyake GT, Staszcuk J, Scharf D. Motorists’ voluntary yielding of right of way at uncontrolled midblock crosswalks with rectangular rapid flashing beacons. Journal of Transportation Safety & Security. 2018;10(4):303-317.",
    },
    {
      98: "Mutto M, Kobusingye OC, Lett RR. The effect of an overpass on pedestrian injuries on a major highway in Kampala – Uganda. African Health Sciences. 2002;2(3): 89-93.",
    },
    {
      99: "Ellis R, Van Houten R, Kim JL. In-Roadway “Yield to Pedestrians” Signs. Transportation Research Record: Journal of the Transportation Research Board. 2007;2002:84-89.",
    },
    {
      100: "Abdulsattar HN, Tarawneh MS, McCoy AT, Kachman SD. Effect on Vehicle-Pedestrian Conflicts of “Turning Traffic Must Yield to Pedestrians” Sign. Transportation Research Record: Journal of the Transportation Research Board. No date;1553:38-45.",
    },
    {
      101: "Federal Highway Administration. US Department of Transportation. Hispanic Pedestrian & Bicycle Safety. 2013.",
    },
    {
      102: "Hochmuth J, Van Houten R. Influence of advanced placement of the in-street sign gateway on distance of yielding from the crosswalk. Transportation Research Record: Journal of the Transportation Research Board. 2018;2672(35):13-20.",
    },
    {
      103: "Chen L, Chen C, Srinivasan R, McKnight CE, Ewing R, Roe M. Evaluating the safety effects of bicycle lanes in New York City. American Journal of Public Health. 2012;102:1120-1127.",
    },
    {
      104: "Bhatia D, Richmond SA, Loo CKJ, Rothman L, Macarthur C, Howard A. Examining the impact of cycle lanes on cyclist-motor vehicle collisions in the city of Toronto. Journal of Transport & Health. 2016;3:523-528.",
    },
    {
      105: "Morrison CN, Thompson J, Kondo MC, Beck B. On-road bicycle lane types, roadway characteristics, and risks for bicycle crashes. Accid Anal Prev. 2019 Feb;123:123-131.",
    },
    {
      106: "Teschke K, Harris A, Reynolds CCO, Winters M, Babul S, Chipman M, et al. Route infrastructure and the risk of injuries to bicyclists: a case-crossover study. Am J Public Health.2012;102:2336–2343.",
    },
    {
      107: "Pedroso FE, Angriman F, Bellows AL, Taylor K. Bicycle use and cyclist safety following Boston’s bicycle infrastructure expansion, 2009-2012. Am J Public Health. 2016;106:2171-2177.",
    },
    {
      108: "Kondo MC, Morrison C, Guerra E, Kaufman EJ, Wiebe DJ. Where do bike lanes work best? A Bayesian spatial model of bicycle lanes and bicycle crashes. Safety Science. 2018;103:225–233.",
    },
    {
      109: "DiGioia J, Watkins KE, Xu Y, Rodgers M, Guensler R. Safety impacts of bicycle infrastructure: A critical review. Journal of Safety Research. 2017;61:105-119.",
    },
    {
      110: "Monsere C, Dill J, McNeil N, Clifton K, Foster N, Goddard T, et al. Lessons from the green lanes: evaluating protected bike lanes in the U.S.",
    },
    {
      111: "Harris MA, Reynolds CCO, Winters M,  Cripton PA, Shen H, Chipman ML, et al. Comparing the effects of infrastructure on bicycling injury at intersections and non-intersections using a case-crossover desgin. Inj Prev. 2013 Feb;19: 303–310.",
    },
    {
      112: "Lusk AC, Furth PG, Morency P, Miranda-Moreno LF, Willett WC, Dennerlein JT. Risk of injury for bicycling on cycle tracks versus in the street. Injury Prevention. 2011;17:131-135.",
    },
    {
      113: "Thomas B, DeRobertis M. The safety of urban cycle tracks: A review of the literature. Accident Analysis and Prevention. 2013;52:219-227.",
    },
    {
      114: "Dill J, Monsere CM, McNeil N. Evaluation of bike boxes at signalized intersections. Accid Anal Prev. 2012;44:126-134.",
    },
    {
      115: "Loskorn J, Mills AF, Brady JF, Duthie JC, Machemehl RB. Effects of Bicycle Boxes on Bicyclist and Motorists Behavior at Intersections in Austin, Texas. Journal of Transportation Engineering. 2013;139(10):1039-1046.",
    },
    {
      116: "Jensen SU. Safety effects of blue cycle crossings: A before-after study. Accident Analysis and Prevention. 2008",
    },
    {
      117: "Daniels S, Nuyts E, Wets G. The effects of roundabouts on traffic safety for bicyclists: An observational study. Accid Anal Prev. 2008;40:518–526.",
    },
    {
      118: "Daniels S, Brijs T, Nuyts E, Wets G. Injury crashes with bicyclists at roundabouts: influence of some location characteristics and the design of cycle facilities. Journal of Safety Research. 2009;40:141–148.",
    },
  ],
}

const GlobalStore = ({ children }) => {
  const [globalState, globalDispatch] = useReducer(
    GlobalReducer,
    initialGlobalState
  )

  return (
    <GlobalContext.Provider value={[globalState, globalDispatch]}>
      {children}
    </GlobalContext.Provider>
  )
}

export const GlobalContext = createContext(initialGlobalState)
export default GlobalStore
