import React, { useContext } from "react";
import facepaint from "facepaint";
import styled from "@emotion/styled";

import { GlobalContext } from "../data/GlobalStore";

import GlobalColors from "../assets/GlobalColors";

import InterventionsContainer from "../components/InterventionsContainer";

const breakpoints = [480, 768, 1280]; // [mobile max width, small tablet max width, small screen max width]
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`));

const StyledWrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  ${mq({
    padding: ["5%", "5%", "3%"]
  })}

  /* Custom scroll bar */
  &.pc-page-wrapper {
    &::-webkit-scrollbar {
      ${mq({
        width: ["8px", "8px", "15px"]
      })}
    }
    &::-webkit-scrollbar-thumb {
      background: ${GlobalColors.blueMain};
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background: ${GlobalColors.blueSecondary};
    }
  }
`;

function Home() {
  document.title = `CHASE`;
  const [globalState] = useContext(GlobalContext);

  return (
    <StyledWrapper
      className={!globalState.isMobileOrTablet && "pc-page-wrapper"}
    >
      {globalState.majorCategories.map((category, index) => {
        if (globalState.filterSelectedCategories.includes(category)) {
          return (
            <InterventionsContainer
              interventionCategory={category}
              key={index}
            />
          );
        }
        return null;
      })}
    </StyledWrapper>
  );
}

export default Home;
