import React from "react";
import styled from "@emotion/styled";

import LoadingIcon from "../components/LoadingIcon";

const StyledVideo = styled.video`
  width: 100%;
  height: auto;
  outline: none;
  padding-bottom: 20px;
`;
const StyledDiv = styled.div`
  .loading-icon-animation {
    width: auto !important;
    display: block;
    position: absolute;
  }
`;

function InterventionDetailHorizontalAnimation(props) {
  // Capitalize the intevention title, and remove white spaces or "/" to use the string as part of the video path ("Speed Bumps" should be "SpeedBumps.mp4")
  const regex = /(\/|\s)/gm;
  const interventionTitleSplitted = props.interventionContents.Intervention.split(
    regex
  );
  const modifiedInterventionTitle = interventionTitleSplitted
    .map((word) => {
      if (!word.match(regex))
        return word.charAt(0).toUpperCase() + word.slice(1);
      return null;
    })
    .join("");

  const mp4VideoPath = `https://chase-staging-cdn.azureedge.net/videos-horizontal/${modifiedInterventionTitle}.mp4`;
  const webmVideoPath = `https://chase-staging-cdn.azureedge.net/videos-horizontal/${modifiedInterventionTitle}.webm`;

  // hide loading icon
  const onLoadAnimation = () => {
    const loadingIconSvg = document.querySelector(".loading-icon");
    loadingIconSvg.style.display = "none";
  };

  return (
    <StyledDiv>
      <LoadingIcon />

      <StyledVideo
        autoPlay
        muted
        loop
        playsInline
        onLoadedData={onLoadAnimation}
        key={webmVideoPath}
      >
        <source src={webmVideoPath} type="video/webm" />
        <source src={mp4VideoPath} type="video/mp4" />
        Your browser does not support the video tag.
      </StyledVideo>
    </StyledDiv>
  );
}

export default InterventionDetailHorizontalAnimation;
