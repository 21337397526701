import React from "react";
import facepaint from "facepaint";
import styled from "@emotion/styled";
import GlobalColors from "../../assets/GlobalColors";

const referenceNum = [2, 3, 117, 118];
//Media Queries//
const breakpoints = [480, 768, 1280]; // [mobile max width, small tablet max width, small screen max width]
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`));

const StyledWrapper = styled.div`
  width: 100%;
  overflow-y: scroll;
`;

const StyledH2 = styled.h2`
  font-size: 2.5rem;
  text-decoration: none;
  text-transform: uppercase;
  color: ${GlobalColors.blueMain};
  letter-spacing: 2px;
  display: inline-block;
  margin-bottom: 20px;
  font-weight: 900;
`;

const StyledH3 = styled.h3`
  font-size: 1.8rem;
  color: ${GlobalColors.blueMain};
  font-weight: bolder;
  letter-spacing: 1px;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 10px;
  padding-top: 10px;
`;

const StyledUp = styled.h3`
  text-transform: uppercase;
  font-size: 1.8rem;
  color: ${GlobalColors.blueMain};
  font-weight: bolder;
  letter-spacing: 1.2px;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 10px;
  padding-top: 10px;
`;

const StyledBodyMethod = styled.div`
  font-size: 1.7rem;
  margin-bottom: 20px;
  width: 100%;
  font-weight: 100;
  a {
    color: ${GlobalColors.blueMain};
    font-size: 1.4rem;
  }
  a.refs-link {
    font-size: 1.7rem;
    text-decoration: none;
    vertical-align: super;
  }
  tbody {
    display: flex;
    flex-direction: row;
  }
`;

const H3NotBold = styled.ul`
  display: inline-block;
  vertical-align: top;
`;
const H3NotBoldSpecial = styled.ul`
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
`;

const StyledTable = styled.table`
  text-align: left;
  width: 100%;
  border-collapse: collapse;
  border-style: hidden;
  background-color: ${GlobalColors.tableBgBottomSection};
  margin-bottom: 30px;
  .title {
    border-bottom: 6px ${GlobalColors.white} solid;
  }
  th {
    ${mq({
      color: [`${GlobalColors.blueMain}`, "black", "black"],
      padding: ["10px", 0, 0],
    })}
    text-align: center;
    font-weight: bold;
  }
  .effect-estimates {
    display: flex;
    ${mq({
      "flex-direction": ["column", "row", "row"],
      "justify-content": ["space-evenly", "space-evenly", "space-evenly"],
      "border-top": ["0", "0", `6px ${GlobalColors.white} solid`],
    })}
  }
  td {
    width: 100%;
    padding: 12px 18px;
    ${mq({
      "border-top": [`6px ${GlobalColors.white} solid`, "0", "0"],
      "border-right": ["0", "0", `6px ${GlobalColors.white} solid`],
    })}
    &:last-of-type {
      ${mq({
        "border-right": ["0", "0", `0`],
      })}
    }
  }
  h3 {
    font-weight: bold;
    letter-spacing: 1.2px;
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
    &:after {
      content: ":";
    }
  }
  .bg-dark {
    ${mq({
      "border-top": ["0", "0", `6px ${GlobalColors.white} solid`],
    })}
    background-color: ${GlobalColors.tableBgTopBar};
  }
  a.refs-link {
    font-size: 1.2rem;
    text-decoration: none;
    vertical-align: super;
    &:before {
      content: "(";
    }
    &:after {
      content: ")";
    }
  }
`;

const BreakDiv = styled.ul`
  padding-top: 10px;
`;
const StyledBody = styled.div`
  font-size: 1.6rem;
  margin-bottom: 30px;
  a {
    color: ${GlobalColors.blueMain};
    font-size: 1.4rem;
  }
  a.refs-link {
    font-size: 1.2rem;
    text-decoration: none;
    vertical-align: super;
    &:before {
      content: "(";
    }
    &:after {
      content: ")";
    }
  }
`;
const StyledReferences = styled.a`
  font-size: 1.2rem;
  text-decoration: none;
  color: ${GlobalColors.blueMain};
  vertical-align: super;
  padding-right: 2px;
  a.refs-link {
    font-size: 1.2rem;
    text-decoration: none;
    vertical-align: super;
    &:before {
      content: "(";
    }
    &:after {
      content: ")";
    }
  }
`;

function HelpPageEffectEstimates(props) {
  return (
    <StyledWrapper>
      <StyledH2>HOW TO INTERPRET EFFECT ESTIMATES:</StyledH2>
      <div />
      <StyledH3>
        Perfect reductions, odds ratios and incidence rate ratios
      </StyledH3>
      <StyledBodyMethod>
        This data was extracted from the studies reported here. You can click on
        the reference for each study and be taken to its full citation. When we
        report a range of estimates, for example, several studies reported a
        change in the number of collisions, pre to post implementation as a
        percent reduction. We have summarized the range of reductions and
        presented them like this:
      </StyledBodyMethod>

      <StyledTable>
        <tbody>
          <tr className="title">
            <th colSpan="4">Summary of Evidence</th>
          </tr>
          <tr>
            <td colSpan="4" className="bg-dark">
              <h3>Collisions [% reduction, range]</h3>
              <H3NotBold>Serious PMVCs: [-22 to -23%] </H3NotBold>
              <StyledReferences
                className="refs-link"
                href={`#footnote${referenceNum[0]}`}
              >
                {referenceNum[0]}
              </StyledReferences>
              <StyledReferences
                className="refs-link"
                href={`#footnote${referenceNum[1]}`}
              >
                {referenceNum[1]}
              </StyledReferences>
            </td>
          </tr>
          <tr className="effect-estimates">
            <td>
              <h3>Speed</h3>
              <span>N/A</span>
            </td>
            <td>
              <h3>Volume</h3>
              <span>N/A</span>
            </td>
            <td>
              <h3>Yielding</h3>
              <span>N/A</span>
            </td>
          </tr>
        </tbody>
      </StyledTable>

      <StyledBody>
        <StyledUp>
          Can this intervention be located in the TAC/CGTC manual?:
        </StyledUp>
        <p>No</p>
      </StyledBody>

      <StyledBodyMethod>
        In this example, the first measure is “serious pedestrian motor vehicle
        collisions”. The reduction in collisions ranges from 22 – 23%, cited by
        Rothman et al. (2015) and Nadesan-Reddy et al. (2013). A statement about
        this measure can be written as follows:
        <BreakDiv>
          Speed humps or raised sections of the roadway that are designed to
          slow the speed of motor vehicles has been shown to reduce serious
          injury to pedestrians involved in a motor vehicle collision by 22 to
          23%.
        </BreakDiv>
        <BreakDiv>
          In the next example, using roundabouts as the intervention, with
          outcomes related to bicycle motor vehicle collisions, we report odds
          ratios. In this case, the summary measures are interpreted in the
          following way:
        </BreakDiv>
      </StyledBodyMethod>
      <StyledTable>
        <tbody>
          <tr className="title">
            <th colSpan="4">Summary of Evidence</th>
          </tr>
          <tr>
            <td colSpan="4" className="bg-dark">
              <h3>Collisions [odds ratios, 95%CI]</h3>
              <H3NotBold>CMVCs: [1.27, 1.00-1.61]</H3NotBold>

              <StyledReferences
                className="refs-link"
                href={`#footnote${referenceNum[2]}`}
              >
                {referenceNum[2]}
              </StyledReferences>
              <StyledReferences
                className="refs-link"
                href={`#footnote${referenceNum[3]}`}
              >
                {referenceNum[3]}
              </StyledReferences>
              <H3NotBoldSpecial>
                CMVCs (grade separated paths compared to roundabouts): [0.56,
                0.11-2.82 to 0.83, 0.56-1.23]
                <StyledReferences
                  className="refs-link"
                  href={`#footnote${referenceNum[3]}`}
                >
                  {referenceNum[3]}
                </StyledReferences>
              </H3NotBoldSpecial>
            </td>
          </tr>
          <tr className="effect-estimates">
            <td>
              <h3>Speed</h3>
              <span>N/A</span>
            </td>
            <td>
              <h3>Volume</h3>
              <span>N/A</span>
            </td>
            <td>
              <h3>Yielding</h3>
              <span>N/A</span>
            </td>
          </tr>
        </tbody>
      </StyledTable>

      <StyledBody>
        <StyledUp>
          Can this intervention be located in the TAC/CGTC manual?:
        </StyledUp>
        <p>No</p>
      </StyledBody>

      <StyledBodyMethod>
        <BreakDiv>
          The literature shows that roundabouts, designed to slow traffic by
          forcing drivers to maneuver through a circular intersection, increased
          the odds of bicycle motor vehicle collisions by 27%. When looking at
          graded separated paths compared to roundabouts, the odds of a cycle
          motor vehicle collision decreased anywhere from 17% to 44%
          (1.0-0.83=0.17x100%=17% and 1.0-0.56=0.44x100%= 44%).
        </BreakDiv>
        <BreakDiv>
          In addition to the odds ratios presented here, there are also
          confidence intervals. Confidence intervals are numbers that represent
          the range of variability in the estimate. In this example, the first
          estimate reported a 27% increase in the odds of a bicycle motor
          vehicle collision with the implementation of a roundabout. The
          confidence interval shows a variability in that number from 0% to 61%.
          This means, that 95% of the time, the true odds of a cyclist MVC
          increases in a roundabout from 0% and 61%.
        </BreakDiv>
      </StyledBodyMethod>
    </StyledWrapper>
  );
}

export default HelpPageEffectEstimates;
