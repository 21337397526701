import React from 'react';
import styled from '@emotion/styled';

const PageContainer = styled.div`
    width: 100%;
    padding: 3%;
`

function NotFound() {
  return (
    <PageContainer>
      <p>Page Not Found</p>
    </PageContainer>
  );
}

export default NotFound;
