import React from 'react';

import GlobalStore from "./data/GlobalStore";

import Routes from "./Routes";

function App() {

  return (
    <GlobalStore>
      <Routes />
    </GlobalStore>
  );
}

export default App;
