import React from "react";
import styled from "@emotion/styled";
import GlobalColors from "../../assets/GlobalColors";

const referenceNum = [1];

const StyledWrapper = styled.div`
  padding-bottom: 50px;
  width: 100%;
  overflow-y: scroll;
`;

const StyledH2 = styled.h2`
  font-size: 2.5rem;
  text-decoration: none;
  text-transform: uppercase;
  color: ${GlobalColors.blueMain};
  letter-spacing: 2px;
  display: inline-block;
  margin-bottom: 20px;
  font-weight: 900;
`;

const StyledH3 = styled.h3`
  font-size: 1.8rem;
  text-transform: uppercase;
  color: ${GlobalColors.blueMain};
  font-weight: bolder;
  letter-spacing: 1.2px;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 10px;
`;

const StyledDescription = styled.p`
  font-size: 1.8rem;
  letter-spacing: 1.2px;
  line-height:30px;
  color: ${GlobalColors.blueMain};
  font-weight: normal;
  margin-bottom: 30px;
  width: 100%;
`;
const StyledBodyMethod = styled.div`
  letter-spacing: 1px;
  font-size: 1.7rem;
  margin-bottom: 50px;
  width: 100%;
  font-weight: 100;
  a.refs-link {
    font-size: 1.2rem;
    text-decoration: none;
    vertical-align: super;
    &:before{
      content:"(";
    }
    &:after{
      content:")";
    }
`;
const Bold = styled.a`
  letter-spacing: 1px;
  color: black;
  font-size: 1.7rem;
  width: 100%;
  font-weight: 900;
  padding-left: 6px;
`;

const StyledReferences = styled.a`
  font-size: 1.2rem;
  text-decoration: none;
  color: ${GlobalColors.blueMain};
  vertical-align: super;
  padding-right: 2px;
  a.refs-link {
    font-size: 1.2rem;
    text-decoration: none;
    vertical-align: super;
    &:before{
      content:"(";
    }
    &:after{
      content:")";
    }
  }
`;

const BreakDiv = styled.ul`
  padding-top: 10px;
`;

function HelpPageMethod(props) {
  return (
    <StyledWrapper>
      <StyledH2>METHODS</StyledH2>
      <StyledBodyMethod>
        <div>
          The evidence summarized in this knowledge product was retrieved from
          an environmental scan of the road safety policies implemented across
          five municipalities that are participating in a research study called
          <Bold>CHASE </Bold>- CHild Active transport Safety in the Environment
          (i.e., Vancouver, Toronto, Peel Region, Calgary and Montreal)
          <StyledReferences className = 'refs-link' href={`#footnote${referenceNum[0]}`}>
            {referenceNum[0]}
          </StyledReferences>
        </div>
        <BreakDiv>
          Articles were retrieved from citations within the policies themselves,
          a literature search, and reference to a recently completed systematic
          review (unpublished). Two independent reviewers completed a literature
          search that included peer-reviewed articles indexed in PubMed and
          MEDLINE as well as a grey search in Google Scholar.
        </BreakDiv>
        <BreakDiv>
          Inclusion criteria were those articles that examined a BE
          intervention, included a collision outcome (e.g., MVCs, pedestrian
          MVCs, cyclist MVCs) or collision pathway outcome (e.g., vehicle speed,
          vehicle volume), and included all study designs that evaluated the
          effectiveness of the intervention (e.g., quasi-experimental, pre-post,
          time series).
        </BreakDiv>
        <BreakDiv>
          Exclusion criteria were studies not published in English, non-BE
          interventions (e.g., enforcement and education interventions), studies
          with outcomes other than MVCs or pathway outcomes (e.g., modal choice,
          physical activity), study designs that did not examine the
          effectiveness of the intervention (e.g., implementation/planning
          guides, risk factor studies), studies that did not complete rigorous
          statistical analysis, or studies published more than 20 years ago.
        </BreakDiv>
        <BreakDiv>
          The evidence that is summarized in each table includes all studies
          that were found in our review. This includes studies that scored
          STRONG, MODERATE or WEAK on the critical appraisal. For studies that
          scored weak, we have included a sentence in the ‘quality of studies’
          section to indicate the scoring of that intervention.
        </BreakDiv>
      </StyledBodyMethod>
      <StyledH3>Critical appraisal</StyledH3>
      <StyledDescription>
        We completed critical appraisal of all studies included in this tool.
        This critical appraisal was done by reviewing all interventions,
        specific to their study design, using the TREND checklist. Each
        intervention study was scored out of a possible 22 points and divided
        into three groups: Strong (16-22), Moderate (8-15), or Weak (0-7).
        Finally, we collected information from several key stakeholders (e.g.,
        public health and traffic safety practitioners, policy makers, and local
        partners involved in road safety) on how information in this tool should
        be presented. Several adaptations of this tool were created and all
        feedback and suggestions were incorporated in the final version.
      </StyledDescription>
    </StyledWrapper>
  );
}

export default HelpPageMethod;
