// library
import React, { useContext } from "react"
import { Link } from "react-router-dom"
import facepaint from "facepaint"
import styled from "@emotion/styled"

// components
import InterventionDetailHorizontalAnimation from "./InterventionDetailHorizontalAnimation"

// assets
import { GlobalContext } from "../data/GlobalStore"
import GlobalColors from "../assets/GlobalColors"
import Arrow_Button from "../assets/icons/arrow_button.svg"
import TooltipIcon from "../assets/icons/info-filled.svg"
import TooltipFile from "../assets/file/Municipal-Active-and-Safe-Travel-Policie.pdf"

//Media Queries//
const breakpoints = [480, 768, 1280] // [mobile max width, small tablet max width, small screen max width]
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`))

const StyledWrapper = styled.div`
  height: 100vh;
  overflow-y: scroll;
  ${mq({
    padding: ["5%", "5%", "5%"],
  })}
  @media (min-width: 1281px) and (max-height: 700px) {
    // fixed height size for small height(<700px) on desktop
    height: calc(100vh - (57px + 6vw));
    width: 100%;
  }

  /* Custom scroll bar */
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${GlobalColors.blueMain};
    border-radius: 5px;
  }
`

const StyledWarning = styled.h3`
  font-size: 1.5rem;
  background-color: ${GlobalColors.tableBgBottomSection};
  text-decoration: none;
  text-align: center;
  color: black;
  font-weight: bold;
  letter-spacing: 1px;
  display: block;
  padding: 20px;
`

const StyledH2 = styled.h2`
  font-size: 2.2rem;
  text-decoration: none;
  text-transform: uppercase;
  color: ${GlobalColors.blueMain};
  font-weight: bold;
  letter-spacing: 1px;
  display: inline-block;
  margin-bottom: 12px;
`

const StyledH3 = styled.h3`
  font-size: 1.5rem;
  text-transform: uppercase;
  color: ${GlobalColors.blueMain};
  font-weight: bolder;
  letter-spacing: 1px;
  display: inline-block;
  margin-right: 5px;
`

const StyledDescription = styled.p`
  font-size: 1.6rem;
  color: ${GlobalColors.blueMain};
  font-weight: bold;
  margin-bottom: 2rem;
`

// Tooltip

const Tooltip = styled.a`
  display: inline-block;
  margin-bottom: 5rem;
  font-style: italic;
  font-size: 85%;
  color: ${GlobalColors.blueMain};
  text-decoration: none;
  img {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.5rem;
  }
  &:hover {
    cursor: pointer;
  }
`

const StyledBody = styled.div`
  font-size: 1.6rem;
  margin-bottom: 30px;
  a {
    color: ${GlobalColors.blueMain};
    font-size: 1.4rem;
  }
  a.refs-link {
    font-size: 1.2rem;
    text-decoration: none;
    vertical-align: super;
    &:before {
      content: "(";
    }
    &:after {
      content: ")";
    }
  }
`

const StyledTable = styled.table`
  width: 100%;
  background-color: ${GlobalColors.tableBgBottomSection};
  margin-bottom: 30px;
  table-layout: fixed;
  text-align: left;
  border-collapse: collapse;

  em {
    font-weight: bold;
  }

  tbody {
    width: 100%;
    display: table;
    border-style: hidden;
    ${mq({
      borderCollapse: ["separate", "separate", "inherit", "inherit"],
    })}
  }

  th {
    text-align: center;
    font-weight: bold;
    padding: 12px 18px;
    ${mq({
      border: [
        `3px ${GlobalColors.white} solid`,
        `3px ${GlobalColors.white} solid`,
        `6px ${GlobalColors.white} solid`,
      ],
      color: [
        `${GlobalColors.blueMain}`,
        `${GlobalColors.blueMain}`,
        "inherit",
      ],
    })}
  }

  /* on mobile, only last row & last td doesn't have the border-bottom(otherwise it creates wierd space below the bottom border on mobile window size < 375px) */
  tr:last-child td:last-child {
    ${mq({
      borderBottom: [`none`, `none`, `6px ${GlobalColors.white} solid`],
    })}
  }

  td {
    padding: 12px 18px;

    /* on mobile, make all row 1 column */
    ${mq({
      width: ["100%", "100%", "initial", "initial"],
      float: ["left", "left", "unset", "unset"],
      border: [
        `3px ${GlobalColors.white} solid`,
        `3px ${GlobalColors.white} solid`,
        `6px ${GlobalColors.white} solid`,
      ],
    })}
  }
  h3 {
    font-weight: bold;
    display: block;
    margin-right: 5px;
    &:after {
      content: ":";
    }
    span:not(:empty):before {
      content: " "; // space before each category extra title
    }
  }
  .bg-dark {
    background-color: ${GlobalColors.tableBgTopBar};
  }
  a.refs-link {
    font-size: 1.2rem;
    color: ${GlobalColors.blueMain};
    text-decoration: none;
    vertical-align: super;
    &:before {
      content: "(";
    }
    &:after {
      content: ")";
    }
  }
  /* only on "Left-Turn Phase" page */
  .collisions-extra-contents {
    justify-content: space-between;
    ${mq({
      display: ["block", "block", "flex", "flex"],
      marginTop: ["0", "0", "8px", "8px"],
    })}

    &>div {
      width: 45%;
      ${mq({
        width: ["100%", "100%", "45%", "45%"],
      })}
      h3 {
        ${mq({
          marginTop: ["8px", "8px", "0", "0"],
        })}
      }
    }
  }
`

const StyledLocation = styled.div`
  font-size: 1.6rem;
  margin-bottom: 30px;
  a.refs-link {
    color: ${GlobalColors.blueMain};
    font-size: 1.2rem;
    text-decoration: none;
    vertical-align: super;
    &:before {
      content: "(";
    }
    &:after {
      content: ")";
    }
  }
`

const StyledReferences = styled.ul`
  font-size: 1.2rem;
  a {
    text-decoration: none;
    color: ${GlobalColors.blueMain};
  }
`

// Prev/Next Links --------

const StyledPrevNextLinks = styled.div`
  margin-bottom: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div:first-of-type {
    text-align: left;
  }
  div:nth-of-type(2) {
    text-align: right;
  }
  ${mq({
    marginTop: ["3%", "3%", "0", "0"],
  })}
`

const StyledLink = styled.div`
  max-width: 45%;
  display: flex;
  align-items: center;
  img {
    ${mq({
      height: ["20px", "20px", "25px", "25px"],
    })}
  }
  .button {
    padding: 10px;
  }
  a {
    text-decoration: none;
    font-size: 1.5rem;
    text-transform: uppercase;
    color: ${GlobalColors.blueMain};
    font-weight: bolder;
    letter-spacing: 1px;
    ${mq({
      fontSize: ["1.2rem", "1.2rem", "1.5rem", "1.5rem"],
    })}
  }
`

const RightArrow = styled.img`
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
`

// --------

function InterventionDetailContents(props) {
  document.title = `CHASE - ${props.interventionContents.Intervention}`
  const [globalState] = useContext(GlobalContext)

  // contents with HTML markups for reference links (markup for "Locations" is in jsx)
  const studyDesignsMarkup = () => {
    let markup = props.interventionContents.StudyDesigns.replaceAll(
      /\(\*/gm,
      "<a class='refs-link' href='#refs-footnotes-"
    )
      .replaceAll(/-\*-/gm, "'>")
      .replaceAll(/\*\)/gm, "</a>")
    return { __html: markup }
  }
  const qualityOfStudiesMarkup = () => {
    let markup = props.interventionContents.QualityOfStudies.replaceAll(
      /\(\*/gm,
      "<a class='refs-link' href='#refs-footnotes-"
    )
      .replaceAll(/-\*-/gm, "'>")
      .replaceAll(/\*\)/gm, "</a>")
    return { __html: markup }
  }
  const collisionsMarkup = () => {
    let markup = props.interventionContents.Collisions.replaceAll(
      /\(\*/gm,
      "<a class='refs-link' href='#refs-footnotes-"
    )
      .replaceAll(/-\*-/gm, "'>")
      .replaceAll(/\*\)/gm, "</a>")
    return { __html: markup }
  }
  const conflictsMarkup = () => {
    let markup = props.interventionContents.Conflicts.replaceAll(
      /\(\*/gm,
      "<a class='refs-link' href='#refs-footnotes-"
    )
      .replaceAll(/-\*-/gm, "'>")
      .replaceAll(/\*\)/gm, "</a>")
    return { __html: markup }
  }
  const cyclistsEnteringCrosswalkMarkup = () => {
    let markup = props.interventionContents.CyclistsEnteringCrosswalk.replaceAll(
      /\(\*/gm,
      "<a class='refs-link' href='#refs-footnotes-"
    )
      .replaceAll(/-\*-/gm, "'>")
      .replaceAll(/\*\)/gm, "</a>")
    return { __html: markup }
  }
  const permissiveToProtectedMarkup = () => {
    let markup = props.interventionContents.PermissiveToProtected.replaceAll(
      /\(\*/gm,
      "<a class='refs-link' href='#refs-footnotes-"
    )
      .replaceAll(/-\*-/gm, "'>")
      .replaceAll(/\*\)/gm, "</a>")
    return { __html: markup }
  }
  const permissiveToProtectedPermissiveMarkup = () => {
    let markup = props.interventionContents.PermissiveToProtectedPermissive.replaceAll(
      /\(\*/gm,
      "<a class='refs-link' href='#refs-footnotes-"
    )
      .replaceAll(/-\*-/gm, "'>")
      .replaceAll(/\*\)/gm, "</a>")
    return { __html: markup }
  }
  const tacCgtcManualMarkup = () => {
    let markup = props.interventionContents.TacCgtcManual.replaceAll(
      /\(\*/gm,
      "<a class='refs-link' href='#refs-footnotes-"
    )
      .replaceAll(/-\*-/gm, "'>")
      .replaceAll(/\*\)/gm, "</a>")
    return { __html: markup }
  }
  const speedMarkup = () => {
    let markup = props.interventionContents.Speed.replaceAll(
      /\(\*/gm,
      "<a class='refs-link' href='#refs-footnotes-"
    )
      .replaceAll(/-\*-/gm, "'>")
      .replaceAll(/\*\)/gm, "</a>")
    return { __html: markup }
  }
  const volumeMarkup = () => {
    let markup = props.interventionContents.Volume.replaceAll(
      /\(\*/gm,
      "<a class='refs-link' href='#refs-footnotes-"
    )
      .replaceAll(/-\*-/gm, "'>")
      .replaceAll(/\*\)/gm, "</a>")
    return { __html: markup }
  }
  const yieldingMarkup = () => {
    let markup = props.interventionContents.Yielding.replaceAll(
      /\(\*/gm,
      "<a class='refs-link' href='#refs-footnotes-"
    )
      .replaceAll(/-\*-/gm, "'>")
      .replaceAll(/\*\)/gm, "</a>")
    return { __html: markup }
  }

  // special case for: Area-Wide Traffic Calming (since it has refs in title & "Pedestrian Crossing Time" in the table):
  const areaWideCollisionTitleMarkup = () => {
    let markup = props.interventionContents.CollisionsExtraTitle.replaceAll(
      /\(\*/gm,
      "<a class='refs-link' href='#refs-footnotes-"
    )
      .replaceAll(/-\*-/gm, "'>")
      .replaceAll(/\*\)/gm, "</a>")
    return { __html: markup }
  }
  const areaWidePedestrianCrossingTimeMarkup = () => {
    let markup = props.interventionContents.PedestrianCrossingTime.replaceAll(
      /\(\*/gm,
      "<a class='refs-link' href='#refs-footnotes-"
    )
      .replaceAll(/-\*-/gm, "'>")
      .replaceAll(/\*\)/gm, "</a>")
    return { __html: markup }
  }

  // Filter all interventions to match this intervention's category
  // then sort them in alphabetical order
  const filteredAndSortedInterventions = globalState.interventions
    .filter((intervention) => {
      if (
        intervention.MainCategory === props.interventionContents.MainCategory
      ) {
        return true
      }
      return false
    })
    .sort((a, b) => (a.Intervention < b.Intervention ? -1 : 1))

  // Get the index of this particular intervention in the sorted array
  const thisInterventionIndex = filteredAndSortedInterventions.findIndex(
    (intervention) =>
      intervention.Intervention === props.interventionContents.Intervention
  )

  const regex = /(\/|\s)/gm
  // Find the previous intervention title, and url-ify it
  const prevLink =
    thisInterventionIndex !== 0
      ? filteredAndSortedInterventions[
          thisInterventionIndex - 1
        ].Intervention.toLowerCase().replaceAll(regex, "-")
      : null

  // Previous intervention object
  const prevIntervention =
    thisInterventionIndex !== 0
      ? filteredAndSortedInterventions[thisInterventionIndex - 1]
      : null

  // Next intervention object
  const nextIntervention =
    thisInterventionIndex !== filteredAndSortedInterventions.length - 1
      ? filteredAndSortedInterventions[thisInterventionIndex + 1]
      : null

  // Find the next intervention title, and url-ify it
  const nextLink =
    thisInterventionIndex !== filteredAndSortedInterventions.length - 1
      ? filteredAndSortedInterventions[
          thisInterventionIndex + 1
        ].Intervention.toLowerCase().replaceAll(regex, "-")
      : null

  return (
    <StyledWrapper>
      <StyledPrevNextLinks
        style={!prevLink ? { justifyContent: "flex-end" } : {}}
      >
        {prevLink && (
          <StyledLink>
            <Link to={prevLink}>
              <img src={Arrow_Button} alt="prev arrow" />
            </Link>
            <Link className="button" to={prevLink}>
              {prevIntervention.Intervention}
            </Link>
          </StyledLink>
        )}
        {nextLink && (
          <StyledLink>
            <Link className="button" to={nextLink}>
              {nextIntervention.Intervention}
            </Link>
            <Link to={nextLink}>
              <RightArrow src={Arrow_Button} alt="next arrow" />
            </Link>
          </StyledLink>
        )}
      </StyledPrevNextLinks>

      <StyledH2>{props.interventionContents.Intervention}</StyledH2>
      <StyledDescription>
        {props.interventionContents.Description}
      </StyledDescription>

      {/* tooltip */}
      <Tooltip target="_blank" rel="noopener noreferrer" href={TooltipFile}>
        <img src={TooltipIcon} alt="tooltip" />
        <span>Click here to see if this intervention is in your CHASE city</span>
      </Tooltip>

      {globalState.isMobileOrTablet ? (
        <InterventionDetailHorizontalAnimation
          interventionContents={props.interventionContents}
        />
      ) : (
        ""
      )}

      {!props.interventionContents.StudyDesigns ? (
        <StyledWarning>
          There were no studies found that evaluated the effectiveness of this
          intervention.
        </StyledWarning>
      ) : (
        <div>
          <StyledBody>
            <StyledH3>Study designs:</StyledH3>
            <div dangerouslySetInnerHTML={studyDesignsMarkup()} />
          </StyledBody>
          <StyledBody>
            <StyledH3>Quality of studies:</StyledH3>
            <div dangerouslySetInnerHTML={qualityOfStudiesMarkup()} />
          </StyledBody>

          {props.interventionContents.Interpretations !==
          "Not possible here." ? (
            <StyledBody>
              <StyledH3>Interpretations:</StyledH3>
              <p>{props.interventionContents.Interpretations}</p>
            </StyledBody>
          ) : (
            ""
          )}

          {props.interventionContents.Locations[0] !== "N/A" && (
            <StyledLocation>
              <StyledH3>Study Locations:</StyledH3>
              <ul>
                {props.interventionContents.Locations.map((city, index) => {
                  const LocationsMarkup = () => {
                    let markup = city
                      .replaceAll(
                        /\(\*/gm,
                        "<a class='refs-link' href='#refs-footnotes-"
                      )
                      .replaceAll(/-\*-/gm, "'>")
                      .replaceAll(/\*\)/gm, "</a>")
                    return { __html: markup }
                  }
                  return (
                    <li
                      key={index}
                      dangerouslySetInnerHTML={LocationsMarkup()}
                    />
                  )
                })}
              </ul>
            </StyledLocation>
          )}

          {/* Only Bike Box doesn't have Collision, Speed, Volume and Yielding*/}
          {props.interventionContents.Intervention === "Bike Box" && (
            <StyledTable>
              <tbody>
                <tr>
                  <th colSpan="3">Summary of Evidence</th>
                </tr>

                <tr>
                  <td colSpan="3" className="bg-dark">
                    <h3>
                      Cyclists entering crosswalk
                      <span>
                        {
                          props.interventionContents
                            .CyclistsEnteringCrosswalkExtraTitle
                        }
                      </span>
                    </h3>
                    <span
                      dangerouslySetInnerHTML={cyclistsEnteringCrosswalkMarkup()}
                    />
                  </td>
                </tr>
              </tbody>
            </StyledTable>
          )}

          {props.interventionContents.Collisions === "N/A" &&
          props.interventionContents.Speed === "N/A" &&
          props.interventionContents.Volume === "N/A" &&
          props.interventionContents.Yielding === "N/A" ? (
            ""
          ) : (
            <StyledTable>
              <tbody>
                <tr>
                  <th colSpan="3">Summary of Evidence</th>
                </tr>

                <tr>
                  {/* In-pavement flashing lights doesn't have Collisions field */}
                  {props.interventionContents.Intervention ===
                    "In-Pavement Flashing Lights" && (
                    <td colSpan="3" className="bg-dark">
                      <h3>
                        Conflicts
                        <span>
                          {props.interventionContents.ConflictsExtraTitle}
                        </span>
                      </h3>
                      <span dangerouslySetInnerHTML={conflictsMarkup()} />
                    </td>
                  )}

                  {props.interventionContents.Collisions !== "N/A" && (
                    <td colSpan="3" className="bg-dark">
                      <h3>
                        Collisions
                        {props.interventionContents.Intervention ===
                        "Area-Wide Traffic Calming" ? (
                          <span
                            dangerouslySetInnerHTML={areaWideCollisionTitleMarkup()}
                          />
                        ) : (
                          <span>
                            {props.interventionContents.CollisionsExtraTitle}
                          </span>
                        )}
                      </h3>
                      <span dangerouslySetInnerHTML={collisionsMarkup()} />
                      {/* only on "Left-Turn phase" intevention page, show extra collision information */}
                      {props.interventionContents.Intervention ===
                        "Left-Turn phase" && (
                        <div className="collisions-extra-contents">
                          <div>
                            <h3>Permissive to protected-only</h3>
                            <span
                              dangerouslySetInnerHTML={permissiveToProtectedMarkup()}
                            />
                          </div>
                          <div>
                            <h3>Permissive to protected/permissive</h3>
                            <span
                              dangerouslySetInnerHTML={permissiveToProtectedPermissiveMarkup()}
                            />
                          </div>
                        </div>
                      )}
                    </td>
                  )}
                </tr>
                <tr>
                  {props.interventionContents.Speed !== "N/A" && (
                    <td>
                      <h3>
                        Speed
                        <span>
                          {props.interventionContents.SpeedExtraTitle}
                        </span>
                      </h3>
                      <span dangerouslySetInnerHTML={speedMarkup()} />
                    </td>
                  )}

                  {props.interventionContents.Volume !== "N/A" && (
                    <td>
                      <h3>
                        Volume
                        <span>
                          {props.interventionContents.VolumeExtraTitle}
                        </span>
                      </h3>
                      <span dangerouslySetInnerHTML={volumeMarkup()} />
                    </td>
                  )}

                  {/* only "Area-Wide Traffic Calming" displays "Pedestrian crossing time" section, other interventions display "Yielding" section */}
                  {props.interventionContents.Intervention ===
                  "Area-Wide Traffic Calming" ? (
                    <td>
                      <h3>
                        Pedestrian Crossing Time
                        <span>
                          {
                            props.interventionContents
                              .PedestrianCrossingTimeExtraTitle
                          }
                        </span>
                      </h3>
                      <span
                        dangerouslySetInnerHTML={areaWidePedestrianCrossingTimeMarkup()}
                      />
                    </td>
                  ) : (
                    props.interventionContents.Yielding !== "N/A" && (
                      <td>
                        <h3>
                          Yielding
                          <span>
                            {props.interventionContents.YieldingExtraTitle}
                          </span>
                        </h3>
                        <span dangerouslySetInnerHTML={yieldingMarkup()} />
                      </td>
                    )
                  )}
                </tr>
              </tbody>
            </StyledTable>
          )}

          {props.interventionContents.TacCgtcManual !== "No" && (
            <StyledBody>
              <StyledH3>
                Can this intervention be located in the TAC/CGTC manual?:
              </StyledH3>
              <p dangerouslySetInnerHTML={tacCgtcManualMarkup()} />
            </StyledBody>
          )}

          {props.interventionContents.Link !== "None" && (
            <StyledBody>
              <StyledH3>Link:</StyledH3>
              <p>
                {props.interventionContents.Link !== "None" && (
                  <a
                    href={props.interventionContents.Link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {props.interventionContents.Link}
                  </a>
                )}
              </p>
            </StyledBody>
          )}

          {props.interventionContents.AdditionalNotes !== "None" ? (
            <StyledBody>
              <StyledH3>Additional Notes:</StyledH3>
              <p>{props.interventionContents.AdditionalNotes}</p>
            </StyledBody>
          ) : (
            ""
          )}

          <StyledBody>
            <StyledH3 style={{ display: "block", marginBottom: "5px" }}>
              References:
            </StyledH3>
            <StyledReferences>
              {props.interventionContents.References?.map(
                (referenceIndex, index) => {
                  if (!globalState.evidenceKPReferences[referenceIndex - 1]) {
                    return <li key={index}>{referenceIndex}: No reference</li>
                  }
                  return (
                    <li key={index}>
                      <span id={`refs-footnotes-${referenceIndex}`}>
                        {referenceIndex}
                      </span>
                      :{" "}
                      {Object.values(
                        globalState.evidenceKPReferences[referenceIndex - 1]
                      )}
                    </li>
                  )
                }
              )}
            </StyledReferences>
          </StyledBody>
        </div>
      )}
    </StyledWrapper>
  )
}

export default InterventionDetailContents
