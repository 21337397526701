import React, { useState, useContext } from "react";
import styled from "@emotion/styled";
import { Link, NavLink, useLocation } from "react-router-dom";

import { GlobalContext } from "../data/GlobalStore";

import SearchBar from "./SearchBar";

import GlobalColors from "../assets/GlobalColors";
import infoIcon from "../assets/icons/info.svg";
import vehicleIcon from "../assets/icons/vehicle.svg";
import bicycleIcon from "../assets/icons/bicycle.svg";
import pedestrianIcon from "../assets/icons/pedestrian.svg";
import navBarBgImage from "../assets/images/navbarBG.jpeg";

const Header = styled.header`
  .about-header {
    display: flex;
    flex-direction: column;
  }
  h1 {
    margin-bottom: 20px;
  }
  color: ${GlobalColors.white};
  background: linear-gradient(rgba(37, 162, 207, 0.7), rgba(37, 162, 207, 0.7)),
    url(${navBarBgImage});
  padding: 3%;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  transition: 0.5s;
  word-break: break-word;
  width: 28vw;
  border-radius: 0 40px 40px 0;
  min-height: 100vh;

  & > * {
    width: 100%;
  }
  nav {
    a {
      text-transform: uppercase;
      font-size: 1.6rem;
      letter-spacing: 1px;
    }
  }
  h4 {
    font-size: 2.8rem;
    margin-bottom: 5px;
  }
  .global-nav-about-container {
    font-size: 1.4rem;
    h4 {
      font-weight: bold;
      letter-spacing: 2px;
    }
  }
  .about-icon {
    display: none;
    height: 0;
  }
  .about-icon-shown {
    display: block;
    width: 90%;
  }

  /* when the nav is collapsed on PC */
  &.global-nav-collapsed {
    width: 11%;
    justify-content: start;
    flex-flow: column nowrap;
    h1 {
      transform: rotate(270deg);
      word-break: initial;
      transform: rotate(270deg);
      margin: 100px 0 50px -8px;
      flex-flow: column nowrap;
    }
    nav {
      margin-top: 40px;
    }
    nav p,
    .global-nav-about-container * {
      display: none;
      height: 0;
      margin: 0 !important;
      padding: 0 !important;
    }
    nav a {
      opacity: 0.6;
      display: block;
      text-align: center;
      margin-bottom: 10px;
      &:hover {
        opacity: 1;
      }
    }
    .active {
      opacity: 1;
    }
    .nav-buttons {
      display: none !important;
    }
    .about-wrapper {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      .about-icon-shown {
        margin-top: auto;
        padding-bottom: 5vh;
        text-align: center;
      }
      .about-icon {
        opacity: 0.6;
        transition: opacity 0.3s;
        img {
          width: 80%;
          display: inline-block;
        }
        &:hover {
          cursor: pointer;
          opacity: 1;
        }
      }
      nav {
        margin: 0;
      }
    }
  }
`;
const HomeLink = styled(Link)`
  color: ${GlobalColors.white};
  display: inline-block;
  text-decoration: none;
  letter-spacing: 2px;
  word-break: keep-all;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  padding-bottom: 2px; /* space for dashed line */
  /* dashed line */
  background-image: linear-gradient(
    to right,
    #fff 60%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: -1px 9rem;
  background-size: 25px 3px;
  background-repeat: repeat-x;
  font-size: 7rem;
`;
const NavLinks = styled.nav`
  a {
    display: flex;
    align-items: center;
  }
  .nav-buttons {
    margin: 30px 0;
    a span {
      text-align: center;
      display: block;
      width: 100%;
      padding: 5px;
      border: 1px solid;
      border-radius: 0;
    }
    a span:empty {
      border: none;
    }
  }
`;

const BorderLinks = `
  text-decoration: none;
  color: #fff;
  display: block;
  font-size: 2.4rem;
  border-radius: 4px;
  font-weight: bold;
  transition: 0.2s;
  margin-bottom: 15px;
  p,
  img {
    display: inline-block;
    margin: 0;
  }
  img {
    width: 65px;
    height: 45px;
    margin-right: 20px;
  }
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;
const Category = styled(NavLink)`
  ${BorderLinks}
`;

const SurveyLink = styled.a`
  ${BorderLinks}
`;

// to export the [isNavBarCollapsed, setIsNavBarCollapsed] state
export const navBarContext = React.createContext();

function GlobalNav() {
  const [globalState, globalDispatch] = useContext(GlobalContext); // load dispatch actions from GlobalReducer.js.
  const [isNavBarCollapsed, setIsNavBarCollapsed] = useState(false); // local state

  const initializeStates = () => {
    globalDispatch({ type: "CHANGE_IS_HELP_ABOUT_SHOWN", payload: "false" });
    setIsNavBarCollapsed(true);
  };
  // when any of the category(bicycle, vehicle, pedestrian) icons are clicked, clear searchText in GlobalStore.js, and collapse the navbar by setting the local state "isNavBarCollapsed" to true
  const clearSearchText = () => {
    globalDispatch({ type: "CHANGE_SEARCH_TEXT", payload: "" });
    setIsNavBarCollapsed(true);
  };

  let location = useLocation(); // React Router Hooks API. The useLocation hook returns the location object that represents the current URL.
  let isHomepage = location.pathname === "/";

  // update "isInfoBarShown" in GlobalStore.js to show/hide "Help" and "About" bars
  const toggleIsAboutHelpAndAboutShown = (e) => {
    setIsNavBarCollapsed(!isNavBarCollapsed);
    globalDispatch({
      type: "CHANGE_IS_HELP_ABOUT_SHOWN",
      payload: !globalState.isAboutHelpAndAboutShown,
    });
  };
  return (
    <Header
      className={`${
        isHomepage || !isNavBarCollapsed ? "" : "global-nav-collapsed"
      }`}
    >
      <div className="about-header">
        <h1>
          <HomeLink to="/" onClick={clearSearchText}>
            CHASE
          </HomeLink>
        </h1>

        <div>
          <navBarContext.Provider
            value={[isNavBarCollapsed, setIsNavBarCollapsed]}
          >
            <SearchBar isHomepage={isHomepage} />
          </navBarContext.Provider>

          <NavLinks>
            <Category to="/bicycle" onClick={clearSearchText}>
              <img src={bicycleIcon} alt="" />
              {/* when on homepage, or navbar is uncollapsed, display the name of each category */}
              <span>{`${
                isHomepage || !isNavBarCollapsed ? "Bicycle" : ""
              }`}</span>
            </Category>
            <Category to="/vehicle" onClick={clearSearchText}>
              <img src={vehicleIcon} alt="" />
              <span>{`${
                isHomepage || !isNavBarCollapsed ? "Vehicle" : ""
              }`}</span>
            </Category>
            <Category to="/pedestrian" onClick={clearSearchText}>
              <img src={pedestrianIcon} alt="" />
              <span>{`${
                isHomepage || !isNavBarCollapsed ? "Pedestrian" : ""
              }`}</span>
            </Category>
          </NavLinks>
        </div>
      </div>

      <div className="about-wrapper">
        <div
          className={`about-icon ${
            isHomepage || !isNavBarCollapsed ? "" : "about-icon-shown"
          }`}
        >
          <img
            src={infoIcon}
            alt="about chase icon"
            onClick={toggleIsAboutHelpAndAboutShown}
          />
        </div>
        <NavLinks>
          <div className="nav-buttons">
            <SurveyLink
              href="https://forms.gle/ynsuZUfsDxpBW5S67"
              target="_blank"
              rel="noreferrer noopener"
              onClick={initializeStates}
            >
              <span>{`${
                isHomepage || !isNavBarCollapsed ? "EVALUATION SURVEY" : ""
              }`}</span>
            </SurveyLink>
            <Category to="/about" onClick={initializeStates}>
              <span>{`${
                isHomepage || !isNavBarCollapsed ? "ABOUT CHASE" : ""
              }`}</span>
            </Category>
            <Category to="/help" onClick={initializeStates}>
              <span>{`${
                isHomepage || !isNavBarCollapsed ? "HOW TO USE" : ""
              }`}</span>
            </Category>
             <Category to="/privacy" onClick={initializeStates}>
              <span>{`${
                isHomepage || !isNavBarCollapsed ? "PRIVACY POLICY" : ""
              }`}</span>
            </Category>
          </div>
        </NavLinks>

        <div className="global-nav-about-container">
          <p>
            This tool serves as a summary of results from published studies that
            examined the effectiveness of built environment interventions at
            increasing road safety.
          </p>
        </div>
      </div>
    </Header>
  );
}

export default GlobalNav;
