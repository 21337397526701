import React from "react";
import styled from "@emotion/styled";
import GlobalColors from "../../assets/GlobalColors";
import facepaint from "facepaint";

const breakpoints = [480, 768, 1280]; // [mobile max width, small tablet max width, small screen max width]
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`));

const list = [
  {
    name: "Brent E. Hagel",
    organization: "University of Calgary",
    leader: true,
  },
  {
    name: "Andrew Howard",
    organization: "Hospital for Sick Children",
    leader: true,
  },
  { name: "Alison MacPherson", organization: "York University", leader: true },
  { name: "Pamela Fuselli", organization: "Parachute", leader: true },
  { name: "Kathy Belton", organization: "University of Alberta" },
  { name: "Linda Rothman", organization: "Ryerson University" },
  { name: "Sarah Richmond", organization: "Public Health Ontario" },
  { name: "Ron Buliung", organization: "University of Toronto" },
  {
    name: "Marie-Soleil Cloutier",
    organization: "Institut National de la Recherche Scientifique",
  },
  { name: "Carolyn Emery", organization: "University of Calgary" },
  { name: "Gregory Morrow", organization: "UC Berkley" },
  { name: "Tracey Ma", organization: "University of Calgary" },
  { name: "Guy Faulkner", organization: "University of British Columbia" },
  { name: "Gavin McCormack", organization: "University of Calgary" },
  { name: "Alberto Nettel-Aguirre", organization: "University of Calgary" },
  { name: "Kelly Russell", organization: "University of Manitoba" },
  { name: "Liz Owens", organization: "Alberta Transportation" },
  { name: "Ian Pike", organization: "University of British Columbia" },
  { name: "Juan Torres", organization: "University of Montreal" },
  { name: "Donald Voaklander", organization: "University of Alberta" },
  { name: "Meghan Winters", organization: "Simon Fraser University" },
  { name: "Colin Macarthur", organization: "SickKids" },
  { name: "Digital Lab", organization: "BC Children's Hospital" },
];

const StyledWrapper = styled.div`
  width: 100%;
  padding-bottom: 50px;
 
`;

const StyledGrid = styled.div`
  .wrapper {

    ${mq({
      "grid-template-columns": [
        `repeat(2,50%)`,
        `repeat(4,25%)`,
        `repeat(4,25%)`,
      ],
    })}
    display: grid;
    text-align: center;
    grid-auto-rows: 1fr;
    padding: 5px;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    ${mq({
    "padding-right": ["3%", "10%", "5%"],
  })}

    h3 {
      ${mq({
        "font-size": ["14px", "15px", "15px"],
      })}
      overflow-wrap: break-word;
      color: black;
      display: inline-block;
      vertical-align: top;

      &.member-name {
        font-weight: 900;
      }
      &.organization-name {
        font-weight: 100;
      }
    }

    .blue-h3.member-name {
      color: ${GlobalColors.blueMain};
    }
    .blue-h3.organization-name {
      color: ${GlobalColors.blueMain};
    }
  }

  .wrapper > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${GlobalColors.tableBgBottomSection};
    color: ${GlobalColors.blueMain};
    padding: 0.5em;
  }
`;

const StyledH2 = styled.h2`
  ${mq({
    "font-size": ["2rem", "2.5rem", "2.5rem"],
  })}
  text-decoration: none;
  text-transform: uppercase;
  color: ${GlobalColors.blueMain};
  display: inline-block;
  margin-bottom: 20px;
  font-weight: 900;
  padding-top: 10px;
`;
const StyledH3 = styled.h3`
  font-size: 1.8rem;
  text-transform: uppercase;
  color: ${GlobalColors.blueMain};
  font-weight: bolder;
  letter-spacing: 1.2px;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 10px;
`;
const StyledDescription = styled.p`
  font-size: 1.8rem;
  line-height: 30px;
  color: ${GlobalColors.blueMain};
  font-weight: normal;
  margin-bottom: 30px;
  width: 100%;
`;

function AboutPageAcks() {
  return (
    <StyledWrapper>
      <StyledH2>ACKNOWLEDGEMENTS</StyledH2>

      <StyledDescription>
        We would like to thank everyone who has been a part of the CHASE work:
      </StyledDescription>

      <StyledH3>TEAM MEMBERS: </StyledH3>

      <StyledGrid>
        <div class="wrapper">
          {list.map((i) => {
            return (
              <div>
                <h3
                  className={i.leader ? "blue-h3 member-name" : "member-name"}
                >
                  {i.name}
                </h3>
                <h3
                  className={
                    i.leader ? "blue-h3 organization-name" : "organization-name"
                  }
                >
                  {i.organization}
                </h3>
              </div>
            );
          })}
        </div>
      </StyledGrid>
    </StyledWrapper>
  );
}

export default AboutPageAcks;
