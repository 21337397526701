import React, { useState, useContext } from "react"
import facepaint from "facepaint"
import styled from "@emotion/styled"
import { useLocation, Link } from "react-router-dom"

import { GlobalContext } from "../data/GlobalStore"

import { LazyLoadImage } from "react-lazy-load-image-component"
import LoadingIcon from "./LoadingIcon"

const breakpoints = [480, 768, 1280] // [mobile max width, small tablet max width, small screen max width]
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`))

const StyledCard = styled(Link)`
  text-decoration: none;
  color: #111;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  width: 100%;
  letter-spacing: 1px;
  ${mq({
    marginBottom: ["10px", "10px", "10px", "unset"],
    display: ["inline-block", "inline-block", "inline-block", "block"],
  })}

  &.on-homepage-intervention-card {
    ${mq({
      width: ["60vw", "60vw", "38vw", "100%"],
      marginBottom: ["15px", "15px", "15px", "unset"],
      marginRight: ["30px", "30px", "30px", "unset"],
    })}
    &:last-child {
      ${mq({
        marginRight: ["0", "0", "0", "unset"],
      })}
    }
  }

  .thumbnailContainer {
    position: relative;
  }
  img {
    width: 100%;
    display: inline-block;
  }
  video {
    width: 100%;
    position: absolute;
    z-index: 2;
    left: 0;
  }
  h3 {
    margin-top: 10px;
  }
`

function Card(props) {
  const [, globalDispatch] = useContext(GlobalContext)
  const [isThumbnailHovered, setIsThumbnailHovered] = useState(false)

  let location = useLocation()
  const isHomepage = location.pathname === "/"

  const loadAnimation = () => {
    setIsThumbnailHovered(true)
  }
  const setDefaultThumbnail = () => {
    setIsThumbnailHovered(false)
  }
  const clearSearchText = () => {
    globalDispatch({ type: "CHANGE_SEARCH_TEXT", payload: "" })
  }

  // when card thumbnail image is loaded, hide loading icon
  const onThumbnailImageLoaded = () => {
    // get the div which contains the loading car SVG from the ID
    const loadingIconSvg = document.getElementById(props.cardIndex)
    loadingIconSvg.style.display = "none"
  }

  // matches "/" or " "
  const regex = /(\/|\s)/gm

  // path for each intervention
  const interventionSlug = `${props.MainCategory.toLowerCase()}/${props.Intervention.toLowerCase().replaceAll(
    regex,
    "-"
  )}`

  // Capitalize the intervention title, and remove white spaces or "/" to use the string as part of the thumbnail src path ("Speed Bumps" should be "SpeedBumps.png" or "SpeedBumps.webp")
  const interventionTitleSplitted = props.Intervention.split(regex)
  const modifiedInterventionTitle = interventionTitleSplitted
    .map((word) => {
      if (!word.match(regex))
        return word.charAt(0).toUpperCase() + word.slice(1)
      return null
    })
    .join("")

  const pngThumbnailPath = `https://chase-staging-cdn.azureedge.net/images-horizontal/${modifiedInterventionTitle}.png`
  const webPThumbnailPath = `https://chase-staging-cdn.azureedge.net/images-horizontal/${modifiedInterventionTitle}.webp`

  const webmVideoPath = `https://chase-staging-cdn.azureedge.net/videos-horizontal/${modifiedInterventionTitle}.webm`
  const mp4VideoPath = `https://chase-staging-cdn.azureedge.net/videos-horizontal/${modifiedInterventionTitle}.mp4`

  return (
    <StyledCard
      className={`intervention-card ${
        isHomepage && "on-homepage-intervention-card"
      }`}
      to={interventionSlug}
      onMouseEnter={loadAnimation}
      onMouseLeave={setDefaultThumbnail}
      data-major-category={props.MainCategory}
      data-sub-category={props.SubCategory}
      onClick={clearSearchText}
    >
      <div className="thumbnailContainer">
        <LoadingIcon cardIndex={props.cardIndex} />
        <picture>
          <source srcSet={webPThumbnailPath} type="image/webp" />
          <source srcSet={pngThumbnailPath} type="image/png" />
          <LazyLoadImage
            className="thumbnailImage"
            alt={props.Intervention}
            src={pngThumbnailPath}
            afterLoad={onThumbnailImageLoaded}
            visibleByDefault={props.preload}
          />
        </picture>
        {isThumbnailHovered && (
          <video autoPlay loop muted>
            <source src={webmVideoPath} type="video/webm" />
            <source src={mp4VideoPath} type="video/mp4" />
          </video>
        )}
      </div>
      <h3>{props.Intervention}</h3>
    </StyledCard>
  )
}

export default Card
