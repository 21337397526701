import React from 'react';
import facepaint from "facepaint";
import styled from '@emotion/styled';

import GlobalColors from "../assets/GlobalColors";

import InterventionsContainer from "../components/InterventionsContainer";

const breakpoints = [480, 768, 1280]; // [mobile max width, small tablet max width, small screen max width]
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`));

const StyledWrapper = styled.div`
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    ${mq({
      padding: ["5%", "5%", "3%"]
    })}

    /* Custom scroll bar */
    &::-webkit-scrollbar {
      ${mq({
        width: ["8px", "8px", "15px"]
      })}
    }
    &::-webkit-scrollbar-thumb {
      background: ${GlobalColors.blueMain};
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background:${GlobalColors.blueSecondary};
    }
`

function Bicycle() {
  document.title = "CHASE - Bicycle Interventions";
  return (
    <StyledWrapper>
      <InterventionsContainer
        interventionCategory="Bicycle"
      />
    </StyledWrapper>
  );
}

export default Bicycle;
