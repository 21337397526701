import React, { useContext, useEffect } from "react";
import facepaint from "facepaint";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { GlobalContext } from "../data/GlobalStore";
import GlobalColors from "../assets/GlobalColors";

import InterventionCard from "./InterventionCard";

import BicycleBlueIcon from "../assets/icons/bicycle-blue.svg";
import VehicleBlueIcon from "../assets/icons/vehicle-blue.svg";
import PedestrianBlueIcon from "../assets/icons/pedestrian-blue.svg";

const breakpoints = [480, 768, 1280]; // [mobile max width, small tablet max width, small screen max width]
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`));

const StyledDiv = styled.div`
  margin-bottom: 70px;
  &.hidden {
    display: none;
  }
`;

const StyledH2 = styled.h2`
  text-transform: uppercase;
  color: ${GlobalColors.blueMain};
  font-weight: bolder;
  letter-spacing: 1px;
  justify-content: space-between;
  ${mq({
    display: ["block", "block", "flex"],
    fontSize: ["2.6rem", "2.6rem", "2.2rem"],
    margin: ["10px 0 40px", "10px 0 40px", "0 0 20px 0"],
    lineHeight: ["1.2", "1.2", "1.4"],
  })}
  a {
    text-decoration: none;
    color: ${GlobalColors.blueMain};
    ${mq({
      display: ["block", "block", "inline-block"],
      position: ["relative", "relative", "unset"],
    })}
    /* "** INTEREVNTIONS" */
    &:nth-of-type(2) {
      ${mq({
        lineHeight: ["0.8", "0.8", "unset"],
      })}
      p {
        ${mq({
          fontSize: ["1.4rem", "1.4rem", "1.4rem"],
          width: ["unset", "unset", "unset"],
        })}
      }
    }
  }
  /* intervention icons */
  img {
    display: inline-block;
    vertical-align: middle;
    ${mq({
      height: ["unset", "unset", "40px"],
      width: ["30%", "14%", "40px"],
      marginRight: ["0", "0", "10px"],
      order: ["1", "1", "unset"],
      opacity: ["0.2", "0.2", "unset"],
      position: ["absolute", "absolute", "unset"],
      top: ["0", "0"],
      right: ["0", "0"],
      zIndex: ["-1", "-1", "unset"],
    })}
    &[alt="Pedestrian icon"] {
      ${mq({
        height: ["85px", "85px", "35px"],
        width: ["unset", "unset", "40px"],
      })}
    }
  }
  p {
    display: inline-block;
  }
`;

const StyledCardContainerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledCardContainer = styled.div`
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  transition: 0.2s;
  margin: 0 auto;
  /* height of each intervention card. (needs to be set as the container min-height to prevent the height of the container becomes 0 when the carousel arrow is clicked)
     100vw - 28vw(width of global nav) = 72vw,
     72vw - (72vw * 6%(padding left and right of "*Page" component)) = 67.68vw,
     50px * 2(carousel arrows + space between arrows and cards) + 30px * 2 (gap between cards(3 columns)) = 160px,
     devide above by 3 = width of each card,
     each thumbnail image has proportion 16(width):9(height),
     therefore, (width of card / 16) * 9 = height of each card.
     60px is for the height of each intervention title.
  */
  min-height: calc((((67.68vw - 160px) / 3) / 16) * 9 + 60px);

  ${mq({
    gridTemplateColumns: [
      "repeat(2, 1fr)",
      "repeat(2, 1fr)",
      "repeat(2, 1fr)",
      "repeat(3, 1fr)",
    ],
    gap: ["20px", "20px", "20px", "30px"],
  })}

  &.on-homepage-intervention-container {
    ${mq({
      display: ["block", "block", "block", "grid"],
    })}
  }

  &.mobile-intervention-container.on-homepage-intervention-container {
    margin-bottom: 0;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;

    &::-webkit-scrollbar {
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #27a2cf;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-track {
      background: #c9e7f2;
    }
    &.tablet-interventions-less-than-2 {
      overflow-x: hidden;
    }
    &.mobile-intervention-length-1 {
      overflow-x: hidden;
    }
  }
`;

// carousel arrows
const StyledLeftArrow = styled.span`
  display: inline-block;
  border: 20px solid transparent;
  margin: -10px 30px 0 -20px;
  border-right-color: ${GlobalColors.blueMain};
  transition: 0.2s;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }
`;
const StyledRightArrow = styled.span`
  display: inline-block;
  border: 20px solid transparent;
  margin: -10px -20px 0 30px;
  border-left-color: ${GlobalColors.blueMain};
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }
`;

//
// component
//
function InterventionsContainer(props) {
  const [globalState, globalDispatch] = useContext(GlobalContext);

  let location = useLocation();
  const isHomepage = location.pathname === "/";

  // ----------------------------------------
  // filtering functionality
  // ----------------------------------------

  const filteredInterventions = globalState.interventions.filter(
    (intervention) => {
      // when the search area is empty, "filteredIntereventions" contains an array of all interventions in each category(Bicycle OR Vehicle OR Pedestrian)
      if (globalState.searchText === "") {
        return intervention.MainCategory === props.interventionCategory;
      }
      if (
        // when the search area has an input string, "filteredIntereventions" array contains interventions with titles which includes the input string
        intervention.Intervention.toLowerCase().includes(
          globalState.searchText.toLowerCase()
        )
      ) {
        return intervention.MainCategory === props.interventionCategory;
      }
      // to resolve a warning "Arrow function expected a return value array-callback-return"
      return null;
    }
  );

  // sort interventions order when:
  // - not on homepage
  // - search area has an input
  if (!isHomepage || globalState.searchText !== "") {
    filteredInterventions.sort((a, b) =>
      a.Intervention < b.Intervention ? -1 : 1
    );
  }


  // on first load of PC Homepage, randomize the interventions order
  const homepageOnInit = function () {
    if (isHomepage && globalState.searchText === "") {
      // randomize the order
      filteredInterventions.sort((a, b) => 0.5 - Math.random());
      // update each category array in global states
      if (props.interventionCategory === "Bicycle") {
        globalDispatch({
          type: "UPDATE_BICYCLE_RANDOMIZED_INTERVENTIONS_ON_HOMEPAGE",
          payload: filteredInterventions,
        });
      }
      if (props.interventionCategory === "Vehicle") {
        globalDispatch({
          type: "UPDATE_VEHICLE_RANDOMIZED_INTERVENTIONS_ON_HOMEPAGE",
          payload: filteredInterventions,
        });
      }
      if (props.interventionCategory === "Pedestrian") {
        globalDispatch({
          type: "UPDATE_PEDESTRIAN_RANDOMIZED_INTERVENTIONS_ON_HOMEPAGE",
          payload: filteredInterventions,
        });
      }
    }
  };

  // pass the "homepageOnInit" function to useEffect,
  // to prevent the useEffect dependency array warning.
  useEffect(homepageOnInit, []);


  // clear "searchText" in globalState
  const clearSearchText = () => {
    globalDispatch({ type: "CHANGE_SEARCH_TEXT", payload: "" });
  };

  // --------------------------------
  // carousel
  // --------------------------------

  // carousel with arrows is only used on PC
  const carouselLeft = (e) => {
    if (props.interventionCategory === "Bicycle") {
      const previousIndexes = globalState.bicycleInterventionIndexes.map(
        (index) => index - 3
      );
      globalDispatch({
        type: "CHANGE_BICYCLE_INTERVENTION_INDEXES",
        payload: previousIndexes,
      });
    } else if (props.interventionCategory === "Vehicle") {
      const previousIndexes = globalState.vehicleInterventionIndexes.map(
        (index) => index - 3
      );
      globalDispatch({
        type: "CHANGE_VEHICLE_INTERVENTION_INDEXES",
        payload: previousIndexes,
      });
    } else if (props.interventionCategory === "Pedestrian") {
      const previousIndexes = globalState.pedestrianInterventionIndexes.map(
        (index) => index - 3
      );
      globalDispatch({
        type: "CHANGE_PEDESTRIAN_INTERVENTION_INDEXES",
        payload: previousIndexes,
      });
    }
  };

  const carouselRight = (e) => {
    if (props.interventionCategory === "Bicycle") {
      const nextIndexes = globalState.bicycleInterventionIndexes.map(
        (index) => index + 3
      );
      globalDispatch({
        type: "CHANGE_BICYCLE_INTERVENTION_INDEXES",
        payload: nextIndexes,
      });
    } else if (props.interventionCategory === "Vehicle") {
      const nextIndexes = globalState.vehicleInterventionIndexes.map(
        (index) => index + 3
      );
      globalDispatch({
        type: "CHANGE_VEHICLE_INTERVENTION_INDEXES",
        payload: nextIndexes,
      });
    } else if (props.interventionCategory === "Pedestrian") {
      const nextIndexes = globalState.pedestrianInterventionIndexes.map(
        (index) => index + 3
      );
      globalDispatch({
        type: "CHANGE_PEDESTRIAN_INTERVENTION_INDEXES",
        payload: nextIndexes,
      });
    }
  };

  const carouselLeftArrowClassName =
    props.interventionCategory === "Bicycle" &&
    globalState.bicycleInterventionIndexes[0] === 0
      ? "disabled"
      : props.interventionCategory === "Vehicle" &&
        globalState.vehicleInterventionIndexes[0] === 0
      ? "disabled"
      : props.interventionCategory === "Pedestrian" &&
        globalState.pedestrianInterventionIndexes[0] === 0
      ? "disabled"
      : "";

  const carouselRightArrowClassName =
    props.interventionCategory === "Bicycle" &&
    globalState.bicycleInterventionIndexes[2] >=
      filteredInterventions.length - 1
      ? "disabled"
      : props.interventionCategory === "Vehicle" &&
        globalState.vehicleInterventionIndexes[2] >=
          filteredInterventions.length - 1
      ? "disabled"
      : props.interventionCategory === "Pedestrian" &&
        globalState.pedestrianInterventionIndexes[2] >=
          filteredInterventions.length - 1
      ? "disabled"
      : "";

  return (
    // if there's no intervention, hide the container
    <StyledDiv className={filteredInterventions.length === 0 && "hidden"}>
      {/* category title and the numbers of the interventions */}
      <StyledH2>
        <Link to={props.interventionCategory} onClick={clearSearchText}>
          <img
            src={
              props.interventionCategory === "Bicycle"
                ? BicycleBlueIcon
                : props.interventionCategory === "Vehicle"
                ? VehicleBlueIcon
                : PedestrianBlueIcon
            }
            alt={props.interventionCategory + " icon"}
          />
          <span>
            {`${props.interventionCategory} Intervention${
              filteredInterventions.length > 1 ? "s" : ""
            }`}
          </span>
        </Link>

        <Link to={props.interventionCategory} onClick={clearSearchText}>
          <p
            style={
              isHomepage && !globalState.isMobileOrTablet
                ? { marginRight: "50px" }
                : {}
            }
          >
            {`${filteredInterventions.length} intervention${
              filteredInterventions.length > 1 ? "s" : ""
            }`}
          </p>
        </Link>
      </StyledH2>

      <StyledCardContainerWrapper>
        {/* show the arrow only on PC */}
        {globalState.isMobileOrTablet ? (
          ""
        ) : (
          <StyledLeftArrow
            onClick={carouselLeft}
            className={carouselLeftArrowClassName}
            style={!isHomepage ? { display: "none" } : {}}
          />
        )}

        {/* to this "StyledCardContainer" <div>, classnames below are given when:
            - on Homepage: "on-homepage-intervention-container"
            - window size < 1279px: "mobile-intervention-container"
            - 600px < window size < 1279px AND searched interventions are less than 2 interventions: "tablet-interventions-less-than-2"
            - window size < 600px AND searched intervention is only 1 intervention: "mobile-intervention-length-1"
          */}
        <StyledCardContainer
          className={`card-container ${
            isHomepage && "on-homepage-intervention-container"
          } ${
            globalState.isMobileOrTablet && "mobile-intervention-container"
          } ${
            globalState.isTablet &&
            filteredInterventions.length <= 2 &&
            "tablet-interventions-less-than-2"
          } ${
            globalState.isMobile &&
            filteredInterventions.length <= 1 &&
            "mobile-intervention-length-1"
          }`}
        >
          {/* ----------------------------------
              on PC Homepage 
          -----------------------------------*/}

          {/* Bicycle */}
          {isHomepage && // on homepage
            globalState.searchText === "" && // search area is empty
            !globalState.isMobileOrTablet && // not on mobile/tablet
            props.interventionCategory === "Bicycle" && // interevention category is "Bicycle"
            globalState.bicycleInterventionIndexes.map((index) => {
              if (globalState.bicycleRandomizedInterventionsOnHomepage[index]) {
                return (
                  <InterventionCard
                    Intervention={
                      globalState.bicycleRandomizedInterventionsOnHomepage[
                        index
                      ].Intervention
                    }
                    Thumbnail={
                      globalState.bicycleRandomizedInterventionsOnHomepage[
                        index
                      ].Thumbnail
                    }
                    ThumbnailHoverMp4={
                      globalState.bicycleRandomizedInterventionsOnHomepage[
                        index
                      ].ThumbnailHoverMp4
                    }
                    ThumbnailHoverWebM={
                      globalState.bicycleRandomizedInterventionsOnHomepage[
                        index
                      ].ThumbnailHoverWebM
                    }
                    MainCategory={
                      globalState.bicycleRandomizedInterventionsOnHomepage[
                        index
                      ].MainCategory
                    }
                    SubCategory={
                      globalState.bicycleRandomizedInterventionsOnHomepage[
                        index
                      ].SubCategory
                    }
                    cardIndex={`${globalState.bicycleRandomizedInterventionsOnHomepage[index].MainCategory}-${index}`}
                    key={index}
                  />
                );
              }
              return null;
            })}

          {/* Vehicle */}
          {isHomepage &&
            globalState.searchText === "" &&
            !globalState.isMobileOrTablet &&
            props.interventionCategory === "Vehicle" &&
            globalState.vehicleInterventionIndexes.map((index) => {
              if (globalState.vehicleRandomizedInterventionsOnHomepage[index]) {
                return (
                  <InterventionCard
                    Intervention={
                      globalState.vehicleRandomizedInterventionsOnHomepage[
                        index
                      ].Intervention
                    }
                    Thumbnail={
                      globalState.vehicleRandomizedInterventionsOnHomepage[
                        index
                      ].Thumbnail
                    }
                    ThumbnailHoverMp4={
                      globalState.vehicleRandomizedInterventionsOnHomepage[
                        index
                      ].ThumbnailHoverMp4
                    }
                    ThumbnailHoverWebM={
                      globalState.vehicleRandomizedInterventionsOnHomepage[
                        index
                      ].ThumbnailHoverWebM
                    }
                    MainCategory={
                      globalState.vehicleRandomizedInterventionsOnHomepage[
                        index
                      ].MainCategory
                    }
                    SubCategory={
                      globalState.vehicleRandomizedInterventionsOnHomepage[
                        index
                      ].SubCategory
                    }
                    cardIndex={`${globalState.vehicleRandomizedInterventionsOnHomepage[index].MainCategory}-${index}`}
                    key={index}
                  />
                );
              }
              return null;
            })}

          {/* Pedestrian */}
          {isHomepage &&
            globalState.searchText === "" &&
            !globalState.isMobileOrTablet &&
            props.interventionCategory === "Pedestrian" &&
            globalState.pedestrianInterventionIndexes.map((index) => {
              if (
                globalState.pedestrianRandomizedInterventionsOnHomepage[index]
              ) {
                return (
                  <InterventionCard
                    Intervention={
                      globalState.pedestrianRandomizedInterventionsOnHomepage[
                        index
                      ].Intervention
                    }
                    Thumbnail={
                      globalState.pedestrianRandomizedInterventionsOnHomepage[
                        index
                      ].Thumbnail
                    }
                    ThumbnailHoverMp4={
                      globalState.pedestrianRandomizedInterventionsOnHomepage[
                        index
                      ].ThumbnailHoverMp4
                    }
                    ThumbnailHoverWebM={
                      globalState.pedestrianRandomizedInterventionsOnHomepage[
                        index
                      ].ThumbnailHoverWebM
                    }
                    MainCategory={
                      globalState.pedestrianRandomizedInterventionsOnHomepage[
                        index
                      ].MainCategory
                    }
                    SubCategory={
                      globalState.pedestrianRandomizedInterventionsOnHomepage[
                        index
                      ].SubCategory
                    }
                    cardIndex={`${globalState.pedestrianRandomizedInterventionsOnHomepage[index].MainCategory}-${index}`}
                    key={index}
                  />
                );
              }
              return null;
            })}

          {/* display filtered interventions with non-randomized order when search text are not empty on homepage*/}
          {isHomepage &&
            globalState.searchText !== "" &&
            !globalState.isMobileOrTablet &&
            props.interventionCategory === "Bicycle" &&
            globalState.bicycleInterventionIndexes.map((index) => {
              if (filteredInterventions[index]) {
                return (
                  <InterventionCard
                    Intervention={filteredInterventions[index].Intervention}
                    Thumbnail={filteredInterventions[index].Thumbnail}
                    ThumbnailHoverMp4={
                      filteredInterventions[index].ThumbnailHoverMp4
                    }
                    ThumbnailHoverWebM={
                      filteredInterventions[index].ThumbnailHoverWebM
                    }
                    MainCategory={filteredInterventions[index].MainCategory}
                    SubCategory={filteredInterventions[index].SubCategory}
                    cardIndex={`${filteredInterventions[index].MainCategory}-${index}`}
                    key={index}
                  />
                );
              }
              return null;
            })}
          {isHomepage &&
            globalState.searchText !== "" &&
            !globalState.isMobileOrTablet &&
            props.interventionCategory === "Vehicle" &&
            globalState.vehicleInterventionIndexes.map((index) => {
              if (filteredInterventions[index]) {
                return (
                  <InterventionCard
                    Intervention={filteredInterventions[index].Intervention}
                    Thumbnail={filteredInterventions[index].Thumbnail}
                    ThumbnailHoverMp4={
                      filteredInterventions[index].ThumbnailHoverMp4
                    }
                    ThumbnailHoverWebM={
                      filteredInterventions[index].ThumbnailHoverWebM
                    }
                    MainCategory={filteredInterventions[index].MainCategory}
                    SubCategory={filteredInterventions[index].SubCategory}
                    cardIndex={`${filteredInterventions[index].MainCategory}-${index}`}
                    key={index}
                  />
                );
              }
              return null;
            })}
          {isHomepage &&
            globalState.searchText !== "" &&
            !globalState.isMobileOrTablet &&
            props.interventionCategory === "Pedestrian" &&
            globalState.pedestrianInterventionIndexes.map((index) => {
              if (filteredInterventions[index]) {
                return (
                  <InterventionCard
                    Intervention={filteredInterventions[index].Intervention}
                    Thumbnail={filteredInterventions[index].Thumbnail}
                    ThumbnailHoverMp4={
                      filteredInterventions[index].ThumbnailHoverMp4
                    }
                    ThumbnailHoverWebM={
                      filteredInterventions[index].ThumbnailHoverWebM
                    }
                    MainCategory={filteredInterventions[index].MainCategory}
                    SubCategory={filteredInterventions[index].SubCategory}
                    cardIndex={`${filteredInterventions[index].MainCategory}-${index}`}
                    key={index}
                  />
                );
              }
              return null;
            })}

          {/* ----------------------------------
            on PC individual category page 
          -----------------------------------*/}

          {/* show all interventions in the category */}
          {!isHomepage &&
            !globalState.isMobileOrTablet &&
            filteredInterventions.map((filteredIntervention, index) => {
              return (
                <InterventionCard
                  Intervention={filteredIntervention.Intervention}
                  Thumbnail={filteredIntervention.Thumbnail}
                  ThumbnailHoverMp4={filteredIntervention.ThumbnailHoverMp4}
                  ThumbnailHoverWebM={filteredIntervention.ThumbnailHoverWebM}
                  MainCategory={filteredIntervention.MainCategory}
                  SubCategory={filteredIntervention.SubCategory}
                  cardIndex={`${filteredIntervention.MainCategory}-${index}`}
                  key={index}
                />
              );
            })}

          {/* ----------------------------------
              on mobile/tablet 
          -----------------------------------*/}

          {/* on Homepage, randomize the interventions order when there's no search input */}
          {globalState.isMobileOrTablet &&
            isHomepage &&
            filteredInterventions
              .sort((a, b) => 0.5 - Math.random())
              .map((filteredIntervention, index) => {
                return (
                  <InterventionCard
                    Intervention={filteredIntervention.Intervention}
                    Thumbnail={filteredIntervention.Thumbnail}
                    ThumbnailHoverMp4={filteredIntervention.ThumbnailHoverMp4}
                    ThumbnailHoverWebM={filteredIntervention.ThumbnailHoverWebM}
                    MainCategory={filteredIntervention.MainCategory}
                    SubCategory={filteredIntervention.SubCategory}
                    cardIndex={`${filteredIntervention.MainCategory}-${index}`}
                    key={index}
                    preload={index < 3}
                  />
                );
              })}

          {/* ----------------------------------
            on mobile individual category page 
          -----------------------------------*/}
          {!isHomepage &&
            globalState.isMobileOrTablet &&
            filteredInterventions.map((filteredIntervention, index) => {
              return (
                <InterventionCard
                  Intervention={filteredIntervention.Intervention}
                  Thumbnail={filteredIntervention.Thumbnail}
                  ThumbnailHoverMp4={filteredIntervention.ThumbnailHoverMp4}
                  ThumbnailHoverWebM={filteredIntervention.ThumbnailHoverWebM}
                  MainCategory={filteredIntervention.MainCategory}
                  SubCategory={filteredIntervention.SubCategory}
                  cardIndex={`${filteredIntervention.MainCategory}-${index}`}
                  key={index}
                  preload={index < 3}
                />
              );
            })}

        </StyledCardContainer>

        {/* show the arrow only on PC */}
        {globalState.isMobileOrTablet ? (
          ""
        ) : (
          <StyledRightArrow
            onClick={carouselRight}
            className={carouselRightArrowClassName}
            style={!isHomepage ? { display: "none" } : {}}
          />
        )}
      </StyledCardContainerWrapper>
    </StyledDiv>
  );
}

export default InterventionsContainer;
