import React from "react";
import styled from "@emotion/styled";
import facepaint from "facepaint";

//Components//
import AboutPageFirstSection from "./AboutPageFirstSection";
//Acknowledgements//
import AboutPageAcks from "./AboutPageAcks";
//LOGOS
import AboutPageLogos from "./AboutPageLogos";

const breakpoints = [480, 768, 1280]; // [mobile max width, small tablet max width, small screen max width]
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`));

const StyledWrapper = styled.div`
  ${mq({
    padding: ["5%", "2%", "2%"],
  })}
  height: 100vh;
  width: 100%;
  padding-bottom: 100px;
`;

function AboutPage() {
  return (
    <StyledWrapper>
      <AboutPageFirstSection />
      <AboutPageAcks />
      <AboutPageLogos />
    </StyledWrapper>
  );
}

export default AboutPage;