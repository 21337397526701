import React, { useContext } from "react";
import facepaint from "facepaint";
import styled from "@emotion/styled";
import { Link, NavLink } from "react-router-dom";

import { GlobalContext } from "../data/GlobalStore";

import SearchBarMobile from "./SearchBarMobile";

import GlobalColors from "../assets/GlobalColors";
import searchIcon from "../assets/icons/search.svg";
import vehicleIcon from "../assets/icons/vehicle.svg";
import bicycleIcon from "../assets/icons/bicycle.svg";
import pedestrianIcon from "../assets/icons/pedestrian.svg";
import navBarBgImage from "../assets/images/navbarBG.jpeg";

// media queries mapping
const breakpoints = [480, 768]; // [mobile max width, small tablet max width, tablet max width]
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`));

const StyledHeader = styled.header`
  color: ${GlobalColors.white};
  background: linear-gradient(rgba(37, 162, 207, 0.7), rgba(37, 162, 207, 0.7)),
    url(${navBarBgImage});
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  transition: 0.5s;
  word-break: break-word;
  width: 100vw;
  padding: 5%;

  h1 {
    ${mq({
      marginRight: ["unset", "15px"],
    })}
  }
  .search-icon {
    display: inline-block;
    flex: auto;
  }
  img {
    // set the same width/height to all <img>s in header
    width: 45px;
    height: 30px;
  }
  .global-nav-about {
    margin: 15px 0;
    font-size: 1.4rem;
  }
`;
const HomeLink = styled(Link)`
  color: ${GlobalColors.white};
  display: inline-block;
  text-decoration: none;
  word-break: keep-all;
  font-size: 3rem;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 3px;
  margin-right: 5px;
  /* dashed line */
  background-image: linear-gradient(
    to right,
    #fff 60%,
    rgba(255, 255, 255, 0) 0%
  );
  background-repeat: repeat-x;

  /* dashed line */
  background-size: 15px 2px; // theLengthOfDash theHeightOfDash
  background-position: 2px 3.3rem; //horizontalPositionOfDash distanceFromTextTop (setting to font size + 0.3rem)
  padding-bottom: 9px; // space under text to show the dashed line
`;

const StyledHamburgerIcon = styled.div`
  width: 28px;
  span {
    // each border
    display: block;
    width: 28px;
    height: 4px;
    border-radius: 1px;
    background-color: #fff;
    margin-bottom: 5px;
    transition: 0.3s;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &.hamburger-icon-collapsed {
    // "X" icon
    position: relative;
    span {
      position: absolute;
      transition: 0.2s;
    }
    span:first-of-type {
      transform: rotate(45deg);
      margin-bottom: 0;
    }
    span:nth-of-type(2) {
      display: none;
    }
    span:last-of-type {
      transform: rotate(135deg);
      margin-bottom: 0;
    }
  }
`;

const StyledMenuContents = styled.div`
  width: 100%;
  height: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  padding-top: 0;
  margin-top: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s, height 0.3s, padding-top 0.3s,
    margin-top 0.3s;

  &.menu-contents-show {
    height: auto;
    padding-top: 20px;
    margin-top: 20px;
    visibility: visible;
    opacity: 1;
  }
`;

const StyledNavLinks = styled.nav`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  a {
    text-transform: uppercase;
    font-size: 1.6rem;
    letter-spacing: 1px;
    opacity: 0.6;
  }
  .active {
    opacity: 1;
  }
`;
const StyledCategory = styled(NavLink)`
  text-decoration: none;
  color: #fff;
  display: block;
  font-size: 2.4rem;
  border-radius: 4px;
  font-weight: bold;
  transition: 0.2s;
  margin-bottom: 0;
  ${mq({
    marginLeft: ["14px", "20px"],
  })}
  &[href="/pedestrian"] img {
    // solely aesthetic purpose
    ${mq({
      width: ["auto"],
    })}
  }
`;
const BorderLinks = `
  text-align: center;
  color: #fff;
  text-decoration: none;
  padding: 10px;
  border: 1px solid;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
`;
const StyledNavButtons = styled.nav`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  a {
    width: 46%;
    ${BorderLinks}
  }
`;
const SurveyLink = styled.a`
  width: 100% !important;
  margin-top: 20px;
  ${BorderLinks}
`;

function GlobalNavMobile() {
  // load global state from GlobalStore.js
  const [globalState, globalDispatch] = useContext(GlobalContext);

  // clear "searchText", set "isSearchBarShown" and "isAboutTextShown" to false in GlobalStore.js
  const initializeStates = () => {
    menuIconToggle();
    globalDispatch({ type: "CHANGE_SEARCH_TEXT", payload: "" });
    globalDispatch({ type: "CHANGE_IS_SEARCH_BAR_SHOWN", payload: false });
    globalDispatch({ type: "CHANGE_IS_ABOUT_TEXT_SHOWN", payload: false });
    globalDispatch({ type: "CHANGE_HOMEPAGE", payload: false });
  };

  //click on CHASE to go to the homepage
  const toggleIsHomePageShown = () => {
    //update :"isHomePageShown"
    globalDispatch({
      type: "CHANGE_HOMEPAGE",
      payload: !globalState.isHomePageShown,
    });
    // If "CHASE" is clicked, the hamburger menu will be collapsed
    document
      .querySelector(".hamburger-icon")
      .classList.remove("hamburger-icon-collapsed");
    document
      .querySelector(".menu-contents")
      .classList.remove("menu-contents-show");
  };

  // update "isSearchBarShown" in GlobalStore.js to show/hide search input in SearchBarMobile.js component
  const toggleIsSearchBarShown = () => {
    globalDispatch({
      type: "CHANGE_IS_SEARCH_BAR_SHOWN",
      payload: !globalState.isSearchBarShown,
    });
    // if "About CHASE" text is shown, hide it
    if (globalState.isAboutTextShown) {
      globalDispatch({
        type: "CHANGE_IS_ABOUT_TEXT_SHOWN",
        payload: !globalState.isAboutTextShown,
      });
    }
  };

  const menuIconToggle = (e) => {
    document
      .querySelector(".hamburger-icon")
      .classList.toggle("hamburger-icon-collapsed");
    document
      .querySelector(".menu-contents")
      .classList.toggle("menu-contents-show");
  };

  return (
    <StyledHeader>
      <h1>
        <HomeLink to="/" onClick={toggleIsHomePageShown}>
          CHASE
        </HomeLink>
      </h1>

      <StyledHamburgerIcon className="hamburger-icon" onClick={menuIconToggle}>
        <span></span>
        <span></span>
        <span></span>
      </StyledHamburgerIcon>

      <StyledMenuContents className="menu-contents">
        <StyledNavLinks>
          <div className="search-icon">
            <img
              src={searchIcon}
              style={
                globalState.isSearchBarShown
                  ? { opacity: "1" }
                  : { opacity: "0.6" }
              }
              alt="search icon"
              onClick={toggleIsSearchBarShown}
              className={`${globalState.isSearchBarShown ? "active" : ""}`}
            />
          </div>

          <StyledCategory to="/bicycle" onClick={initializeStates}>
            <img src={bicycleIcon} alt="bicycle icon" />
          </StyledCategory>
          <StyledCategory to="/vehicle" onClick={initializeStates}>
            <img src={vehicleIcon} alt="vehicle icon" />
          </StyledCategory>
          <StyledCategory to="/pedestrian" onClick={initializeStates}>
            <img src={pedestrianIcon} alt="pedestrian icon" />
          </StyledCategory>
        </StyledNavLinks>

        {globalState.isSearchBarShown && <SearchBarMobile />}

        <div className="global-nav-about">
          <p>
            This tool serves as a summary of results from published studies that
            examined the effectiveness of built environment interventions at
            increasing road safety.
          </p>
        </div>

        <StyledNavButtons>
          <Link to="/about" onClick={initializeStates}>
            ABOUT CHASE
          </Link>
          <Link to="/help" onClick={initializeStates}>
            HOW TO USE
          </Link>
          <SurveyLink
            href="https://forms.gle/ynsuZUfsDxpBW5S67"
            target="_blank"
            onClick={initializeStates}
            rel="noreferrer noopener"
          >
            EVALUATION SURVEY
          </SurveyLink>
        </StyledNavButtons>
      </StyledMenuContents>
    </StyledHeader>
  );
}

export default GlobalNavMobile;
