import React from "react";
import facepaint from "facepaint";
import styled from "@emotion/styled";
import GlobalColors from "../../assets/GlobalColors";
const breakpoints = [480, 768, 1280]; // [mobile max width, small tablet max width, small screen max width]
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`));

const arrayLogos = [
  "1_Alberta_CHRI",
  "2_Alberta_Health_Services",
  "3_Alberta Transportation",
  "4_BC_Childrens_Hospital",
  "5_BC_IRPU",
  "6_CAA",
  "7_City_of_Calgary",
  "8_CARSP",
  "9_CIHR",
  "10_City_of_Vancouver",
  "11_BC_CHDL",
  "12_Ecology_Action_Centre",
  "13_Eco_Counter",
  "14_Green_Communities_Canada",
  "15_HAST",
  "16_Injury_Prevention_Centre",
  "17_INRS_Insitute",
  "18_CCTP",
  "19_Montreal",
  "20_Metrolinx",
  "21_a_Ontario_Active_School_Travel",
  "21_Ontario_Ministry_of_Transportation",
  "22_Pediatric_Emergency_Research_Canada",
  "23_Parachute",
  "24_Public_Health_Ontario",
  "25_Region_of_Peel",
  "26_Sick_Kids_Research_Institute",
  "27_SFU",
  "28_Transport_Canada",
  "29_Toronto_DSB",
  "30_City_of_Toronto",
  "31_Toronto_Public_Health",
  "32_UOA",
  "33_UBC",
  "34_University_of_Calgary_CSM",
  "35_University_of_Calgary_SIPRC",
  "36_University_of_Manitoba",
  "37_Université_de_Montréal",
  "38_University_of_Toronto",
  "39_York_University",
  "40_Vélo_Québec",
];

const StyledWrapper = styled.div`
  width: 100%;
  height: 100vh;
  padding-bottom: 50px;
`;

const StyledGrid = styled.div`
  .wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .logo-container {
      ${mq({
        width: ["50%", "21%", "21%"],
        'margin-bottom':['10%','5%','5%'],
      })}
      height: 60px;
      /* centering the image inside this container */
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 10px;
      padding-left: 10px;
    }
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
`;

const StyledH2 = styled.h2`
  font-size: 2.5rem;
  text-decoration: none;
  text-transform: uppercase;
  color: ${GlobalColors.blueMain};
  letter-spacing: 2px;
  display: inline-block;
  margin-bottom: 20px;
  font-weight: 900;
  padding-top: 10px;
  padding-bottom: 30px;
`;

function AboutPageLogos() {
  return (
    <StyledWrapper>
      <StyledH2>PARTNERS</StyledH2>
      <StyledGrid>
        <div className="wrapper">
          {arrayLogos.map((img) => {
            return (
              <div className="logo-container">
                <img
                  src={`https://chase-staging-cdn.azureedge.net/partners-logo/${img}.png`}
                  alt={img}
                />
              </div>
            );
          })}
        </div>
      </StyledGrid>
    </StyledWrapper>
  );
}

export default AboutPageLogos;
